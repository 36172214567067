<template>
    <div class="flex flex-col h-full" :class="{ 'w-auto' : size === 'small', 'w-full mb-4' : size === 'large' }">
        <label for="file-upload"
               :class="{ 'p-4 h-full basis-[14%]' : size === 'small', 'p-12 sm:w-[50%] lg:w-[33%]' : size === 'large' }"
               class="relative flex items-center justify-center bg-office-gray-200 hover:bg-office-gray-100 border-2 border-gray-300 border-dashed w-full group">
            <div v-if="!loading" class="flex flex-col items-center space-y-1 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0"
                     :class="{ 'w-8 h-8' : size === 'small', 'w-12 h-12' : size === 'large' }"
                     viewBox="0 0 49.055 42.679">
                    <g transform="translate(-182.014 -40.107)">
                        <g transform="translate(182.913 48.133) rotate(-13)"
                           class="fill-transparent group-hover:fill-office-sky-900">
                            <path
                                d="M11.088,31.681h9.5c7.92,0,11.088-3.168,11.088-11.088v-9.5C31.681,3.168,28.513,0,20.593,0h-9.5C3.168,0,0,3.168,0,11.088v9.5C0,28.513,3.168,31.681,11.088,31.681Z"
                                transform="translate(0)"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                            />
                        </g>
                        <g transform="translate(195.461 43.014) rotate(7)"
                           class="fill-[#292d32] group-hover:fill-office-sky-950">
                            <rect width="34" height="34" rx="9" transform="translate(0.083 1.792)" fill="#fff" />
                            <path
                                d="M8.49,0H3.468A3.128,3.128,0,0,0,.393,1.708,3.57,3.57,0,0,0,0,3.468V8.49a3.188,3.188,0,0,0,3.468,3.468H8.49a3.57,3.57,0,0,0,1.76-.393A3.128,3.128,0,0,0,11.958,8.49V3.468A3.188,3.188,0,0,0,8.49,0ZM10.1,6.714a1.1,1.1,0,0,1-.7.308H6.987v.871L7,9.4a1.08,1.08,0,0,1-.325.735,1.009,1.009,0,0,1-.7.29A1.028,1.028,0,0,1,4.954,9.4V7l-2.392.017A1.043,1.043,0,0,1,1.537,5.979,1.028,1.028,0,0,1,2.563,4.954l1.5.017h.888V2.58A1.025,1.025,0,1,1,7,2.58L6.987,3.793V4.954H9.4a1.028,1.028,0,0,1,1.025,1.025A1.163,1.163,0,0,1,10.1,6.714Z"
                                transform="translate(23.917 0)"
                            />
                            <path d="M8.132,4.066A4.066,4.066,0,1,1,4.066,0,4.066,4.066,0,0,1,8.132,4.066Z"
                                  transform="translate(7.892 7.893)" fill="currentColor" />
                            <path
                                d="M32.407,10.25h-.8v7.875l-.222-.188a3.809,3.809,0,0,0-4.818,0l-7.107,6.1a3.809,3.809,0,0,1-4.818,0l-.581-.478a3.794,3.794,0,0,0-4.51-.239L3.16,27.607a9.11,9.11,0,0,1-.6-3.365V9.925c0-4.818,2.545-7.363,7.363-7.363H23.917v-.8A3.57,3.57,0,0,1,24.31,0H9.925C3.707,0,0,3.707,0,9.925V24.241a11.485,11.485,0,0,0,.957,4.852c1.469,3.246,4.613,5.074,8.969,5.074H24.241c6.218,0,9.925-3.707,9.925-9.925V9.857A3.57,3.57,0,0,1,32.407,10.25Z"
                                transform="translate(0 1.708)"
                                fill="currentColor"
                            />
                        </g>
                    </g>
                </svg>
                <div
                    class="flex flex-col justify-center font-medium text-gray-600 group-hover:text-office-sky-900 gap-1">
                    <span class="leading-3" :class="{ 'text-xxs' : size === 'small', 'text-base' : size === 'large' }">Klik for at uploade billeder</span>
                    <span class="font-light text-gray-600 leading-3"
                          :class="{ 'text-[10px]' : size === 'small', 'text-xs' : size === 'large' }">HEIC, HEIF, JPEG, JPG, PNG filtyper er tilladt</span>
                    <input id="file-upload" name="file-upload" type="file" multiple="multiple"
                           class="sr-only" @change="previewMultiImage" />
                </div>
            </div>
            <div v-if="loading" class="flex flex-col items-center gap-1">
                <loading-spinner :loading="loading"></loading-spinner>
                <span class="text-sm font-medium text-gray-600">{{ preview_list.length }} billeder er ved at blive uploadet...</span>
            </div>
        </label>

        <div v-if="!usedInTable">
            <light-box
                :show="showLightbox"
                :current-image="currentImage"
                @close="toggleLightbox"
                @move="moveImage"
            />
            <div class="border rounded-2xl p-6 my-4">
                <span class="font-bold">Forhåndsvisning</span>
                <template v-if="preview_list.length">
                    <p class="mt-2 mb-1">Følgende billeder er ved at blive uploadet.</p>
                    <div class="flex items-center flex-wrap gap-2 w-full">
                        <div v-for="(item, index) in preview_list" :key="index"
                             class="flex flex-col items-start gap-2 h-auto">
                            <img :src="item"
                                 class="object-cover aspect-[2/3] w-full h-full max-w-[150px] max-h-[250px] rounded-md opacity-50"
                                 alt="" />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p class="mt-2">Upload et eller flere billeder for at starte processen, hvor der vil blive vist en
                        forhåndsvisning af de billeder der er ved at blive uploadet.</p>
                </template>
                <div v-if="errors">
                    <p class="mt-4 text-sm font-medium text-red-700 bg-red-50 px-2 py-1 w-fit">{{ errors }}</p>
                </div>
            </div>

            <draggable
                v-model="ourProductImages"
                v-bind="dragOptions"
                class="grid grid-cols-1 xxs:grid-cols-2 gap-2 bg-gray-100 p-4 rounded-xl"
                @start="drag = true"
                @end="updateOrder"
                item-key="id"
            >
                <template #item="{ element, index }">
                    <div class="relative flex h-auto cursor-move hover:opacity-90 group">
                        <img :alt="element.alt" :src="element.real_image_path"
                             class="object-contain aspect-[2/3] w-full h-full rounded-lg" width="auto"
                             height="auto" @click="toggleLightbox(element)" />
                        <div
                            class="absolute bottom-0 left-0 right-0 bg-black/[.7] text-white p-2 text-sm font-bold rounded-b-md w-full flex flex-col">
                            <button :class="[{ 'bg-green-500': !element.is_draft }, { 'bg-red-500': element.is_draft }]"
                                    @click="updateImage(element)">{{ element.is_draft ? "Ikke publiceret" : "Publiceret"
                                }}
                            </button>
                            <div class="flex flex-col w-full mt-2">
                                <label class="sr-only" for="order">Nummerorden</label>
                                <div class="flex items-center gap-2">
                                    <div>Nummerorden</div>
                                    <input name="order" id="order" v-model="element.order" type="number"
                                           min="1"
                                           max="99" disabled
                                           class="bg-transparent border-none p-0 m-0 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none">
                                </div>
                            </div>
                        </div>
                        <div class="absolute top-2 right-2 xs:hidden xs:group-hover:inline-flex">
                            <button class="admin-delete-button smallest-button" @click="deleteImage(element, index)">
                                Slet
                            </button>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
    </div>
</template>

<script>
    import draggable from "vuedraggable";
    import LoadingSpinner from "./LoadingSpinner.vue";
    import axios from "axios";
    import LightBox from "../../Components/LightBox.vue";

    export default {
        name: "UploadImage",
        components: {
            LoadingSpinner,
            draggable,
            LightBox
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            productImages: {
                type: Array
            },
            variant: {
                type: Object,
                required: true
            },
            // loading: {
            //     type: Boolean,
            //     default: false,
            //     required: true
            // },
            usedInTable: {
                type: Boolean,
                default: false,
                required: true
            },
            size: {
                type: String,
                default: "large",
                required: true
            }
        },
        data() {
            return {
                errors: null,
                preview: null,
                drag: false,
                image: null,
                preview_list: [],
                image_list: [],
                loading: false,
                ourProductImages: this.productImages,
                showLightbox: false,
                currentImage: null
            };
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "connected",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        mounted() {
            window.addEventListener("keydown", this.onKeydown);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.onKeydown);
        },
        methods: {
            previewMultiImage(event) {
                const input = event.target;
                let count = input.files.length;
                let index = 0;
                if (input.files) {
                    while (count--) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.preview_list.push(e.target.result);
                        };
                        this.image_list.push(input.files[index]);
                        reader.readAsDataURL(input.files[index]);
                        index++;
                    }

                    this.uploadToRemote();
                }
            },
            uploadToRemote() {
                this.loading = true;
                const form = new FormData();
                form.append("images", this.image_list);

                for (let i = 0; i < this.image_list.length; i++) {
                    const image = this.image_list[i];
                    form.append("images[" + i + "]", image);
                }

                axios
                    .post("/fileupload/" + this.product.uuid + "/" + this.variant.uuid, form)
                    .then((response) => {
                        window.location.reload();
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data.errors);
                        this.errors = error.response.data.errors;
                        this.loading = false;
                        this.preview_list = [];
                        this.image_list = [];
                        form.delete("images");
                    });
            },
            updateOrder() {
                this.drag = false;
                console.log("our images", this.ourProductImages);

                axios
                    .patch("/products/" + this.product.uuid + "/" + this.variant.uuid + "/update-image-order", {
                        images: this.ourProductImages
                    })
                    .then((response) => {
                        console.log("hhh", response.data);
                        this.ourProductImages = response.data.hasOwnProperty("images") ? response.data.images : [];
                        // this.ourProductImages = response.data.images;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            updateImage(image) {
                image.is_draft = !image.is_draft;

                axios
                    .patch("/product-images/" + image.uuid + "/update", {
                        is_draft: image.is_draft
                    })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            deleteImage(image, index) {
                if (confirm("Er du sikker på du vil slette billedet?")) {
                    axios
                        .delete("/product-images/" + image.uuid + "/delete")
                        .then(() => {
                            this.ourProductImages.splice(index, 1);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            },
            toggleLightbox(image = null) {
                this.currentImage = image;
                this.showLightbox = !!image;
            },
            moveImage(direction) {
                // find index of current image
                let index = this.ourProductImages.findIndex(img => img === this.currentImage);
                // calculate new index based on direction
                let newIndex = (index + direction + this.ourProductImages.length) % this.ourProductImages.length;
                // set currentImage to newImage
                this.currentImage = this.ourProductImages[newIndex];
            },
            onKeydown(event) {
                const LEFT_ARROW = 37;
                const RIGHT_ARROW = 39;
                const ESCAPE = 27;

                if (!this.currentImage || !this.showLightbox) return; // If there's no current image or Lightbox is not open, just return

                let newIndex;
                const currentIndex = this.ourProductImages.findIndex(image => image === this.currentImage);

                if (currentIndex < 0) return;  // exit if currentImage is not in ourProductImages

                if (event.keyCode === RIGHT_ARROW) {
                    newIndex = (currentIndex + 1) % this.ourProductImages.length;
                } else if (event.keyCode === LEFT_ARROW) {
                    newIndex = (currentIndex - 1 + this.ourProductImages.length) % this.ourProductImages.length;
                } else if (event.keyCode === ESCAPE) {
                    this.toggleLightbox(null);
                    return;
                }

                this.currentImage = this.ourProductImages[newIndex];
            }
        }
    };
</script>

<style>
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
</style>
