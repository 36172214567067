<template>
    <div>
        <button v-if="!isFavored" @click="addToFavoredList"
                class="shop-button favour-button flex items-center justify-between"
                :class="{ 'p-1.5 xs:p-2.5': buttonType === 'small', 'p-2 xs:p-4': buttonType === 'large' }"
                title="Tilføj til wishlist">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="fillColor" stroke="currentColor"
                 stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
            </svg>
        </button>
        <button v-if="isFavored" @click="removeFromFavoredList"
                class="shop-button favour-button flex items-center justify-between"
                :class="{ 'is-favored' : isFavored, 'p-1.5 xs:p-2.5': buttonType === 'small', 'p-2 xs:p-4': buttonType === 'large' }"
                title="Fjern fra wishlist">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="fillColor" stroke="currentColor"
                 stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
            </svg>
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            favored: {
                type: Boolean
            },
            authenticated: {
                type: Boolean
            },
            productId: {
                type: Number
            },
            buttonType: {
                type: String,
                default: "small",
                required: false
            }
        },
        data() {
            return {
                isFavored: this.favored
            };
        },
        methods: {
            addToFavoredList(e) {
                e.preventDefault();
                if (this.authenticated) {
                    axios.post(`/favorites/${this.productId}`).then((response) => {
                        if (response.status === 200) {
                            this.isFavored = true;
                        }
                    });
                } else {
                    //@TODO show modal here
                    alert("Create an account to favorite products.");
                }
            },
            removeFromFavoredList(e) {
                e.preventDefault();
                this.isFavored = false;
                if (this.authenticated) {
                    axios.delete(`/favorites/${this.productId}`).then((response) => {
                        if (response.status === 200) {
                            this.isFavored = false;
                        }
                    });
                }
            }
        }
    };
</script>
