<template>
    <div class="relative">
        <div v-if="loading">
            <div v-show="loading" class="loading-spinner"
                 :class="{ 'w-10 h-10': size === 'large', 'w-8 h-8': size === 'medium', 'w-6 h-6': size === 'small', 'w-4 h-4': size === 'mini'}"></div>
        </div>
        <span v-if="!loading"><slot /></span>
    </div>
</template>

<script>
    export default {
        name: "LoadingSpinner",
        props: {
            loading: {
                type: Boolean
            },
            size: {
                type: String,
                default: "large",
                required: false
            }
        }
    };
</script>