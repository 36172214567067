<template>
    <div class="shop-product-card" :class="{ 'shop-product-sold-card' : variant.sold_at }">
        <a class="shop-product-image-container" :href="productLink">
            <div v-if="productHasImages">
                <img :srcset="srcSetString"
                     sizes="(max-width: 600px) 600px, (max-width: 800px) 800px, 1200px"
                     :src="defaultImage.real_image_path"
                     :alt="defaultImage.alt"
                     class="shop-top-image"
                >
                <img :srcset="srcSetString"
                     sizes="(max-width: 600px) 600px, (max-width: 800px) 800px, 1200px"
                     :src="defaultImage.real_image_path"
                     :alt="defaultImage.alt"
                     class="shop-second-image"
                >
            </div>
            <div v-else>
                <div v-if="newProducts">
                    <img src="/assets/shop/minitopolis-product-placeholder-light-gray.jpg"
                         alt="minitopolis-no-image-placeholder"
                         class="shop-top-image">
                    <img src="/assets/shop/minitopolis-product-placeholder-light-gray.jpg"
                         alt="minitopolis-no-image-placeholder"
                         class="shop-second-image">
                </div>
                <div v-else>
                    <img src="/assets/shop/minitopolis-product-placeholder-gray.jpg"
                         alt="minitopolis-no-image-placeholder"
                         class="shop-top-image">
                    <img src="/assets/shop/minitopolis-product-placeholder-gray.jpg"
                         alt="minitopolis-no-image-placeholder"
                         class="shop-second-image">
                </div>
                <!--                <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg"-->
                <!--                     alt="Front of men&#039;s Basic Tee in black." class="shop-top-image">-->
                <!--                <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg"-->
                <!--                     alt="Front of men&#039;s Basic Tee in black." class="shop-second-image">-->
            </div>
            <!-- Current no.of viewers-->
            <div class="absolute z-30 top-5 left-0">
                <div class="bg-white bg-opacity-[.50] leading-3 p-1 rounded-r-lg">
            <span class="inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 text-gray-800" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                <span class="text-xs font-medium ml-0.5 mr-1 text-gray-800">6</span>
                <span class="active-circle"></span>
            </span>
                </div>
            </div>
            <!-- Tags-->
            <div class="absolute z-30 top-5 right-1">
                <div v-if="variant.tags && variant.tags.length"
                     class="inline-flex items-center justify-end flex-wrap gap-1">
                       <span v-for="tag in variant.tags" :key="tag.value"
                             class="font-medium inline-flex items-center w-fit">
                           <tag-badge :name="tag.name"
                                      :bg-color="tag.bg_color"
                                      :text-color="tag.text_color"
                                      :border-color="tag.border_color"
                                      size="mini"></tag-badge>
                       </span>
                </div>
            </div>
            <!-- If Sold-->
            <div class="absolute z-30 bottom-0 left-0 right-0 w-full">
                <div v-if="variant.sold_at"
                     class="bg-stone-300 text-center text-xs text-black font-medium p-1">
                    <span class="font-light">Solgt d.</span> {{ formatDate(variant.sold_at) }}
                </div>
            </div>
            <div class="shop-product-card-buttons">
                <div class="flex items-center justify-center w-full gap-2">
                    <!--                Quick view / Open product in modal-->
                    <!--                <div class="shop-button p-1.5 xs:p-2.5 flex items-center justify-between" title="Quick view">-->
                    <!--                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">-->
                    <!--                        <g transform="translate(-108 -188)">-->
                    <!--                            <path d="M7.16,3.58A3.58,3.58,0,1,1,3.58,0,3.576,3.576,0,0,1,7.16,3.58Z"-->
                    <!--                                  transform="translate(116.42 196.42)" fill="none" stroke="currentColor"-->
                    <!--                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />-->
                    <!--                            <path-->
                    <!--                                d="M9.785,16.55c3.53,0,6.82-2.08,9.11-5.68a5.326,5.326,0,0,0,0-5.19C16.6,2.08,13.315,0,9.785,0S2.965,2.08.675,5.68a5.326,5.326,0,0,0,0,5.19C2.965,14.47,6.255,16.55,9.785,16.55Z"-->
                    <!--                                transform="translate(110.215 191.72)" fill="none" stroke="currentColor"-->
                    <!--                                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />-->
                    <!--                        </g>-->
                    <!--                    </svg>-->
                    <!--                </div>-->
                    <add-product-button :product="product" :variant="variant" button-type="small"></add-product-button>
                    <!--                Add to wishlist button-->
                    <add-product-to-list-button :product="product" :variant="variant" :authenticated="true"
                                                :favorited="true" button-type="small"></add-product-to-list-button>
                </div>
            </div>
        </a>
        <!-- Product details and variants -->
        <div class="mt-2 flex items-center w-full mx-auto px-0.5">
            <div class="flex flex-col justify-between w-full leading-3">
                <a href="#" class="line-clamp-1 mb-1">
                    <span v-if="product.brand" class="font-bold text-xs">{{ product.brand.name }}</span>
                </a>
                <!-- 'a' tag href will use the router-link or nuxt-link instead with the correct route to the product -->
                <a :href="productLink" class="line-clamp-1 mb-1">
                    <span class="font-medium text-sm">{{ product.pretty_name }}</span>
                </a>
                <!-- Product variants, use v-for to loop through your variants -->
                <div class="flex flex-col gap-2">
                    <!-- The SVG will remain the same since it's static -->
                    <div v-if="variant.size" class="flex items-center gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5 -rotate-45"
                             viewBox="0 0 24 24">
                            <g transform="translate(-620 -188)">
                                <path d="M0,10H20V0H0S0,8.612,0,10Z" transform="translate(622 195)" fill="#292d32"
                                      opacity="0.2" />
                                <path d="M1.5,0V5a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,5V0Z"
                                      transform="translate(637.25 195)" fill="#292d32" />
                                <path d="M1.5,0V3.8a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,3.8V0Z"
                                      transform="translate(625.25 195)" fill="#292d32" />
                                <path d="M1.54,0,1.5,5.01a.747.747,0,0,1-.75.74H.74A.749.749,0,0,1,0,4.99L.04,0Z"
                                      transform="translate(629.25 195)" fill="#292d32" />
                                <path d="M1.5,0V2.6a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,2.6V0Z"
                                      transform="translate(633.25 195)" fill="#292d32" />
                            </g>
                        </svg>
                        <p class="text-sm leading-3">{{ "Str. " + variant.size.name }}</p>
                    </div>
                    <div v-if="variant.discount" class="flex flex-col">
                        <div class="flex">
                            <p class="text-sm xs:text-base font-medium text-red-500">
                                <price :price="variant.discounted_price"></price>
                            </p>
                        </div>
                        <div class="flex items-center -mt-0.5">
                            <p class="text-gray-500 line-through text-xs mr-1">
                                <price :price="variant.price"></price>
                            </p>
                            <p class="text-xxs xs:text-xs text-red-500 font-medium">-{{ variant.discount }}%</p>
                        </div>
                    </div>
                    <div v-else class="flex text-sm xs:text-base font-medium text-topolis-blue-700 mr-2 xs:mr-0">
                        <price :price="variant.price"></price>
                    </div>
                </div>

                <!-- Display price and discount based on the variant -->
                <!-- You may have to adjust your data structure for easy access to price and discounts -->

                <!-- Variant images -->
                <!-- Also with v-for loop for displaying each of your variants' images -->
                <div class="mt-2 flex items-center gap-2" v-if="productHasImages">
                    <div class="rounded bg-zinc-50 w-20 h-16 border-2 border-topolis-bright-purple-300">
                        <img :src="image.real_image_path"
                             alt="Sample description"
                             class="shop-top-image rounded"
                             v-for="(variant, index) in product.variants"
                             :key="index" />
                    </div>
                </div>
                <div class="mt-2 flex items-center gap-2">
                    <div class="rounded bg-zinc-50 w-[30px] h-[40px] border-2 border-topolis-bright-purple-300">
                        <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg"
                             alt="Front of men&#039;s Basic Tee in black." class="shop-top-image rounded">
                    </div>
                    <div class="rounded bg-zinc-800 w-[30px] h-[40px]">
                        <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-04.jpg"
                             alt="Front of men&#039;s Basic Tee in black." class="shop-top-image rounded">
                    </div>
                    <div class="rounded bg-zinc-500 w-[30px] h-[40px]">
                        <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg"
                             alt="Front of men&#039;s Basic Tee in black." class="shop-top-image rounded">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Price from "./Price.vue";
    import TagBadge from "../Pages/Common/TagBadge.vue";
    import AddProductButton from "./AddProductButton.vue";
    import AddProductToListButton from "./AddProductToListButton.vue";

    export default {
        components: { Price, TagBadge, AddProductButton, AddProductToListButton },
        props: {
            product: {},
            variant: {},
            newProducts: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                defaultImage: null,
                mediumImage: null,
                mobileImage: null
            };
        },
        computed: {
            productLink() {
                return `/shop/test/produkter/${this.product.slug}`;
            },
            productHasImages() {
                return !!this.defaultImage && !!this.mediumImage && !!this.mobileImage;
            },
            srcSetString() {
                return `${this.defaultImage.real_image_path} 1200w, ${this.mediumImage.real_image_path} 800w, ${this.mobileImage.real_image_path} 600w`;
            }
        },
        created() {
            this.product.variants.forEach(variant => {
                variant.images.forEach(image => {
                    switch (image.responsive_size) {
                        case "default":
                            this.defaultImage = image;
                            break;
                        case "medium":
                            this.mediumImage = image;
                            break;
                        case "mobile":
                            this.mobileImage = image;
                            break;
                    }
                });
            });
        },
        methods: {
            formatDate(dateString) {
                let date = new Date(dateString);
                let day = date.getDate();
                let monthIndex = date.getMonth();
                let year = date.getFullYear();
                let hours = date.getHours();
                let minutes = date.getMinutes();

                // Format the date
                day = day < 10 ? "0" + day : day;
                // Danish month names
                const months = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
                monthIndex = months[monthIndex];
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;

                return day + ". " + monthIndex + " " + hours + ":" + minutes;
            }
        }
    };

</script>
