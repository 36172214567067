<template>
    <transition name="slide">
        <div class="drawer-bizz"
             :class="{ 'active-right' : showDrawerValue && !directionLeft, 'active-left' : showDrawerValue && directionLeft, '-right-72': adminPage && !directionLeft && !search, '-left-72': adminPage && directionLeft && !search, '-right-[225px] xxs:-right-[300px]': !adminPage && !directionLeft && !search, '-left-[225px] xxs:-left-[300px]': !adminPage && directionLeft && !search, '-right-[490px]': !directionLeft && search, '-left-[490px]': directionLeft && search }">
            <div :id="'drawer-' + name" class="h-full bg-white"
                 :class="{ 'w-72': adminPage && !search, 'w-[225px] xxs:w-[300px]': !adminPage && !search, 'w-[490px]' : search }">
                <div class="h-full">
                    <div class="flex flex-col">
                        <div class="drawer-header flex-1 py-2 px-4 border-b-2 border-gray-100">
                            <div class="flex items-center justify-between w-full">
                                <div class="flex-1">
                                    <h4 class="text-lg font-bold">{{ drawerHeader }}</h4>
                                </div>
                                <button class="hover:rounded-full hover:bg-gray-100 p-1" @click="closeDrawer">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-7"
                                         viewBox="0 0 32 32">
                                        <g transform="translate(-236 -252)">
                                            <path d="M0,14.935,14.935,0" transform="translate(244.532 260.532)"
                                                  fill="none" stroke="#292d32" stroke-linecap="round"
                                                  stroke-linejoin="round" stroke-width="2" />
                                            <path d="M14.935,14.935,0,0" transform="translate(244.532 260.532)"
                                                  fill="none" stroke="#292d32" stroke-linecap="round"
                                                  stroke-linejoin="round" stroke-width="2" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="drawer-items flex-auto flex flex-col h-full overflow-auto">
                            <div class="drawer-inner p-0 flex flex-col relative" style="height: calc(100vh - 80px)">
                                <div v-if="name === 'create-guide' && guide"
                                     class="content p-4 overflow-auto flex-auto relative mb-16 lg:mb-0">
                                    <div class="flex flex-col space-y-8">
                                        <div class="flex flex-col">
                                            <h5 class="font-black hyphens-auto">1. Start med kategorien</h5>
                                            <div class="mt-2 flex flex-col gap-2">
                                                <p>Søg efter din kategori, hvor du så skal vælge en af de muligheder der
                                                    popper frem.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-col">
                                            <h5 class="font-black hyphens-auto">2. Udfyld mulige felter</h5>
                                            <div class="mt-2 flex flex-col gap-4">
                                                <p>Hver kategori er tilknyttet en såkaldt overkategori. Dette betyder at
                                                    ved valgt kategori, så åbnes de felter der er relevante for dit
                                                    givne produkt.</p>
                                                <div class="border border-[#E6E6E6] rounded-2xl h-full">
                                                    <div class="flex items-center w-full h-full relative">
                                                        <div
                                                            class="w-20 h-16 bg-topolis-blue-50 flex justify-center items-center relative mr-2 rounded-l-2xl">
                                                            <div v-if="currentParentCategory">
                                                                <img v-if="currentParentCategory.value === 144"
                                                                     class="flex-shrink-0 min-w-0 w-10 -mt-2"
                                                                     src="/assets/categories/equipment.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 351"
                                                                     class="flex-shrink-0 min-w-0 w-10 -mt-2"
                                                                     src="/assets/categories/clothes.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 221"
                                                                     class="flex-shrink-0 min-w-0 w-10 -mt-2"
                                                                     src="/assets/categories/jacket.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 213"
                                                                     class="flex-shrink-0 min-w-0 w-10 -mt-2"
                                                                     src="/assets/categories/toys.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 620"
                                                                     class="flex-shrink-0 min-w-0 w-7 -mt-2"
                                                                     src="/assets/categories/interior.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 621"
                                                                     class="flex-shrink-0 min-w-0 w-10 -mt-2"
                                                                     src="/assets/categories/footwear.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 376"
                                                                     class="flex-shrink-0 min-w-0 w-7 -mt-2"
                                                                     src="/assets/categories/accessories.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 377"
                                                                     class="flex-shrink-0 min-w-0 w-6 -mt-4"
                                                                     src="/assets/categories/books.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 132"
                                                                     class="flex-shrink-0 min-w-0 w-7 -mt-1"
                                                                     src="/assets/categories/other.svg" alt="" />
                                                                <img v-if="currentParentCategory.value === 378"
                                                                     class="flex-shrink-0 min-w-0 w-7 -mt-1"
                                                                     src="/assets/categories/unknown.svg" alt="" />
                                                                <span
                                                                    class="absolute bottom-0 left-0 right-0 mx-auto text-[9px] font-bold text-center">{{ currentParentCategory.label
                                                                    }}</span>
                                                                <!--                                                                <span v-if="currentParentCategory === 132"-->
                                                                <!--                                                                      class="absolute bottom-0 left-0 right-0 mx-auto text-[9px] font-bold text-center">Andet</span>-->
                                                                <!--                                                                <span v-if="currentParentCategory === 378"-->
                                                                <!--                                                                      class="absolute bottom-0 left-0 right-0 mx-auto text-[9px] font-bold text-center">Ukendt</span>-->
                                                            </div>
                                                            <div v-if="editMode && !currentParentCategory">
                                                                <img v-if="product.category_id === 132"
                                                                     class="flex-shrink-0 min-w-0 w-7 -mt-1"
                                                                     src="/assets/categories/other.svg" alt="" />
                                                                <span v-if="product.category_id === 132"
                                                                      class="absolute bottom-0 mx-auto text-[9px] font-bold text-center">Andet</span>
                                                                <img v-if="product.category_id === 378"
                                                                     class="flex-shrink-0 min-w-0 w-7 -mt-1"
                                                                     src="/assets/categories/unknown.svg" alt="" />
                                                                <span v-if="product.category_id === 378"
                                                                      class="absolute bottom-0 mx-auto text-[9px] font-bold text-center">Ukendt</span>
                                                            </div>
                                                            <span
                                                                v-if="!currentParentCategory && product.category_id !== 132 && product.category_id !== 378"
                                                                class="absolute top-10 xs:top-6 mx-auto text-[9px] font-semibold text-gray-500 -rotate-12"
                                                            >Overkategori</span
                                                            >
                                                        </div>
                                                        <p v-if="currentParentCategory">
                                                            Din overkategori er altså <span
                                                            class="font-bold">{{ currentParentCategory.label }}</span>
                                                            for din valgte kategori.
                                                        </p>
                                                        <p v-if="editMode && !currentParentCategory">
                                                            Din overkategori er altså <span
                                                            v-if="product.category_id === 132"
                                                            class="font-bold">Andet</span><span
                                                            v-if="product.category_id === 378"
                                                            class="font-bold">Ukendt</span> for din valgte
                                                            kategori.
                                                        </p>
                                                        <p v-if="!currentParentCategory && product.category_id !== 132 && product.category_id !== 378"
                                                           class="text-orange-700">Vælg en kategori for videre
                                                            forklaring</p>
                                                    </div>
                                                </div>
                                                <div class="space-y-2">
                                                    <p>Følgende felter skal altså udfyldes siden overstående
                                                        overkategori påkræver disse:</p>
                                                    <div v-if="currentParentCategory">
                                                        <div
                                                            v-if="currentParentCategory.value === 351 || currentParentCategory.value === 221 || currentParentCategory.value === 621"
                                                            class="flex flex-wrap w-full text-sm font-medium gap-2">
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Mærke
                                                            </div>
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Størrelse
                                                            </div>
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Farve
                                                            </div>
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Pris
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="currentParentCategory.value === 213 || currentParentCategory.value === 144 || currentParentCategory.value === 132 || currentParentCategory.value === 378"
                                                            class="flex flex-wrap w-full text-sm font-medium gap-2"
                                                        >
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Mærke
                                                            </div>
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Farve
                                                            </div>
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Kort beskrivelse
                                                            </div>
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Pris
                                                            </div>
                                                        </div>
                                                        <div v-if="currentParentCategory.value === 377"
                                                             class="flex flex-wrap w-full text-sm font-medium gap-2">
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Titel
                                                            </div>
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Kort beskrivelse
                                                            </div>
                                                            <div
                                                                class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                                Pris
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="editMode && !currentParentCategory && (product.category_id === 132 || product.category_id === 378)"
                                                        class="flex flex-wrap w-full text-sm font-medium gap-2">
                                                        <div
                                                            class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                            Mærke
                                                        </div>
                                                        <div
                                                            class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                            Farve
                                                        </div>
                                                        <div
                                                            class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                            Kort beskrivelse
                                                        </div>
                                                        <div
                                                            class="p-1 border border-gray-300 rounded-lg w-[45%] xxs:w-[48%]">
                                                            Pris
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex flex-col">
                                            <h5 class="font-black hyphens-auto">3. Pris</h5>
                                            <div class="mt-2 flex flex-col gap-2">
                                                <p>Sæt din ønskede pris for dit produkt.</p>
                                                <p>På alle overkategorier, hvor mærket skal udfyldes, der har vi
                                                    udviklet en prishjælper (nok mest brugbar ift. tøj og sko
                                                    overkategorierne).</p>
                                                <p>Den er blot til hjælp - ikke ment som et krav til prissætningen.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-col">
                                            <h5 class="font-black hyphens-auto">4. Evt./Andet</h5>
                                            <div class="mt-2 flex flex-col gap-2">
                                                <p>Hvis du ikke kan finde lige præcis din kategori/mærke/størrelse eller
                                                    farve, så vælg "Andet" i det felt, og dernæst beskriv inde i
                                                    "Produkt note" tekstfeltet, hvad og hvor der mangler.</p>
                                                <p>Eksempel kunne være, at dit mærke ikke findes, så vælg "Andet" i
                                                    feltet, og skriv hvilket navn på mærket der mangler. Indholdet i
                                                    feltet gemmes så snart produktet oprettes eller opdateres.</p>
                                                <p>Vi ser dette i systemet, og opdaterer vores database, og dertil dit
                                                    produkt.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="name === 'size-guide' && guide && (currentParentCategory && (currentParentCategory.value === 351 || currentParentCategory.value === 221))"
                                    class="content p-1 overflow-auto flex-auto relative mb-16 lg:mb-0">
                                    <div class="flex flex-col justify-center items-center rounded-b-lg mb-1">
                                        <div class="flex flex-col justify-between w-full">
                                            <table class="table w-full text-xs">
                                                <thead>
                                                <tr class="font-semibold text-gray-900 bg-gray-100 border-b border-gray-600">
                                                    <th class="px-2 py-1.5">Alder</th>
                                                    <th class="px-2 py-1.5">Størrelse</th>
                                                </tr>
                                                </thead>
                                                <tbody class="bg-gray-50">
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">Nyfødt</td>
                                                    <td class="px-2 py-1.5 border">50</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">1-2 mdr.</td>
                                                    <td class="px-2 py-1.5 border">56</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">3 mdr.</td>
                                                    <td class="px-2 py-1.5 border">62</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">6 mdr.</td>
                                                    <td class="px-2 py-1.5 border">68</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">9 mdr.</td>
                                                    <td class="px-2 py-1.5 border">74</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">1 år</td>
                                                    <td class="px-2 py-1.5 border">80</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">1,5 år</td>
                                                    <td class="px-2 py-1.5 border">86</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">2 år</td>
                                                    <td class="px-2 py-1.5 border">92</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">3 år</td>
                                                    <td class="px-2 py-1.5 border">98</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">4 år</td>
                                                    <td class="px-2 py-1.5 border">104</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">5 år</td>
                                                    <td class="px-2 py-1.5 border">110</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">6 år</td>
                                                    <td class="px-2 py-1.5 border">116</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">7 år</td>
                                                    <td class="px-2 py-1.5 border">122</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">8 år</td>
                                                    <td class="px-2 py-1.5 border">128</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">9 år</td>
                                                    <td class="px-2 py-1.5 border">134</td>
                                                </tr>
                                                <tr class="text-gray-700 text-center">
                                                    <td class="px-2 py-1.5 border">10 år</td>
                                                    <td class="px-2 py-1.5 border">140</td>
                                                </tr>
                                                <!--                            <tr class="text-gray-700 text-center">-->
                                                <!--                                <td class="px-2 py-1.5 border">11 år</td>-->
                                                <!--                                <td class="px-2 py-1.5 border">146</td>-->
                                                <!--                            </tr>-->
                                                <!--                            <tr class="text-gray-700 text-center">-->
                                                <!--                                <td class="px-2 py-1.5 border">12 år</td>-->
                                                <!--                                <td class="px-2 py-1.5 border">152</td>-->
                                                <!--                            </tr>-->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-center items-center rounded-lg">
                                        <div class="flex flex-col justify-between w-full md:pb-2">
                                            <div class="p-1 text-center">
                                                <h5 class="app-h5">OBS</h5>
                                                <p class="text-xxs font-medium break-words hyphens-auto">
                                                    Vær OBS på mærker hvor størrelsen går udenfor den almindelige
                                                    størrelsesguide. Hvis dette er tilfældet, såsom <span
                                                    class="font-bold underline">Joha</span> og
                                                    <span class="font-bold underline">petit bateau</span>, <span
                                                    class="font-bold underline">går I altid en størrelse ned</span>.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="name === 'size-guide' && guide && !currentParentCategory"
                                     class="content p-4 overflow-auto flex-auto relative">
                                    <h5 class="hyphens-auto">Vælg en kategori for at se den relevante
                                        størrelsesguide</h5>
                                </div>
                                <div
                                    v-else-if="name === 'size-guide' && guide && (currentParentCategory && (currentParentCategory.value !== 351 || currentParentCategory.value !== 221))"
                                    class="content p-4 overflow-auto flex-auto relative">
                                    <h5 class="hyphens-auto">Der er ingen størrelsesguide til din valgte kategori</h5>
                                </div>
                                <div v-if="name === 'color-guide' && guide"
                                     class="content p-1 overflow-auto flex-auto relative mb-16 lg:mb-0">
                                    <h5 class="p-1 hyphens-auto mb-2">Herunder ses vores farvekatelog. Du kan tilføje og
                                        fravælge ved at klikke på dem.</h5>
                                    <div class="flex flex-col gap-2">
                                        <!--                                        <p class="mb-1"><span class="font-medium">Blå</span> nuancer</p>-->
                                        <div class="flex flex-wrap items-stretch justify-between gap-1 mb-3">
                                            <button v-for="item in neutralItems" :key="item.value"
                                                    @click.stop="isColorSelected(item) ? null : onColorSelect(item)"
                                                    type="button"
                                                    :class="{ 'bg-gray-200 hover:bg-gray-200': isColorSelected(item) }"
                                                    class="flex items-center justify-start text-left text-black hover:text-black hover:no-underline hover:bg-gray-100 p-1 w-auto basis-full sm:basis-[33%] flex-1 h-auto relative overflow-visible">
                                                <color-badge model="color" :name="item.label" :hex="item.hex"
                                                             size="medium"
                                                             class="mr-1" />
                                                <div class="text-sm truncate">
                                                    {{ item.label }}
                                                </div>
                                                <span v-if="isColorSelected(item)" title="Fjern farve fra produktet"
                                                      class="ml-auto admin-border-icon-button admin-red-button admin-gray-icon-small-button"
                                                      type="button"
                                                      @click.stop="onColorRemove(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="flex-shrink-0 min-w-0 w-3"
                                                         fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor"
                                                         stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                        <!--                                        <p class="mb-1"><span class="font-medium">Grønne</span> nuancer</p>-->
                                        <div class="flex flex-wrap items-stretch justify-between gap-1 mb-3">
                                            <button v-for="item in greenItems" :key="item.value"
                                                    @click.stop="isColorSelected(item) ? null : onColorSelect(item)"
                                                    type="button"
                                                    :class="{ 'bg-gray-200 hover:bg-gray-300': isColorSelected(item) }"
                                                    class="flex items-center justify-start text-left text-black hover:text-black hover:no-underline hover:bg-gray-100 p-1 w-auto basis-full sm:basis-[33%] flex-1 h-auto relative overflow-visible">
                                                <color-badge model="color" :name="item.label" :hex="item.hex"
                                                             size="medium"
                                                             class="mr-1" />
                                                <div class="text-sm truncate">
                                                    {{ item.label }}
                                                </div>
                                                <span v-if="isColorSelected(item)" title="Fjern farve fra produktet"
                                                      class="ml-auto admin-border-icon-button admin-red-button admin-gray-icon-small-button"
                                                      type="button"
                                                      @click.stop="onColorRemove(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="flex-shrink-0 min-w-0 w-3"
                                                         fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor"
                                                         stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                        <!--                                        <p class="mb-1"><span class="font-medium">Røde</span> nuancer</p>-->
                                        <div class="flex flex-wrap items-stretch justify-between gap-1 mb-3">
                                            <button v-for="item in redItems" :key="item.value"
                                                    @click.stop="isColorSelected(item) ? null : onColorSelect(item)"
                                                    type="button"
                                                    :class="{ 'bg-gray-200 hover:bg-gray-300': isColorSelected(item) }"
                                                    class="flex items-center justify-start text-left text-black hover:text-black hover:no-underline hover:bg-gray-100 p-1 w-auto basis-full sm:basis-[33%] flex-1 h-auto relative overflow-visible">
                                                <color-badge model="color" :name="item.label" :hex="item.hex"
                                                             size="medium"
                                                             class="mr-1" />
                                                <div class="text-sm truncate">
                                                    {{ item.label }}
                                                </div>
                                                <span v-if="isColorSelected(item)" title="Fjern farve fra produktet"
                                                      class="ml-auto admin-border-icon-button admin-red-button admin-gray-icon-small-button"
                                                      type="button"
                                                      @click.stop="onColorRemove(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="flex-shrink-0 min-w-0 w-3"
                                                         fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor"
                                                         stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                        <!--                                        <p class="mb-1"><span class="font-medium">Blå</span> nuancer</p>-->
                                        <div class="flex flex-wrap items-stretch justify-between gap-1 mb-3">
                                            <button v-for="item in blueItems" :key="item.value"
                                                    @click.stop="isColorSelected(item) ? null : onColorSelect(item)"
                                                    type="button"
                                                    :class="{ 'bg-gray-200 hover:bg-gray-300': isColorSelected(item) }"
                                                    class="flex items-center justify-start text-left text-black hover:text-black hover:no-underline hover:bg-gray-100 p-1 w-auto basis-full sm:basis-[33%] flex-1 h-auto relative overflow-visible">
                                                <color-badge model="color" :name="item.label" :hex="item.hex"
                                                             size="medium"
                                                             class="mr-1" />
                                                <div class="text-sm truncate">
                                                    {{ item.label }}
                                                </div>
                                                <span v-if="isColorSelected(item)" title="Fjern farve fra produktet"
                                                      class="ml-auto admin-border-icon-button admin-red-button admin-gray-icon-small-button"
                                                      type="button"
                                                      @click.stop="onColorRemove(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="flex-shrink-0 min-w-0 w-3"
                                                         fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor"
                                                         stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                        <!--                                        <p class="mb-1"><span class="font-medium">Blå</span> nuancer</p>-->
                                        <div class="flex flex-wrap items-stretch justify-between gap-1 mb-3">
                                            <button v-for="item in miscItems" :key="item.value"
                                                    @click.stop="isColorSelected(item) ? null : onColorSelect(item)"
                                                    type="button"
                                                    :class="{ 'bg-gray-200 hover:bg-gray-300': isColorSelected(item) }"
                                                    class="flex items-center justify-start text-left text-black hover:text-black hover:no-underline hover:bg-gray-100 p-1 w-auto basis-full sm:basis-[33%] flex-1 h-auto relative overflow-visible">
                                                <color-badge model="color" :name="item.label" :hex="item.hex"
                                                             size="medium"
                                                             class="mr-1" />
                                                <div class="text-sm truncate">
                                                    {{ item.label }}
                                                </div>
                                                <span v-if="isColorSelected(item)" title="Fjern farve fra produktet"
                                                      class="ml-auto admin-border-icon-button admin-red-button admin-gray-icon-small-button"
                                                      type="button"
                                                      @click.stop="onColorRemove(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="flex-shrink-0 min-w-0 w-3"
                                                         fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor"
                                                         stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                        <!--                                        <p class="mb-1"><span class="font-medium">Blå</span> nuancer</p>-->
                                        <div class="flex flex-wrap items-stretch justify-between gap-1 mb-3">
                                            <button v-for="item in leftOverItems" :key="item.value"
                                                    @click.stop="isColorSelected(item) ? null : onColorSelect(item)"
                                                    type="button"
                                                    :class="{ 'bg-gray-200 hover:bg-gray-300': isColorSelected(item) }"
                                                    class="flex items-center justify-start text-left text-black hover:text-black hover:no-underline hover:bg-gray-100 p-1 w-auto basis-full sm:basis-[33%] flex-1 h-auto relative overflow-visible">
                                                <color-badge model="color" :name="item.label" :hex="item.hex"
                                                             size="medium"
                                                             class="mr-1" />
                                                <div class="text-sm truncate">
                                                    {{ item.label }}
                                                </div>
                                                <span v-if="isColorSelected(item)" title="Fjern farve fra produktet"
                                                      class="ml-auto admin-border-icon-button admin-red-button admin-gray-icon-small-button"
                                                      type="button"
                                                      @click.stop="onColorRemove(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="flex-shrink-0 min-w-0 w-3"
                                                         fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor"
                                                         stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div v-if="name === 'pattern-guide' && guide"
                                     class="content p-1 overflow-auto flex-auto relative mb-16 lg:mb-0">
                                    <h5 class="p-1 hyphens-auto mb-2">Herunder ses vores mønsterkatelog. Du kan tilføje
                                        og
                                        fravælge ved at klikke på dem.</h5>
                                    <div class="flex flex-col gap-2">
                                        <!--                                        <p class="mb-1"><span class="font-medium">Blå</span> nuancer</p>-->
                                        <div class="flex flex-wrap items-stretch justify-between gap-1 mb-3">
                                            <button v-for="item in sortedPatternItems" :key="item.value"
                                                    @click.stop="isPatternSelected(item) ? null : onPatternSelect(item)"
                                                    type="button"
                                                    :class="{ 'bg-gray-200 hover:bg-gray-200': isPatternSelected(item) }"
                                                    class="flex items-center justify-start text-left text-black hover:text-black hover:no-underline hover:bg-gray-100 p-1 w-auto basis-full sm:basis-[33%] flex-1 h-auto relative overflow-visible">
                                                <color-badge model="color" :name="item.label" :hex="item.hex"
                                                             size="medium"
                                                             class="mr-1" />
                                                <div class="text-sm truncate">
                                                    {{ item.label }}
                                                </div>
                                                <span v-if="isPatternSelected(item)" title="Fjern mønster fra produktet"
                                                      class="ml-auto admin-border-icon-button admin-red-button admin-gray-icon-small-button"
                                                      type="button"
                                                      @click.stop="onPatternRemove(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="flex-shrink-0 min-w-0 w-3"
                                                         fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor"
                                                         stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div v-if="name === 'tag-guide' && guide"
                                     class="content p-1 overflow-auto flex-auto relative mb-16 lg:mb-0">
                                    <h5 class="p-1 hyphens-auto mb-2">Herunder ses vores tagkatelog. Du kan tilføje
                                        og
                                        fravælge ved at klikke på dem.</h5>
                                    <div class="flex flex-col gap-2">
                                        <!--                                        <p class="mb-1"><span class="font-medium">Blå</span> nuancer</p>-->
                                        <div class="flex flex-wrap items-stretch justify-between gap-1 mb-3">
                                            <button v-for="item in sortedTagItems" :key="item.value"
                                                    @click.stop="isTagSelected(item) ? null : onTagSelect(item)"
                                                    type="button"
                                                    :class="{ 'bg-gray-200 hover:bg-gray-200': isTagSelected(item) }"
                                                    class="flex items-center justify-start text-left text-black hover:text-black hover:no-underline hover:bg-gray-100 p-1 w-auto basis-full sm:basis-[33%] flex-1 h-auto relative overflow-visible">
                                                <tag-badge :name="item.label" :hex="item.hex" />
                                                <span v-if="isTagSelected(item)" title="Fjern tag fra produktet"
                                                      class="ml-auto admin-border-icon-button admin-red-button admin-gray-icon-small-button"
                                                      type="button"
                                                      @click.stop="onTagRemove(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="flex-shrink-0 min-w-0 w-3"
                                                         fill="none" viewBox="0 0 24 24"
                                                         stroke="currentColor"
                                                         stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div v-if="name === 'search'"
                                     class="content overflow-auto flex-auto relative mb-16 lg:mb-0">
                                    <div class="flex flex-col gap-2 p-4">
                                        <div class="relative">
                                            <input
                                                v-model="searchQuery"
                                                class="shop-search-input"
                                                type="search"
                                                :placeholder="$translate('Søg efter produkter, kategorier eller mærker')"
                                                @input="shopSearch"
                                            />
                                            <button v-if="searchQuery && searchQuery.length >= 1"
                                                    class="absolute right-0 top-0 shop-delete-search-input-btn mr-2"
                                                    @click="clearSearch">
                                                {{ $translate("Ryd") }}
                                            </button>
                                        </div>
                                    </div>
                                    <div v-if="searchQuery && searchQuery.length >= 2"
                                         class="mt-2 flex flex-col gap-4">
                                        <div v-if="searchResults.products && searchResults.products.length > 0">
                                            <h3 class="text-xl font-bold mb-2 px-6">{{ $translate("Products") }}</h3>
                                            <div v-for="product in searchResults.products" :key="product.id"
                                                 class="inline-flex flex-col gap-4 w-full">
                                                <a :href="productLink(product)" class="px-6 hover:bg-gray-100">
                                                    <span class="inline-flex">
                                                        {{ product.name }}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>

                                        <div v-if="searchResults.brands && searchResults.brands.length > 0"
                                             class="mt-2 flex flex-col gap-4">
                                            <h3 class="text-xl font-bold mb-2">{{ $translate("Brands") }}</h3>
                                            <div v-for="brand in searchResults.brands" :key="brand.id"
                                                 class="inline-flex flex-col gap-4 w-full">
                                                <a :href="brandLink(brand)" class="px-6 hover:bg-gray-100">
                                                    <span class="inline-flex">
                                                        {{ brand.name }}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>

                                        <div v-if="searchResults.categories && searchResults.categories.length > 0"
                                             class="mt-2 flex flex-col gap-4">
                                            <h3 class="text-xl font-bold mb-2">{{ $translate("Categories") }}</h3>
                                            <div v-for="category in searchResults.categories" :key="category.id"
                                                 class="inline-flex flex-col gap-4 w-full">
                                                <a :href="categoryLink(category)" class="px-6 hover:bg-gray-100">
                                                    <span class="inline-flex">
                                                        {{ category.name }}
                                                    </span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>

                                    <p v-else-if="searchQuery && searchQuery.length >= 2">No results found.</p>
                                </div>
                                <!--                                <div v-if="name === 'purchase' && toPurchase" class="info border-t-4 border-gray-300">-->
                                <!--                                    <div class="p-4 mb-16 lg:mb-0">-->
                                <!--                                        <div v-for="(item, index) in drawerItems" :key="index">-->
                                <!--                                            <div class="bg-gray-50 p-2 mb-4">-->
                                <!--                                                <p class="text-sm font-medium">{{ item.name }}</p>-->
                                <!--                                                <p class="text-sm font-medium">{{ item.price }}</p>-->
                                <!--                                            </div>-->
                                <!--                                        </div>-->

                                <!--                                        <div class="mb-4">-->
                                <!--                                            &lt;!&ndash;                                            <div class="text-left flex justify-between mb-2">&ndash;&gt;-->
                                <!--                                            &lt;!&ndash;                                                <span class="text-sm">Klik & Hent:</span>&ndash;&gt;-->
                                <!--                                            &lt;!&ndash;                                                <span class="font-bold text-sm">Gratis</span>&ndash;&gt;-->
                                <!--                                            &lt;!&ndash;                                            </div>&ndash;&gt;-->
                                <!--                                            <div class="text-left flex justify-between mb-2">-->
                                <!--                                                <span class="text-sm">Total DKK</span>-->
                                <!--                                                <div class="font-bold text-sm">Pris</div>-->
                                <!--                                            </div>-->
                                <!--                                        </div>-->
                                <!--                                        <div class="mb-4 w-full">-->
                                <!--                                            <a id="drawer-submit" href="#" class="button button-purple"-->
                                <!--                                               style="width: 100%"> Gå til betaling </a>-->
                                <!--                                        </div>-->
                                <!--                                        <button>Remove all (ADMIN)</button>-->
                                <!--                                        <div class="flex items-center sm:justify-center">-->
                                <!--                                            <img class="w-40" src="/assets/shop/minitopolis-shop-payments.png" alt="" />-->
                                <!--                                        </div>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <button class="sm:hidden absolute bottom-24 right-0 rounded-l-full bg-gray-100 p-1"
                                        @click="closeDrawer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                        <g transform="translate(-236 -252)">
                                            <path d="M0,14.935,14.935,0" transform="translate(244.532 260.532)"
                                                  fill="none" stroke="#292d32" stroke-linecap="round"
                                                  stroke-linejoin="round" stroke-width="2" />
                                            <path d="M14.935,14.935,0,0" transform="translate(244.532 260.532)"
                                                  fill="none" stroke="#292d32" stroke-linecap="round"
                                                  stroke-linejoin="round" stroke-width="2" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import ColorBadge from "./ColorBadge.vue";
    // import { mapState, mapActions } from "vuex";

    export default {
        components: { ColorBadge },
        props: {
            name: {
                type: String,
                required: true
            },
            showDrawer: {
                type: Boolean
            },
            guide: {
                type: Boolean
            },
            search: {
                type: Boolean,
                required: false,
                default: false
            },
            toPurchase: {
                type: Boolean
            },
            drawerHeader: {
                type: String,
                required: true
            },
            deactivateOutsideClick: {
                type: Boolean,
                default: false,
                required: false
            },
            directionLeft: {
                type: Boolean,
                default: false,
                required: false
            },
            currentParentCategory: {},
            product: {},
            colors: {},
            patterns: {},
            tags: {},
            editMode: {},
            adminPage: {},
            items: {
                type: [Array, Object],
                required: false
            }
        },
        data() {
            return {
                drawerOpenedListener: null,
                showDrawerValue: this.showDrawer,
                isClickedInside: false,
                selectedColors: [],
                excludedColors: ["Mange farver"],
                colorOrder: {
                    neutral: ["Sort", "Grå", "Lysegrå", "Koksgrå", "Mørkegrå", "Hvid", "Råhvid", "Creme", "Sand", "Sesamfarvet", "Beige"],
                    green: ["Grøn", "Lysegrøn", "Mørkegrøn", "Mint", "Flaskegrøn", "Army", "Støvet grøn", "Oliven", "Neon grøn", "Oliven", "Gul", "Karrygul"],
                    red: ["Rød", "Lyserød", "Mørkerød", "Bordeaux", "Brun", "Lysebrun", "Cognac", "Rosa", "Mørk rosa", "Gammelrosa", "Sart lyserød", "Pink", "Rust", "Fersken", "Koral", "Laksefarvet", "Orange", "Neon gul"],
                    blue: ["Blå", "Lyseblå", "Mørkeblå", "Babyblå", "Støvet blå", "Turkis", "Denim", "Navy", "Petroleum", "Lilla", "Lyselilla", "Mørkelilla"],
                    misc: ["Multi", "Guld", "Sølv", "Kobber", "Træfarvet", "Gennemsigtig"]
                },
                selectedPatterns: [],
                selectedTags: [],
                searchQuery: "",
                searchResults: []
            };
        },
        // computed: {
        //     ...mapState({
        //         drawerItems: state => state.drawerItems
        //     })
        // },
        mounted() {
            this.drawerOpenedListener = (openedDrawerName) => {
                if (openedDrawerName !== this.name && this.showDrawerValue) {
                    this.closeDrawer();
                }
            };
            this.emitter.on("drawerOpened", this.drawerOpenedListener);
            this.emitter.on("open-drawer", (value) => {
                this.openDrawer(value);
            });

            document.addEventListener("keydown", (e) => {
                const ESCAPEKEY = 27;
                if (this.showDrawerValue === true && e.keyCode === ESCAPEKEY) {
                    this.closeDrawer();
                }
            });
            this.selectedColors = this.colors;
            this.selectedPatterns = this.patterns;
            this.selectedTags = this.tags;
        },
        beforeUnmount() {
            this.emitter.off("drawerOpened", this.drawerOpenedListener);
        },
        computed: {
            colorGroups() {
                // Creates an array with all colors from all groups
                return [
                    ...this.colorOrder.neutral,
                    ...this.colorOrder.green,
                    ...this.colorOrder.red,
                    ...this.colorOrder.blue,
                    ...this.colorOrder.misc
                ];
            },
            neutralItems() {
                return this.items
                    .filter(item => this.colorOrder.neutral.includes(item.label))
                    .sort((a, b) => this.colorIndex(a.label, this.colorOrder.neutral) - this.colorIndex(b.label, this.colorOrder.neutral));
            },
            greenItems() {
                return this.items
                    .filter(item => this.colorOrder.green.includes(item.label))
                    .sort((a, b) => this.colorIndex(a.label, this.colorOrder.green) - this.colorIndex(b.label, this.colorOrder.green));
            },
            redItems() {
                return this.items
                    .filter(item => this.colorOrder.red.includes(item.label))
                    .sort((a, b) => this.colorIndex(a.label, this.colorOrder.red) - this.colorIndex(b.label, this.colorOrder.red));
            },
            blueItems() {
                return this.items
                    .filter(item => this.colorOrder.blue.includes(item.label))
                    .sort((a, b) => this.colorIndex(a.label, this.colorOrder.blue) - this.colorIndex(b.label, this.colorOrder.blue));
            },
            miscItems() {
                return this.items
                    .filter(item => this.colorOrder.misc.includes(item.label))
                    .sort((a, b) => this.colorIndex(a.label, this.colorOrder.misc) - this.colorIndex(b.label, this.colorOrder.misc));
            },
            leftOverItems() {
                // filter out items that are in the excluded colors list
                return this.items.filter(item =>
                    !this.colorGroups.includes(item.label) && !this.excludedColors.includes(item.label));
            },
            sortedPatternItems() {
                return this.items.sort((a, b) => a.name > b.name ? 1 : -1);
            },
            sortedTagItems() {
                return this.items.sort((a, b) => a.name > b.name ? 1 : -1);
            }
        },
        methods: {
            productLink(product) {
                return `/shop/test/produkter/${product.slug}`;
            },
            brandLink(brand) {
                return `/shop/test/brands/${brand.slug}`;
            },
            categoryLink(category) {
                return `/shop/test/kategorier/${category.parent.slug}/${category.slug}`;
            },
            // ...mapActions(["addItemToDrawer"]),
            openDrawer(drawerName) {
                if (this.name === drawerName) {
                    this.showDrawerValue = !this.showDrawerValue; // toggle the value
                    if (this.showDrawerValue) { // If the drawer is now open then emit the drawerOpened event
                        this.emitter.emit("drawerOpened", drawerName);
                    }
                }
            },
            closeDrawer() {
                this.showDrawerValue = false;
            },
            // outsideCloseDrawer() {
            //     console.log("hvaa1");
            //     if (!this.deactivateOutsideClick) {
            //         console.log("hvaa2");
            //         this.closeDrawer();
            //     }
            // },
            isColorSelected(color) {
                return this.selectedColors ? this.selectedColors.some(selectedColor => selectedColor.value === color.value) : false;
            },
            isPatternSelected(pattern) {
                return this.selectedPatterns ? this.selectedPatterns.some(selectedPattern => selectedPattern.value === pattern.value) : false;
            },
            isTagSelected(tag) {
                return this.selectedTags ? this.selectedTags.some(selectedTag => selectedTag.value === tag.value) : false;
            },
            colorIndex(color, colorGroup) {
                const index = colorGroup.indexOf(color);
                return index === -1 ? Infinity : index;
            },
            onColorSelect(color) {
                this.$emit("add-color", color);
            },
            onColorRemove(color) {
                console.log("what color", color);
                this.$emit("remove-color", color);
            },
            onPatternSelect(pattern) {
                this.$emit("add-pattern", pattern);
            },
            onPatternRemove(pattern) {
                this.$emit("remove-pattern", pattern);
            },
            onTagSelect(tag) {
                this.$emit("add-tag", tag);
            },
            onTagRemove(tag) {
                this.$emit("remove-tag", tag);
            },
            async shopSearch() {
                if (this.searchQuery.length >= 2) {
                    try {
                        const response = await axios.get("/shop/searching-shop", {
                            params: {
                                query: this.searchQuery
                            }
                        });

                        this.searchResults = response.data;
                    } catch (error) {
                        console.error("API call failed.", error);
                    }
                } else {
                    this.searchResults = [];
                }
            },
            clearSearch() {
                this.searchQuery = "";
            }
        }
    };
</script>

<style scoped>
    .shop-search-input::-ms-clear {
        display: none;
    }

    .shop-search-input::-ms-reveal {
        display: none;
    }

    .shop-search-input::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
    }
</style>