<template>
    <div class="flex flex-col mx-auto space-y-8">
        <profile v-if="authenticatedUser" class="mt-2" />

        <div class="flex flex-col">
            <div class="flex flex-col space-y-8">
                <span class="text-xl xs:text-2xl sm:text-3xl font-black">Gennemse din booking</span>
                <div class="flex flex-col items-center justify-between gap-6">
                    <h5 class="app-h5">Din periode er næsten booket, tjek venligst, at dine bookingoplysninger er
                        korrekte og bekræft dem nederst på siden.</h5>
                    <div class="flex items-center justify-between w-full">
                        <router-link to="/kalender" class="flex items-center hover:no-underline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-684 -188)">
                                    <path d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" />
                                    <path d="M16.83,0H0" transform="translate(687.67 200)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" />
                                </g>
                            </svg>
                            <span class="ml-1 text-xs xxs:text-base">Tilbage</span>
                        </router-link>
                        <bouncing-button data-cy="btn-booking-continue-stripe" type="button"
                                         :bouncing="chosenConcept !== null"
                                         :disabled="chosenConcept === null || disabled" @click.native="startCheckout">Gå
                            til betaling
                        </bouncing-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col w-full mx-auto text-left">
            <div class="space-y-4 p-4 sm:p-6 bg-white/[0.4] rounded-2xl shadow-md">
                <span class="app-h3">Booking information</span>
                <!--Concept-->
                <div>
                    <div v-if="chosenConcept === 'easy'" class="flex items-center space-x-2">
                        <img class="w-14" src="/assets/images/hanger-yellow.svg" alt="" />
                        <div class="flex flex-col">
                            <h4 class="app-h4 leading-3">
                                Nemt
                                <span class="font-medium">konceptet</span>
                            </h4>
                            <div class="flex items-center gap-1">
                                <div>
                                    <span v-if="chosenPeriod.concept === 'easy25'" class="font-bold">25</span>
                                    <span v-if="chosenPeriod.concept === 'easy50'" class="font-bold">50</span>
                                    <span v-if="chosenPeriod.concept === 'easy75'" class="font-bold">75</span>
                                    <span
                                        v-if="chosenPeriod.concept === 'easy100'"
                                        class="font-bold">100</span>
                                </div>
                                <div class="font-normal">varer</div>
                            </div>

                        </div>
                    </div>
                    <div v-if="chosenConcept === 'easier'" class="flex items-center space-x-2">
                        <img class="w-14" src="/assets/images/hanger-purple.svg" alt="" />
                        <div class="flex flex-col">
                            <h4 class="app-h4 leading-3">
                                Nemmere
                                <span class="font-medium">konceptet</span>
                            </h4>
                            <div class="flex items-center gap-1">
                                <div>
                                    <span v-if="chosenPeriod.concept === 'easier50'" class="font-bold">50</span>
                                    <span
                                        v-if="chosenPeriod.concept === 'easier100'"
                                        class="font-bold">100</span>
                                </div>
                                <div class="font-normal">varer</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="chosenConcept === 'easiest'" class="flex items-center space-x-2">
                        <img class="w-14" src="/assets/images/hanger-blue.svg" alt="" />
                        <div class="flex flex-col">
                            <h4 class="app-h4">
                                Nemmest
                                <span class="font-medium">konceptet</span>
                            </h4>
                            <div class="flex items-center gap-1">
                                <div>
                                    <span v-if="chosenPeriod.concept === 'easiest'" class="font-bold">100</span>
                                </div>
                                <div class="font-normal">varer</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Period-->
                <div>
                    <div class="flex">
                        <div class="flex-col space-y-4">
                            <h5 class="app-h5">
                                <span class="font-normal">
                                    Periode på
                                    <span class="font-bold">{{ chosenPeriod.period_length }}</span>
                                    <span class="font-normal"> uger med </span>
                                </span>
                                <span class="font-normal">
                                    <span class="font-bold">{{ chosenPeriod.commission }} %</span>
                                    <span> kommission</span>
                                </span>
                            </h5>
                            <div>
                                <h5 class="app-h5 flex flex-col">
                                    <span class="font-normal">
                                        Indlevering:
                                        <span class="font-bold">uge {{ selectedWeeks[0].week_number }}</span>
                                    </span>
                                </h5>
                                <div v-if="chosenConcept === 'easy'"
                                     class="px-3 py-1.5 rounded-2xl border border-blue-900 border-dashed mt-1">
                                    <div class="font-black text-sm">
                                        Ved Nemt skal man have oprettet, indleveret og klargjort sine varer en uge inden
                                        perioden starter.
                                    </div>
                                    <div class="text-sm mt-1">
                                        Du skal senest indlevere i mellem
                                        <span
                                            class="font-black text-black underline decoration-2 underline-offset-2 decoration-topolis-yellow-600">
                                            {{ selectedWeeks[0].start_date_pretty
                                            }} - {{ selectedWeeks[0].end_date_pretty }}
                                        </span>
                                        <span class="ml-1 font-medium">(Vær OBS på vores åbningsdage)</span>


                                    </div>
                                </div>
                                <div v-if="chosenConcept === 'easier'"
                                     class="px-3 py-1.5 rounded-2xl border border-blue-900 border-dashed mt-1">
                                    <div class="font-black text-sm">
                                        Ved Nemmere skal man indlevere sine varer 3 uger før ens periode starter.
                                    </div>
                                    <div class="text-sm mt-1">
                                        Du skal senest indlevere i mellem
                                        <span
                                            class="font-black text-black underline decoration-2 underline-offset-2 decoration-topolis-purple-600">
                                            {{ selectedWeeks[0].start_date_pretty
                                            }} - {{ selectedWeeks[0].end_date_pretty }}
                                        </span>
                                        <span class="ml-1 font-medium">(Vær OBS på vores åbningsdage)</span>
                                    </div>
                                </div>
                                <div v-if="chosenConcept === 'easiest'"
                                     class="px-3 py-1.5 rounded-2xl border border-blue-900 border-dashed mt-1">
                                    <div class="font-black text-sm">
                                        Ved Nemmest afhenter Minitopolis dine varer senest 3 uger før ens periode
                                        starter. Minitopolis kontakter dig pr. mail i forhold til at aftale tidspunkt
                                        for afhentning.
                                    </div>
                                    <div class="text-sm mt-1">
                                        Du skal senest aflevere i mellem
                                        <span
                                            class="font-black text-black underline decoration-2 underline-offset-2 decoration-topolis-blue-700">
                                            {{ selectedWeeks[0].start_date_pretty
                                            }} - {{ selectedWeeks[0].end_date_pretty }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h5 class="app-h5 flex flex-col">
                                <span class="font-normal">
                                    <span>Periode start: </span>
                                    <span class="font-normal">
                                        <span class="font-bold">uge {{ startWeek.week_number }}</span> (slut: <span
                                        class="font-bold">uge {{ endWeek.week_number }}</span
                                    >)</span
                                    >
                                </span>
                                <span class="font-normal text-sm">Fra {{ startWeek.start_date_pretty
                                    }} - {{ endWeek.end_date_pretty }}</span>
                            </h5>
                            <h5 class="app-h5 flex flex-col">
                                <span>
                                    <span class="font-normal">Afhentning: </span>
                                    <span class="font-normal">
                                        <span
                                            class="font-bold">uge
                                            {{ chosenConcept === "easy" ? selectedWeeks[9].week_number : selectedWeeks[11].week_number
                                            }}</span>
                                    </span>
                                </span>
                            </h5>
                        </div>
                    </div>
                </div>
                <!--Price-->
                <div class="pt-2">
                    <div v-if="chosenConcept === 'easy'" class="flex flex-col space-y-4">
                        <div v-if="chosenPeriod.discount_percentage" class="space-y-1">
                            <div class="flex justify-between">
                                <p class="font-bold text-topolis-blue-300">Original pris</p>
                                <p class="font-bold text-topolis-blue-300">{{ formatPrice(chosenPeriod.original_price)
                                    }}</p>
                            </div>
                            <div class="flex justify-between">
                                <p class="font-bold text-topolis-blue-300">Kampagne tilbud</p>
                                <p class="font-bold text-topolis-blue-300">{{ chosenPeriod.discount_percentage }}%</p>
                            </div>
                        </div>
                        <div class="flex justify-between">
                            <p class="font-bold text-topolis-blue-700">Booking pris</p>
                            <p class="font-bold text-topolis-blue-700">{{ formatPriceIfDiscount(chosenPeriod.price)
                                }}</p>
                        </div>
                    </div>
                    <div v-else-if="chosenConcept === 'easier'" class="flex items-center justify-between">
                        <p class="font-bold text-topolis-blue-700">Booking pris</p>
                        <p class="font-bold text-topolis-blue-700">{{ formatPrice(chosenPeriod.price) }}</p>
                    </div>
                    <div v-else-if="chosenConcept === 'easiest'" class="flex flex-col justify-between space-y-2">
                        <div class="flex items-center justify-between">
                            <p class="font-bold text-topolis-blue-300">Booking pris</p>
                            <p class="font-bold text-topolis-blue-300">{{ formatPrice(chosenPeriod.price) }}</p>
                        </div>
                        <div class="flex items-center justify-between">
                            <p class="font-bold text-topolis-blue-300">Afhenting</p>
                            <p class="font-bold text-topolis-blue-300">79 kr.</p>
                            <!--                            <p class="font-bold text-topolis-blue-300">{{ formatPrice(chosenPeriod.price) }}</p>-->
                        </div>
                    </div>
                </div>
            </div>
            <!--Personal information-->
            <div class="space-y-4 mt-8 p-4 sm:p-6 bg-white/[0.4] rounded-2xl shadow-md">
                <div class="flex items-center justify-between">
                    <span class="app-h3">Personlig information</span>
                    <button type="button" @click="goToRegister" class="a-link">Rediger</button>
                </div>
                <div class="space-y-2">
                    <div class="flex items-center">
                        <h5 class="app-h5">
                            <span class="font-normal">{{ authenticatedUser.name }}</span>
                        </h5>
                    </div>
                    <div class="flex items-center">
                        <h5 class="app-h5">
                            <span class="font-normal">{{ authenticatedUser.address }}</span>
                        </h5>
                    </div>
                    <div class="flex items-center">
                        <h5 class="app-h5">
                            <span class="font-normal">{{ authenticatedUser.zip }}, {{ authenticatedUser.city }}</span>
                        </h5>
                    </div>
                    <div v-if="authenticatedUser.phone" class="flex items-center">
                        <h5 class="app-h5">
                            <span class="font-normal">Telefonnr.:</span>
                            {{ authenticatedUser.phone }}
                        </h5>
                    </div>
                    <div class="flex items-center">
                        <h5 class="app-h5">
                            <span class="font-normal">Email:</span>
                            {{ authenticatedUser.email }}
                        </h5>
                    </div>
                    <div v-if="note" class="flex flex-col space-y-1">
                        <h5 class="app-h5">
                            <span>Note:</span>
                        </h5>
                        <p class="font-bold">
                            {{ note }}
                        </p>
                    </div>
                </div>
            </div>
            <!--Total price-->
            <div class="space-y-2 mt-8">
                <div class="flex items-center justify-between gap-4">
                    <h4 class="app-h4">Samlet pris inkl. moms</h4>
                    <span class="app-h2">{{ formatPriceIfDiscount(totalPrice) }}</span>
                </div>
                <div class="flex flex-col space-y-2">
                    <div class="flex items-center justify-between">
                        <span>Moms udgør</span>
                        <span>{{ formatPriceVAT(totalPrice) }}</span>
                    </div>
                    <div class="flex items-center justify-between">
                        <span>Subtotal ekskl. moms</span>
                        <span>{{ formatPriceNoVAT(totalPrice) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col space-y-8">
            <hr />
            <div class="flex items-center justify-between pb-48 sm:pb-28">
                <router-link to="/kalender" class="flex items-center hover:no-underline">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4" viewBox="0 0 24 24">
                        <g transform="translate(-684 -188)">
                            <path d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="1.5" />
                            <path d="M16.83,0H0" transform="translate(687.67 200)" fill="none" stroke="currentColor"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        </g>
                    </svg>
                    <span class="ml-1 text-xs xxs:text-base">Tilbage</span>
                </router-link>
                <bouncing-button data-cy="btn-booking-continue-stripe" type="button" :bouncing="chosenConcept !== null"
                                 :disabled="chosenConcept === null || disabled" @click.native="startCheckout">Gå til
                    betaling
                </bouncing-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import BouncingButton from "../Common/BouncingButton.vue";
    import Profile from "./Common/Profile.vue";

    export default {
        name: "ReviewStep",
        components: { BouncingButton, Profile },
        data() {
            return {
                bouncing: false,
                disabled: false,
                discountPrice: null
            };
        },
        computed: {
            ...mapGetters(["chosenConcept", "periods", "chosenPeriod", "authenticatedUser", "bookingUuid", "startDate", "endDate", "startWeek", "endWeek", "selectedWeeks", "formattedStartDate", "formattedEndDate", "note", "conceptPrices"]),
            totalPrice: function() {
                if (this.chosenPeriod.concept === "easiest") {
                    return this.chosenPeriod.price + this.$store.state.pickUpPrice;
                } else {
                    return this.chosenPeriod.price;
                }
            }
        },
        methods: {
            goToRegister() {
                this.$store.commit("setParameters", {
                    showPassword: false,
                    registrationStep: false,
                    fullRegistration: true,
                    isEditMode: true
                });
                this.$router.push("/registrer");
            },
            formatPrice(period) {
                return new Intl.NumberFormat("da-DK", {
                    style: "currency",
                    currency: "DKK",
                    maximumSignificantDigits: 3
                }).format(period / 100);
            },
            formatPriceIfDiscount(period) {
                if (this.chosenPeriod.discount_percentage) {
                    this.discountPrice = (period * this.chosenPeriod.discount_percentage) / 100;
                    const calculatedDiscountedPrice = (period - this.discountPrice) / 100;
                    // rounds to floating point number
                    return new Intl.NumberFormat("da-DK", {
                        style: "currency",
                        currency: "DKK",
                        maximumSignificantDigits: 3
                    }).format(Math.floor(calculatedDiscountedPrice));
                } else {
                    return new Intl.NumberFormat("da-DK", {
                        style: "currency",
                        currency: "DKK",
                        maximumSignificantDigits: 3
                    }).format(period / 100);
                }
            },
            formatPriceVAT(period) {
                if (this.chosenPeriod.discount_percentage) {
                    this.discountPrice = (period * this.chosenPeriod.discount_percentage) / 100;
                    // rounds to floating point number
                    const price = Math.round((period - this.discountPrice) / 100);
                    const noVat = price - price / 1.25;
                    return new Intl.NumberFormat("da-DK", {
                        style: "currency",
                        currency: "DKK",
                        maximumSignificantDigits: 4
                    }).format(noVat);
                } else {
                    const price = period / 100;
                    const noVat = price - price / 1.25;
                    return new Intl.NumberFormat("da-DK", {
                        style: "currency",
                        currency: "DKK",
                        maximumSignificantDigits: 4
                    }).format(noVat);
                }
            },
            formatPriceNoVAT(period) {
                if (this.chosenPeriod.discount_percentage) {
                    this.discountPrice = (period * this.chosenPeriod.discount_percentage) / 100;
                    // rounds to floating point number
                    const price = Math.round((period - this.discountPrice) / 100);
                    const vat = price / 1.25;
                    return new Intl.NumberFormat("da-DK", {
                        style: "currency",
                        currency: "DKK",
                        maximumSignificantDigits: 4
                    }).format(vat);
                } else {
                    const price = period / 100;
                    const vat = price / 1.25;
                    return new Intl.NumberFormat("da-DK", {
                        style: "currency",
                        currency: "DKK",
                        maximumSignificantDigits: 4
                    }).format(vat);
                }
            },
            startCheckout() {
                this.$store.dispatch("startBooking").then(() => {
                    this.loading = true;
                    this.disabled = true;
                    axios
                        .post("start-checkout", {
                            booking_uuid: this.bookingUuid
                        })
                        .then((response) => {
                            window.location.href = response.data.url;
                        })
                        .catch((error) => {
                            this.disabled = false;
                            console.log(error);
                        });
                });
            },
            endBooking() {
                this.$router.push({ name: "success-step" });
            },
            gotoPrev() {
                this.$router.back();
            }
        }
    };
</script>
