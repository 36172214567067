<template>
    <div :class="{ '-mx-4' : dashboard }">
        <apexchart type="bar" :height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    export default {
        name: "ColumnGraph",
        props: {
            chartData: Object,
            xAxis: String,
            seriesName: String,
            locale: String,
            dashboard: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("componentLoaded"));
                }, 300);
            });
        },
        data() {
            return {
                series: [
                    {
                        name: this.$translate(this.seriesName),
                        data: this.prepareSeriesData()
                    }
                ],
                chartOptions: {
                    colors: ["#9D7369", "#604833"], // Define an array of colors for the gradient effect
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "20%",
                            borderRadius: 4,
                            dataLabels: {
                                position: "top" // top, center, bottom
                            }
                        }
                    },
                    options: {
                        chart: {
                            width: "100%",
                            toolbar: {
                                show: false
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: function(val) {
                            if (val === 0) {
                                return ""; // Return an empty string for 0 values
                            }
                            // Convert the value from its current form into kr format (e.g., 108325 to 1083.25 kr)
                            const formattedValue = (val / 100).toFixed(2); // Dividing by 100 and rounding to 2 decimal places
                            return formattedValue + " kr";
                        },
                        offsetY: -20,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"]
                        }
                    },
                    xaxis: {
                        categories: this.getXAxisCategories()
                    },
                    yaxis: {
                        min: 0, // Set the minimum value to 0
                        max: Math.ceil((Math.max(...this.chartData.map(item => item.sales)) + 100) / 100) * 100, // Calculate the max value for the y-axis labels
                        labels: {
                            formatter: (value) => {
                                return value / 100 + " kr"; // Display without decimal places
                            }
                        },
                        maxTicks: 5, // Limit the number of y-axis labels to 5
                        forceNiceScale: true // Force a nice scale with the defined maxTicks
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "light",
                            type: "vertical",
                            shadeIntensity: 0.5,
                            gradientToColors: ["#604833", "#9D7369"],
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 100]
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: "rgba(226,232,240,0.56)",
                        strokeDashArray: 0,
                        xaxis: {
                            lines: {
                                show: false
                            }
                        },
                        yaxis: {
                            lines: {
                                show: true
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                        shared: false,
                        intersect: false,
                        style: {
                            fontSize: "14px",
                            fontFamily: "Arial, sans-serif"
                        },
                        onDatasetHover: {
                            highlightDataSeries: false
                        },
                        x: {
                            show: false // Hide x-axis tooltip
                        },
                        y: {
                            formatter: function(val) {
                                return new Intl.NumberFormat("da-DK", {
                                    style: "currency",
                                    currency: "DKK"
                                }).format(val / 100);
                            }.bind(this) // bind `this` context
                        },
                        marker: {
                            show: false
                        }
                    }
                }
            };
        },
        methods: {
            prepareSeriesData() {
                return this.chartData.map(item => {
                    if (this.xAxis === "weekly") {
                        // For weekly view, use the date directly
                        return {
                            x: item.date, // x-axis value (date)
                            y: item.sales // y-axis value (sales)
                        };
                    } else if (this.xAxis === "monthly") {
                        // For monthly view, use the month as x-axis value
                        return {
                            x: item.month, // x-axis value (month)
                            y: item.sales // y-axis value (sales)
                        };
                    } else if (this.xAxis === "hourly") {
                        return {
                            x: item.hour, // x-axis value (month)
                            y: item.sales // y-axis value (sales)
                        };
                    }
                });
            },
            getXAxisCategories() {
                if (this.xAxis === "weekly") {
                    return this.chartData.map(item => {
                        const date = new Date(item.date);
                        // Format the date for weekly view
                        const formattedDate = date.toLocaleString(this.locale, { day: "2-digit", month: "short" });
                        const day = date.toLocaleString(this.locale, { weekday: "short" });
                        return `${day} - ${formattedDate}`;
                    });
                } else if (this.xAxis === "monthly") {
                    return this.chartData.map(item => {
                        const date = new Date(item.month + "-01");
                        const monthName = date.toLocaleString(this.locale, { month: "short" });
                        const year = date.getFullYear();
                        // Format the date for monthly view
                        return `${monthName} - ${year}`;
                    });
                } else if (this.xAxis === "hourly") {
                    return this.chartData.map(item => {
                        const hour = new Date(item.hour).getHours().toString().padStart(2, "0");
                        return `${hour}`;
                    });
                }
            }
        }
    };
</script>

<style>
    /* Hide the ApexCharts toolbar */
    .apexcharts-toolbar {
        display: none !important;
    }

    .apexcharts-tooltip-text-y-label {
        display: none !important;
    }
</style>