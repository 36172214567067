<template>
    <form class="flex flex-col items-center justify-center gap-4 w-full" method="POST"
          @submit.prevent="create">
        <div v-if="generalError" class="text-red-500">
            {{ generalError }}
        </div>
        <div class="flex flex-col sm:flex-row sm:items-center gap-4 w-full sm:w-2/3 order-5 sm:order-1">
            <div class="p-1 w-full">
                <label class="form-label" for="agent_name">Navn på tøjagent</label>
                <input class="form-input" v-model="form.agentName" id="agent_name" type="text" name="agent_name"
                       placeholder="..."
                       value="">
                <div v-if="errors && errors.agent_name" class="mt-1 text-xs text-red-500">
                    <p>{{ errors.agent_name[0] }}</p>
                </div>
            </div>
            <div class="p-1 w-full">
                <label class="form-label" for="agent_name">Prisleje</label>
                <min-max-slider class="mx-6 mt-4 mb-8 sm:m-4" :value="[form.priceFrom, form.priceTo]"
                                @changed-price="handlePriceChange"></min-max-slider>
            </div>
        </div>
        <div class="flex flex-col relative p-1 w-full sm:w-2/3 order-1 sm:order-2">
            <label class="form-label" for="category_id">Kategori(er)</label>
            <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="category"
                                      :placeholder="$translate('Search categories')"
                                      :items="categories" @set-category="setFilteredCategory"
                                      @remove-category="deleteFilteredCategory"
                                      @clear-value="clearAllFilteredCategories">
            </minitopolis-autocomplete>
            <div v-if="errors && errors.autocompleteCategories" class="mt-1 text-xs text-red-500">
                <p>{{ errors.autocompleteCategories[0] }}</p>
            </div>

        </div>
        <div class="flex flex-col relative p-1 w-full sm:w-2/3 order-2 sm:order-3">
            <label class="form-label" for="brand_id">Mærke(r)</label>
            <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="brand"
                                      :placeholder="$translate('Search brands')"
                                      :items="brands"
                                      @set-brand="setFilteredBrand" @remove-brand="deleteFilteredBrand"
                                      @clear-value="clearAllFilteredBrands">
            </minitopolis-autocomplete>
            <div v-if="errors && errors.autocompleteBrands" class="mt-1 text-xs text-red-500">
                <p>{{ errors.autocompleteBrands[0] }}</p>
            </div>

        </div>
        <div class="flex flex-col relative p-1 w-full sm:w-2/3 order-3 sm:order-4">
            <label class="form-label" for="size_id">Størrelse(r)</label>
            <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="size"
                                      :placeholder="$translate('Search sizes')"
                                      :items="sizes" @set-size="setFilteredSize" @remove-size="deleteFilteredSize"
                                      @clear-value="clearAllFilteredSizes">
            </minitopolis-autocomplete>
            <div v-if="errors && errors.autocompleteSizes" class="mt-1 text-xs text-red-500">
                <p>{{ errors.autocompleteSizes[0] }}</p>
            </div>

        </div>
        <div class="flex flex-col relative p-1 w-full sm:w-2/3 order-4 sm:order-5">
            <label class="form-label" for="color_id">Farve(r)</label>
            <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="colors"
                                      :placeholder="$translate('Search colors')"
                                      :items="colors" @set-color="setFilteredColor"
                                      @remove-color="deleteFilteredColor" @clear-value="clearAllFilteredColors">
            </minitopolis-autocomplete>
            <div v-if="errors && errors.autocompleteColors" class="mt-1 text-xs text-red-500">
                <p>{{ errors.autocompleteColors[0] }}</p>
            </div>

        </div>
        <div class="flex flex-col sm:flex-row sm:items-center gap-4 w-full sm:w-2/3 order-5 sm:order-6">
            <div class="p-1 w-full">
                <label class="form-label" for="agent_user_name">{{ $translate("Your full name") }}</label>
                <input class="form-input" v-model="form.customerName" id="agent_user_name" type="text"
                       name="agent_user_name"
                       placeholder="Helle Hansen"
                       value="">
                <div v-if="errors && errors.agent_user_name" class="mt-1 text-xs text-red-500">
                    <p>{{ errors.agent_user_name[0] }}</p>
                </div>
            </div>
            <div class="p-1 w-full">
                <label class="form-label" for="agent_email">{{ $translate("Your email") }}</label>
                <input class="form-input" v-model="form.customerEmail" id="agent_email" type="email" name="agent_email"
                       placeholder="helle.hansen@gmail.com"
                       value=""
                       autocomplete="off">
                <div v-if="errors && errors.agent_email" class="mt-1 text-xs text-red-500">
                    <p>{{ errors.agent_email[0] }}</p>
                </div>
            </div>
        </div>
        <div class="flex items-start justify-center w-full sm:w-2/3 mx-auto p-1 order-6 sm:order-7">
            <div class="flex items-center h-5">
                <input id="agent_accepts_privacy_terms" v-model="form.accepts_privacy_terms"
                       name="agent_accepts_privacy_terms" type="checkbox" class="form-input-checkbox"
                       value="" />
            </div>
            <div class="ml-2 text-sm">
                <label for="agent_accepts_privacy_terms" class="font-medium"
                >For at kunne oprette en tøjagent, så skal du godkende vores
                    <a href="https://minitopolis.dk/persondatapolitik#/" class="font-black"
                       target="_blank">persondata
                        politik</a>
                </label>
            </div>
        </div>
        <loading-button type="submit" :loading="loading"
                        :disabled="!form.customerName || !form.customerEmail || !form.agentName || !form.accepts_privacy_terms || (!form.autocompleteCategories.length &&
                    !form.autocompleteBrands.length &&
                    !form.autocompleteSizes.length &&
                    !form.autocompleteColors.length)"
                        class="button button-purple order-6 sm:order-7">
            {{ $translate("Create fashion alert") }}
        </loading-button>
    </form>
</template>

<script>
    import MinMaxSlider from "./MinMaxSlider.vue";
    import MTAutocomplete from "../Pages/Common/MTAutocomplete.vue";
    import LoadingButton from "../Pages/Common/LoadingButton.vue";

    export default {
        components: { MinMaxSlider, MTAutocomplete, LoadingButton },
        props: {
            categories: {},
            brands: {},
            sizes: {},
            colors: {}
        },
        data() {
            return {
                loading: false,
                errors: null,
                generalError: null,
                isFormValid: false,
                isEditMode: false,
                form: {
                    autocompleteCategories: [],
                    autocompleteBrands: [],
                    autocompleteSizes: [],
                    autocompleteColors: [],
                    priceFrom: 0,
                    priceTo: 1500,
                    agentName: null,
                    customerName: null,
                    customerEmail: null,
                    accepts_privacy_terms: false
                }
            };
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("componentLoaded"));
                }, 300);
            });
        },
        methods: {
            handlePriceChange(newPriceRange) {
                this.form.priceFrom = newPriceRange[0];
                this.form.priceTo = newPriceRange[1];
            },
            setFilteredCategory(payload) {
                this.form.autocompleteCategories.push(payload.value);
            },
            setFilteredBrand(payload) {
                this.form.autocompleteBrands.push(payload.value);
            },
            setFilteredSize(payload) {
                this.form.autocompleteSizes.push(payload.value);
            },
            setFilteredColor(payload) {
                this.form.autocompleteColors.push(payload.value);
            },
            deleteFilteredCategory(payload) {
                this.form.autocompleteCategories = this.form.autocompleteCategories.filter(
                    item => item !== payload.value
                );
            },
            deleteFilteredBrand(payload) {
                this.form.autocompleteBrands = this.form.autocompleteBrands.filter(
                    item => item !== payload.value
                );
            },
            deleteFilteredSize(payload) {
                this.form.autocompleteSizes = this.form.autocompleteSizes.filter(
                    item => item !== payload.value
                );
            },
            deleteFilteredColor(payload) {
                this.form.autocompleteColors = this.form.autocompleteColors.filter(
                    item => item !== payload.value
                );
            },
            clearAllFilteredCategories() {
                this.form.autocompleteCategories = [];
            },
            clearAllFilteredBrands() {
                this.form.autocompleteBrands = [];
            },
            clearAllFilteredSizes() {
                this.form.autocompleteSizes = [];
            },
            clearAllFilteredColors() {
                this.form.autocompleteColors = [];
            },
            create() {
                this.loading = true;
                axios.post(`/shop/test/toej-agent/opret`, this.form)
                    .then((response) => {
                        window.location.href = response.data.url;
                    })
                    .catch((error) => {
                        this.loading = false;
                        if (error.response.status === 400) {
                            // show error notification
                            this.generalError = error.response.data.error;
                        }

                        this.errors = error.response.data.errors;
                    });
            }
        }
    };
</script>