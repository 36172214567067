<template>
    <loading-spinner v-if="loading" :loading="loading" />
    <div class="flex items-start justify-center gap-16">
        <div class="flex flex-col" v-if="!newProducts">
            <h5 class="font-bold mb-2">Kategorier</h5>
            <div v-for="sideCategory in categories" :key="sideCategory.id">
                <a :href="categoryLink(sideCategory)"
                   class="text-sm font-normal text-black hover:text-black">{{ sideCategory.name }}</a>
            </div>
        </div>
        <div v-if="!loading"
             class="flex items-center justify-center relative flex-wrap gap-12 lg:gap-16 max-w-8xl mx-auto w-full">
            <product-filters v-if="!newProducts" :categories="categories" :brands="brands" :sizes="sizes"
                             :colors="colors"
                             :patterns="patterns" @filters-changed="applyFilters"></product-filters>
            <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-x-2 gap-y-4 xs:gap-4 sm:gap-y-6 lg:gap-y-8">
                <div v-for="product in products" :key="product.id">
                    <product-card v-for="variant in product.variants" :key="variant.id" :product="product"
                                  :variant="variant"
                                  :new-products="newProducts" />
                </div>

            </div>
            <div class="flex">
                <loading-button v-if="moreProducts" type="button" :loading="loadingMore"
                                :disabled="!moreProducts"
                                class="button button-purple" @click="loadMore">
                    Load more
                </loading-button>
                <p v-else-if="products.length > 30 && !moreProducts" class="italic font-medium">No more products to
                    load</p>
            </div>
        </div>
    </div>

</template>

<script>
    import ProductCard from "./ProductCard.vue";
    import LoadingButton from "../Pages/Common/LoadingButton.vue";
    import LoadingSpinner from "../Pages/Common/LoadingSpinner.vue";
    import ProductFilters from "../Components/ProductFilters.vue";

    export default {
        components: {
            ProductCard, LoadingButton, LoadingSpinner, ProductFilters
        },
        props: {
            slugProducts: {
                type: Boolean,
                default: false,
                required: false
            },
            newProducts: {
                type: Boolean,
                default: false,
                required: false
            },
            route: {
                type: String,
                required: true
            },
            slug: {},
            productPerPage: {},
            categories: {},
            brands: {},
            sizes: {},
            colors: {},
            patterns: {},
            isParentCategory: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        data() {
            return {
                products: [],
                moreProducts: true,
                nextPage: 1,
                perPage: this.productPerPage,
                loading: false,
                loadingMore: false
            };
        },
        methods: {
            categoryLink(category) {
                console.log(category);
                return `/shop/test/kategorier/${category.parent.slug}/${category.slug}`;
            },
            loadMore() {
                this.loadingMore = true;
                const params = {
                    ...this.filters,
                    page: this.nextPage,
                    perPage: this.perPage
                };
                if (this.slugProducts) {
                    params.slug = this.slug;
                    params.parentCategory = this.isParentCategory;
                }
                axios.get(this.route, { params })
                    .then(response => {
                        this.products = this.products.concat(response.data.products);
                        this.moreProducts = response.data.has_more; // Change this line
                        this.nextPage++;
                        this.loadingMore = false;
                    });
            },
            applyFilters(newFilters) {
                console.log("filter.", newFilters);
                this.filters = newFilters;
                this.products = [];  // reset products array
                this.nextPage = 1;   // reset page counter
                this.loadMore();     // fetch products
            }
        },
        mounted() {
            this.loadMore();
        }
    };

</script>
