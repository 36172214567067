<template>
    <div class="relative w-full">
        <div class="mx-auto text-left w-full relative">
            <form action="#" method="POST" class="flex flex-col gap-4 w-full"
                  @submit.prevent="subscribe">
                <div class="flex flex-col lg:flex-row w-full gap-2 lg:gap-4">
                    <div class="flex flex-col xs:flex-row items-center w-full gap-2">
                        <div class="lg:min-w-0 lg:flex-1 w-full lg:max-w-[350px]">
                            <label for="subscribe_name" class="sr-only">Fornavn</label>
                            <input id="subscribe_name" v-model="form.name" name="subscribe_name"
                                   autocomplete="given-name"
                                   type="text" class="newsletter-input form-large-input"
                                   placeholder="Skriv dit fornavn her" required />
                        </div>
                        <div class="lg:min-w-0 lg:flex-1 w-full lg:max-w-[650px]">
                            <label for="subscribe_email" class="sr-only">Din email addresse</label>
                            <input id="subscribe_email" v-model="form.email" name="subscribe_email" autocomplete="email"
                                   type="email" class="newsletter-input form-large-input"
                                   placeholder="Skriv din email her" required />
                        </div>
                    </div>

                    <div
                        class="lg:hidden flex items-center xs:ml-2 my-2 text-gray-100 justify-center xs:justify-start max-w-2xl">
                        <div class="flex items-center h-5">
                            <input id="accepts_privacy_terms" v-model="form.accepts_privacy_terms"
                                   name="accepts_privacy_terms" type="checkbox" class="form-input-checkbox" value="" />
                        </div>
                        <div class="ml-2 text-sm">
                            <label for="accepts_privacy_terms" class="font-medium text-gray-100"
                            >Godkend
                                <a href="https://minitopolis.dk/persondatapolitik#/" class="font-black text-gray-100"
                                   target="_blank">persondata
                                    politik</a>
                            </label>
                        </div>
                    </div>

                    <loading-button class="button button-purple"
                                    :loading="loading"
                                    :disabled="loading" type="submit" @click.native="subscribe">
                        <span>Tilmeld</span>
                    </loading-button>
                </div>
                <div class="flex flex-col gap-4">
                    <div
                        class="hidden lg:flex items-center xs:ml-2 text-gray-100 justify-center xs:justify-start max-w-2xl">
                        <div class="flex items-center h-5">
                            <input id="accepts_privacy_terms" v-model="form.accepts_privacy_terms"
                                   name="accepts_privacy_terms" type="checkbox" class="form-input-checkbox" value="" />
                        </div>
                        <div class="ml-2 text-sm">
                            <label for="accepts_privacy_terms" class="font-medium text-gray-100"
                            >Godkend
                                <a href="https://minitopolis.dk/persondatapolitik#/" class="font-black text-gray-100"
                                   target="_blank">persondata
                                    politik</a>
                            </label>
                        </div>
                    </div>
                    <div class="text-xs font-medium font-black text-gray-100 text-center xs:text-left">
                        Du kan
                        <a href="https://minitopolis.test/nyhedsbrev#/" class="font-black text-gray-100"
                           target="_blank">afmelde dig</a>
                        nyhedsbrevet når som helst.
                    </div>

                </div>
            </form>
            <div v-if="message" class="flex items-center justify-center xs:justify-start mt-4 w-full space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 h-6 text-green-200"
                     viewBox="0 0 24 24">
                    <g transform="translate(-748 -188)">
                        <path d="M10,20A10,10,0,1,0,0,10,10.029,10.029,0,0,0,10,20Z" transform="translate(750 190)"
                              fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" />
                        <path d="M0,2.83,2.83,5.66,8.5,0" transform="translate(755.75 197.17)" fill="none"
                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    </g>
                </svg>
                <p class="font-medium text-sm text-green-200 w-fit hyphens-auto">{{ $translate(message) }}</p>
            </div>
            <div v-if="error && error.subscribe_name"
                 class="flex items-center justify-center xs:justify-start mt-4 w-full space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 h-6 text-red-300"
                     viewBox="0 0 24 24">
                    <g transform="translate(-428 -252)">
                        <path d="M0,0V5" transform="translate(440 261)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <path
                            d="M10,18.817H3.939c-3.47,0-4.92-2.48-3.24-5.51l3.12-5.62,2.94-5.28c1.78-3.21,4.7-3.21,6.48,0l2.94,5.29,3.12,5.62c1.68,3.03.22,5.51-3.24,5.51H10Z"
                            transform="translate(430.001 254.592)"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                        <path d="M0,0H.009" transform="translate(439.995 269)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </g>
                </svg>
                <p class="font-medium text-sm text-red-300 w-fit hyphens-auto">{{ $translate(error.subscribe_name[0])
                    }}</p>
            </div>
            <div v-if="error && error.subscribe_email"
                 class="flex items-center justify-center xs:justify-start mt-4 w-full space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 h-6 text-red-300"
                     viewBox="0 0 24 24">
                    <g transform="translate(-428 -252)">
                        <path d="M0,0V5" transform="translate(440 261)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <path
                            d="M10,18.817H3.939c-3.47,0-4.92-2.48-3.24-5.51l3.12-5.62,2.94-5.28c1.78-3.21,4.7-3.21,6.48,0l2.94,5.29,3.12,5.62c1.68,3.03.22,5.51-3.24,5.51H10Z"
                            transform="translate(430.001 254.592)"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                        <path d="M0,0H.009" transform="translate(439.995 269)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </g>
                </svg>
                <p class="font-medium text-sm text-red-300 w-fit hyphens-auto">{{ $translate(error.subscribe_email[0])
                    }}</p>
            </div>
            <div v-if="error && error.accepts_privacy_terms"
                 class="flex items-center justify-center xs:justify-start mt-4 w-full space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 h-6 text-red-300"
                     viewBox="0 0 24 24">
                    <g transform="translate(-428 -252)">
                        <path d="M0,0V5" transform="translate(440 261)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <path
                            d="M10,18.817H3.939c-3.47,0-4.92-2.48-3.24-5.51l3.12-5.62,2.94-5.28c1.78-3.21,4.7-3.21,6.48,0l2.94,5.29,3.12,5.62c1.68,3.03.22,5.51-3.24,5.51H10Z"
                            transform="translate(430.001 254.592)"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                        <path d="M0,0H.009" transform="translate(439.995 269)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </g>
                </svg>
                <p class="font-medium text-sm text-red-300 w-fit hyphens-auto">
                    {{ $translate(error.accepts_privacy_terms[0])
                    }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingButton from "./LoadingButton.vue";

    export default {
        name: "SubscribeNewsletter",
        components: { LoadingButton },
        data() {
            return {
                form: {
                    name: null,
                    email: null,
                    accepts_privacy_terms: false
                },
                message: null,
                error: null,
                loading: false
            };
        },
        methods: {
            subscribe() {
                this.loading = true;
                this.message = null;
                this.error = null;
                axios
                    .post("/subscribe", {
                        subscribe_name: this.form.name,
                        subscribe_email: this.form.email,
                        accepts_privacy_terms: this.form.accepts_privacy_terms
                    })

                    .then((response) => {
                        this.loading = false;
                        this.message = response.data.message;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.error = error.response.data.errors;
                    });
            }
        }
    };
</script>
