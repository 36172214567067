<template>
    <div class="rounded-t-2xl">
        <vue-cal active-view="week" :disable-views="['years']"
                 :locale="locale"
                 style="height: 600px" :events="events" events-count-on-month-view :time-from="7 * 60"
                 :time-step="30">
            <template #event="{ event, view }">
                <div class="event-content h-full rounded-lg"
                     :style="{ 'background-color': '#' + event.backgroundColor, 'color':  '#' + event.color}">
                    <div class="text-sm font-medium">
                        <div class="mb-1 truncate">{{ event.title }}</div>
                        <a :href="bookingShowUrl(event.booking)"
                           class="flex flex-col items-center text-xxs leading-3 text-gray-300 w-fit mx-auto hover:text-white"
                           v-if="event.booking">
                            <div class="mx-auto">
                                <span class="leading-3 mr-0.5">Butiksnr.:</span>
                                <span class="font-bold">
                                    {{ event.booking.store_id }}
                                </span>
                            </div>
                            <div>
                                {{ event.booking.customer.name }}
                            </div>
                            <div>
                                {{ event.booking.customer.zip }} {{ event.booking.customer.city }}
                            </div>
                        </a>
                        <a :href="eventShowUrl(event.event)"
                           class="flex flex-col items-center text-xxs text-gray-200 leading-3 text-gray-300 w-fit mx-auto hover:text-white"
                           v-if="event.event">
                            <div class="font-font">
                                {{ event.event.name }}
                            </div>
                            <div class="mb-1">
                                {{ $translate("by") }}
                                <div v-if="event.event.event_planner">
                                    <span class="font-medium italic">{{ event.event.event_planner.name }}</span>
                                </div>
                                <div v-else>Minitopolis</div>
                            </div>
                            <div>
                                {{ event.event.teaser }}
                            </div>
                        </a>
                    </div>
                    <div class="divider-hori mt-1 mx-auto"></div>

                    <small class="font-bold text-xxs">
                        <span>{{ event.start.formatTime() }}</span>
                        <span>-</span>
                        <span>{{ event.end.formatTime() }}</span>
                    </small>
                    <div v-if="event.icon === 'star_calendar'">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             class="flex items-center justify-center flex-shrink-0 min-w-0 w-6 mx-auto"
                             :style="{ 'color':  '#' + event.color}"
                             viewBox="0 0 24 24">
                            <g transform="translate(-300 -252)">
                                <path
                                    d="M.75,4.5A.755.755,0,0,1,0,3.75v-3A.755.755,0,0,1,.75,0,.755.755,0,0,1,1.5.75v3A.755.755,0,0,1,.75,4.5Z"
                                    transform="translate(307.25 253.25)" fill="currentColor" />
                                <path
                                    d="M.75,4.5A.755.755,0,0,1,0,3.75v-3A.755.755,0,0,1,.75,0,.755.755,0,0,1,1.5.75v3A.755.755,0,0,1,.75,4.5Z"
                                    transform="translate(315.25 253.25)" fill="currentColor" />
                                <path
                                    d="M12.75,20h-6C1.13,20,0,17.35,0,13.07V6.9C0,2.16,1.6.23,5.71,0h8.08C17.9.23,19.5,2.16,19.5,6.9v6.17C19.5,17.35,18.37,20,12.75,20Zm-7-18.5C2.95,1.66,1.5,2.54,1.5,6.9v6.17c0,3.83.73,5.43,5.25,5.43h6c4.52,0,5.25-1.6,5.25-5.43V6.9c0-4.35-1.44-5.24-4.27-5.4Z"
                                    transform="translate(302.25 254.75)" fill="currentColor" />
                                <path
                                    d="M18.25,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h17.5A.755.755,0,0,1,19,.75.755.755,0,0,1,18.25,1.5Z"
                                    transform="translate(302.5 268.85)" fill="currentColor" />
                                <path
                                    d="M4.691.574,5.4,1.986a.881.881,0,0,0,.566.421l1.277.21c.816.135,1.006.726.421,1.317l-1,1a.864.864,0,0,0-.205.726l.285,1.232c.225.971-.3,1.352-1.151.841L4.4,7.018a.85.85,0,0,0-.791,0l-1.2.711c-.856.506-1.377.13-1.151-.841l.285-1.232a.874.874,0,0,0-.205-.726l-1-1c-.586-.586-.4-1.176.421-1.317l1.277-.21A.865.865,0,0,0,2.6,1.986L3.309.574C3.684-.191,4.305-.191,4.691.574Z"
                                    transform="translate(308 259.022)" fill="currentColor" />
                            </g>
                        </svg>
                    </div>
                    <div v-if="event.icon === 'pick_up_truck'">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             class="flex items-center justify-center flex-shrink-0 min-w-0 w-6 mx-auto"
                             :style="{ 'color':  '#' + event.color}" viewBox="0 0 24 24">
                            <g transform="translate(-684 -188)">
                                <path d="M13,0V10a2.006,2.006,0,0,1-2,2H0V4A4,4,0,0,1,4,0Z"
                                      transform="translate(686 190)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path
                                    d="M20,9v3a3,3,0,0,1-3,3H16a2,2,0,0,0-4,0H8a2,2,0,0,0-4,0H3a3,3,0,0,1-3-3V9H11a2.006,2.006,0,0,0,2-2V0h1.84a2.016,2.016,0,0,1,1.74,1.01L18.29,4H17a1,1,0,0,0-1,1V8a1,1,0,0,0,1,1Z"
                                    transform="translate(686 193)" fill="none" stroke="currentColor"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
                                      transform="translate(690 206)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
                                      transform="translate(698 206)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M4,3V5H1A1,1,0,0,1,0,4V1A1,1,0,0,1,1,0H2.29Z"
                                      transform="translate(702 197)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </div>
                </div>

            </template>
        </vue-cal>
    </div>
</template>

<script>
    import VueCal from "vue-cal";
    import "vue-cal/dist/vuecal.css";

    export default {
        components: { VueCal },
        props: {
            events: Object,
            locale: String
        },
        methods: {
            bookingShowUrl(booking) {
                // You can generate the URL for booking details here
                // Assuming booking contains the necessary data, e.g., booking.uuid
                return booking ? `/admin/bookinger/${booking.uuid}` : ""; // Adjust the URL generation logic
            },
            eventShowUrl(event) {
                // You can generate the URL for event details here
                // Assuming event contains the necessary data, e.g., event.uuid
                return event ? `/admin/events/${event.slug}` : ""; // Adjust the URL generation logic
            }
        }
    };
</script>
<style>
    .vuecal, .vuecal__header, .vuecal__menu {
        border-top-left-radius: 1rem; /* 16px */
        border-top-right-radius: 1rem; /* 16px */
    }

    .vuecal__menu, .vuecal__cell-events-count {
        background-color: #2a2a2a;
        color: #FCFCFC;
    }

    .vuecal__menu .vuecal__view-btn {
        font-size: 1rem;
        font-weight: 600;
    }

    .vuecal__title {
        font-size: 14px;
    }

    .weekday-label {
        font-size: 12px;
    }

    .vuecal__no-event {
        font-size: 8px;
        padding-top: 0.5rem;
        margin-left: 0.375rem; /* 6px */
        margin-right: 0.375rem; /* 6px */
    }

    .vuecal__time-cell-label {
        font-size: 12px;
    }

    .vuecal__title-bar {
        background-color: #e5ebf5;
    }

    .vuecal__cell--today, .vuecal__cell--current {
        background-color: rgba(240, 240, 255, 0.4);
    }

    .vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
        background-color: rgba(235, 255, 245, 0.4);
    }

    .vuecal__cell--selected:before {
        border-color: rgba(66, 185, 131, 0.5);
    }

    /* Cells and buttons get highlighted when an event is dragged over it. */
    .vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
    .vuecal__cell-split--highlighted {
        background-color: rgba(195, 255, 225, 0.5);
    }

    .vuecal__arrow.vuecal__arrow--highlighted,
    .vuecal__view-btn.vuecal__view-btn--highlighted {
        background-color: rgba(136, 236, 191, 0.25);
    }


    @media (min-width: 1024px) {
        .weekday-label {
            font-size: 14px;
        }
    }

    @media (min-width: 640px) {
        .vuecal__no-event {
            font-size: 10px;
        }
    }
</style>