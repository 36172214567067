<template>
    <div class="flex w-full xl:w-1/2 p-2 xs:p-6 lg:p-2 xl:p-12">
        <!--        <div class="flex flex-col w-full h-full p-4 rounded-lg bg-white max-w-[600px] shadow mx-auto pointer-events-none select-none">-->
        <div class="flex flex-col w-full h-full p-4 rounded-lg bg-white max-w-[600px] shadow mx-auto">
            <div class="flex flex-col space-y-8">
                <span class="text-base font-bold">Forhåndsvisning</span>
                <div class="pb-4">
                    <span class="text-sm flex items-center gap-1 mb-0.5">
                        <span>Produkt tabel</span>
                        <span v-if="isValid">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 min-w-0 text-green-600"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-748 -188)">
                                    <path
                                        d="M10,0A10,10,0,1,0,20,10,10.016,10.016,0,0,0,10,0Zm4.78,7.7L9.11,13.37a.748.748,0,0,1-1.06,0L5.22,10.54A.75.75,0,1,1,6.28,9.48l2.3,2.3,5.14-5.14A.75.75,0,0,1,14.78,7.7Z"
                                        transform="translate(750 190)"
                                        fill="currentColor"
                                    />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <div class="border border-[#E6E6E6] rounded-2xl h-full" :class="{ 'border-green-600': isValid }">
                        <div class="flex items-center w-full h-full relative">
                            <div
                                class="w-20 h-auto bg-topolis-blue-50 flex justify-center items-center relative mr-2 rounded-l-2xl self-stretch">
                                <div v-if="currentParentCategory">
                                    <img v-if="currentParentCategory.value === 144"
                                         class="flex-shrink-0 min-w-0 w-10 sm:w-12 -mt-2"
                                         src="/assets/categories/equipment.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 351"
                                         class="flex-shrink-0 min-w-0 w-10 sm:w-12 -mt-2"
                                         src="/assets/categories/clothes.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 221"
                                         class="flex-shrink-0 min-w-0 w-10 sm:w-12 -mt-2"
                                         src="/assets/categories/jacket.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 213"
                                         class="flex-shrink-0 min-w-0 w-10 sm:w-12 -mt-2"
                                         src="/assets/categories/toys.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 620"
                                         class="flex-shrink-0 min-w-0 w-7 sm:w-9 -mt-2"
                                         src="/assets/categories/interior.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 621"
                                         class="flex-shrink-0 min-w-0 w-10 sm:w-12 -mt-2"
                                         src="/assets/categories/footwear.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 376"
                                         class="flex-shrink-0 min-w-0 w-7 sm:w-9 -mt-2"
                                         src="/assets/categories/accessories.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 377"
                                         class="flex-shrink-0 min-w-0 w-7 sm:w-9 -mt-2"
                                         src="/assets/categories/books.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 132"
                                         class="flex-shrink-0 min-w-0 w-7 sm:w-9 -mt-1"
                                         src="/assets/categories/other.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 378"
                                         class="flex-shrink-0 min-w-0 w-7 sm:w-9 -mt-1"
                                         src="/assets/categories/unknown.svg" alt="" />
                                    <span
                                        class="absolute bottom-0 left-0 right-0 mx-auto text-[9px] font-bold text-center">{{ currentParentCategory.label
                                        }}</span>
                                    <!--                                    <span v-if="currentParentCategory === 132"-->
                                    <!--                                          class="absolute bottom-0 left-0 right-0 mx-auto text-[9px] font-bold text-center">Andet</span>-->
                                    <!--                                    <span v-if="currentParentCategory === 378"-->
                                    <!--                                          class="absolute bottom-0 left-0 right-0 mx-auto text-[9px] font-bold text-center">Ukendt</span>-->
                                </div>
                                <div v-if="editMode && !currentParentCategory">
                                    <img v-if="product.category_id === 132"
                                         class="flex-shrink-0 min-w-0 w-7 sm:w-9 -mt-1"
                                         src="/assets/categories/other.svg" alt="" />
                                    <span v-if="product.category_id === 132"
                                          class="absolute bottom-0 mx-auto text-[9px] font-bold text-center">Andet</span>
                                    <img v-if="product.category_id === 378"
                                         class="flex-shrink-0 min-w-0 w-7 sm:w-9 -mt-1"
                                         src="/assets/categories/unknown.svg" alt="" />
                                    <span v-if="product.category_id === 378"
                                          class="absolute bottom-0 mx-auto text-[9px] font-bold text-center">Ukendt</span>
                                </div>
                                <span
                                    v-if="!currentParentCategory && product.category_id !== 132 && product.category_id !== 378"
                                    class="absolute top-10 xs:top-6 mx-auto text-[9px] font-semibold text-gray-500 -rotate-12">Overkategori</span>
                            </div>

                            <div class="flex flex-col justify-between w-full pr-2 pl-1 py-1 lg:py-0">
                                <div class="flex flex-col xs:flex-row items-start xs:items-center justify-between">
                                    <p class="text-sm text-left mr-2">
                                        <span v-if="!editMode">
                                            <span v-if="product.category && product.brand"
                                                  class="font-medium">{{ product.brand.label + "-" + product.category.label + "-XXXXXX"
                                                }}</span>
                                            <span v-else-if="product.category && !product.brand && product.title"
                                                  class="font-medium">{{ product.title + "-" + product.category.label + "-XXXXXX"
                                                }}</span>
                                            <span v-else class="text-gray-500">Produkt navn</span>
                                        </span>
                                        <span v-if="editMode">
                                            <span v-if="product.category" class="font-medium">{{ product.name
                                                }}</span>
                                            <span v-else class="text-gray-500">Produkt navn</span>
                                        </span>
                                    </p>
                                    <p v-if="currentParentCategory && currentParentCategory.value !== 377 && currentParentCategory.value !== 144 && currentParentCategory.value !== 620 && currentParentCategory.value !== 213 && currentParentCategory.value !== 132">
                                        <span v-if="product.size"
                                              class="status-badge topolis-small-badge turquoise-badge">{{ product.size.label
                                            }}</span>
                                        <span v-else class="topolis-small-red-badge">Str.</span>
                                    </p>
                                </div>
                                <div class="mt-2 flex flex-col xs:flex-row justify-between flex-wrap gap-2">
                                    <div class="flex items-center gap-2 mr-2 flex-wrap">
                                        <p v-if="currentParentCategory && currentParentCategory.value !== 377"
                                           class="flex items-center text-sm capitalize text-gray-800">
                                            <span v-if="product.brand" class="font-medium">{{ product.brand.label
                                                }}</span>
                                            <span v-else class="text-red-600">Mærke</span>
                                        </p>
                                        <p class="flex items-center text-sm text-gray-800">
                                            <span v-if="product.category" class="font-medium">{{ product.category.label
                                                }}</span>
                                            <span v-else class="text-gray-500">Kategori</span>
                                        </p>
                                        <p v-if="currentParentCategory && currentParentCategory.value !== 377"
                                           class="flex items-center text-sm text-gray-800">
                                            <span v-if="product.new_colors && product.new_colors.length"
                                                  class="text-gray-500 inline-flex items-center flex-wrap w-fit gap-1">
                                                <span v-for="color in product.new_colors" :key="color.value"
                                                      class="font-medium inline-flex items-center">
                                                    <color-badge model="color"
                                                                 :title="color.label"
                                                                 :name="color.label"
                                                                 :hex="color.hex"
                                                                 class="w-fit" />
                                                </span>
                                            </span>
                                            <span v-else class="text-red-600">Farve(r)</span>
                                        </p>
                                        <p v-if="currentParentCategory && currentParentCategory.value !== 377"
                                           class="flex items-center text-sm text-gray-800">
                                            <span v-if="product.pattern" class="font-medium">{{ product.pattern.label
                                                }}
                                            </span>
                                        </p>
                                        <p v-if="currentParentCategory && currentParentCategory.value === 377"
                                           class="flex items-center text-sm text-gray-800">
                                            <span v-if="product.title" class="font-medium">{{ product.title }}</span>
                                            <span v-else class="text-gray-500">Titel</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="text-left mr-2 xs:mr-0">
                                            <span v-if="!editMode">
                                                <span v-if="product.price"
                                                      class="font-medium text-gray-800">{{ product.price
                                                    }} kr.</span>
                                                <span v-else class="text-red-600">Pris</span>
                                            </span>
                                            <span v-if="editMode">
                                                <span v-if="product.category" class="font-medium">{{ product.price
                                                    }},00 kr.</span>
                                                <span v-else class="text-gray-500">Pris</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    v-if="(currentParentCategory && currentParentCategory.value === 377) || (currentParentCategory && currentParentCategory.value === 132) || (currentParentCategory && currentParentCategory.value === 378)"
                                    class="mt-1">
                                    <p class="flex items-center text-xs text-gray-800">
                                        <span v-if="product.description" class="font-medium">{{ product.description
                                            }}</span>
                                        <span v-else class="text-gray-500">Beskrivelse</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="product.marked_for_webshop">
                    <span class="text-sm flex items-center gap-1 mb-0.5">
                        <span>Webshop</span>
                        <span v-if="isWebshopValid">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 min-w-0 text-green-600"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-748 -188)">
                                    <path
                                        d="M10,0A10,10,0,1,0,20,10,10.016,10.016,0,0,0,10,0Zm4.78,7.7L9.11,13.37a.748.748,0,0,1-1.06,0L5.22,10.54A.75.75,0,1,1,6.28,9.48l2.3,2.3,5.14-5.14A.75.75,0,0,1,14.78,7.7Z"
                                        transform="translate(750 190)"
                                        fill="currentColor"
                                    />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span class="text-sm"></span>
                    <div class="border border-[#E6E6E6] rounded-2xl h-full"
                         :class="{ 'border-green-600': isWebshopValid }">
                        <div class="flex items-center justify-between gap-4 w-full h-full">
                            <div
                                class="w-1/2 h-auto bg-topolis-blue-50 flex justify-center items-center relative rounded-l-2xl self-stretch">
                                <div v-if="currentParentCategory">
                                    <img v-if="currentParentCategory.value === 144"
                                         class="flex-shrink-0 min-w-0 w-24 sm:w-32 -mt-2"
                                         src="/assets/categories/equipment.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 351"
                                         class="flex-shrink-0 min-w-0 w-24 sm:w-32 -mt-2"
                                         src="/assets/categories/clothes.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 221"
                                         class="flex-shrink-0 min-w-0 w-24 sm:w-32 -mt-2"
                                         src="/assets/categories/jacket.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 213"
                                         class="flex-shrink-0 min-w-0 w-24 sm:w-32 -mt-2"
                                         src="/assets/categories/toys.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 620"
                                         class="flex-shrink-0 min-w-0 w-20 sm:w-28 -mt-2"
                                         src="/assets/categories/interior.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 621"
                                         class="flex-shrink-0 min-w-0 w-24 sm:w-32 -mt-2"
                                         src="/assets/categories/footwear.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 376"
                                         class="flex-shrink-0 min-w-0 w-20 sm:w-28 -mt-2"
                                         src="/assets/categories/accessories.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 377"
                                         class="flex-shrink-0 min-w-0 w-20 sm:w-28 -mt-2"
                                         src="/assets/categories/books.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 132"
                                         class="flex-shrink-0 min-w-0 w-20 sm:w-28 -mt-1"
                                         src="/assets/categories/other.svg" alt="" />
                                    <img v-if="currentParentCategory.value === 378"
                                         class="flex-shrink-0 min-w-0 w-20 sm:w-28 -mt-1"
                                         src="/assets/categories/unknown.svg" alt="" />
                                </div>
                                <span v-if="currentParentCategory"
                                      class="absolute bottom-0.5 mx-auto text-sm font-bold">{{ currentParentCategory.label
                                    }}</span>
                                <span v-if="product.tags && product.tags.length"
                                      class="absolute top-2.5 right-2 text-sm font-bold inline-flex items-center justify-end flex-wrap gap-1">
                                   <span v-for="tag in product.tags" :key="tag.value"
                                         class="font-medium inline-flex items-center w-fit">
                                       <tag-badge model="tag" :name="tag.label"
                                                  :hex="tag.hex" size="mini" />
                                   </span>
                                </span>
                                <!--                                <span v-if="currentParentCategory === 132"-->
                                <!--                                      class="absolute bottom-0 mx-auto text-[10px] font-bold">Andet</span>-->
                                <!--                                <span v-if="currentParentCategory === 378"-->
                                <!--                                      class="absolute bottom-0 mx-auto text-[10px] font-bold">Ukendt</span>-->
                                <span v-if="!currentParentCategory"
                                      class="absolute top-1/4 mx-auto text-sm font-semibold text-gray-500">Overkategori</span>
                            </div>

                            <div class="flex flex-col justify-between w-1/2 py-2 lg:py-0 mr-1">
                                <div class="flex flex-col">
                                    <span class="text-lg text-left mb-1 truncate">
                                        <span v-if="product.pretty_name" class="font-medium">{{ product.pretty_name
                                            }}</span>
                                        <span v-else class="text-gray-500">Produkt navn</span>
                                    </span>
                                    <span class="text-sm mb-2">
                                        <span v-if="product.brand" class="font-medium">{{ product.brand.label }}</span>
                                        <span v-else class="text-gray-500">Mærke</span>
                                    </span>
                                    <span class="text-sm">
                                        <span v-if="product.pretty_description"
                                              class="font-light">{{ product.pretty_description }}</span>
                                        <span v-else class="text-red-600">Beskrivelse</span>
                                    </span>
                                </div>
                                <div class="border-b border-gray-600 h-1 w-full my-2.5"></div>
                                <div class="flex flex-col space-y-1">
                                    <span
                                        class="inline-flex items-center flex-wrap gap-1 text-sm text-left text-gray-500"
                                        v-if="product.new_colors && product.new_colors.length">
                                            Farve(r):
                                            <span v-for="color in product.new_colors" :key="color.value"
                                                  class="font-medium inline-flex items-center w-fit gap-1">
                                                <color-badge model="color"
                                                             :name="color.label"
                                                             :hex="color.hex"
                                                             class="w-fit" />
                                            </span>
                                    </span>
                                    <span class="text-sm text-left" v-if="product.patterns && product.patterns.length">
                                        <span class="text-gray-500">
                                            Mønster:
                                            <span v-for="(pattern, index) in product.patterns" :key="pattern.value"
                                                  class="text-sm font-medium inline-flex items-center flex-wrap w-fit">
                                                    {{ pattern.label }}
                                                <span class="text-xxs font-light mx-1"
                                                      v-if="index !== product.patterns.length - 1">|</span>
                                            </span>
                                        </span>
                                    </span>
                                    <span class="text-sm text-left">
                                        <span class="text-gray-500">
                                            Størrelse:
                                            <span v-if="product.size" class="font-medium">{{ product.size.label
                                                }}</span>
                                        </span>
                                    </span>
                                    <span class="text-sm text-left">
                                        <span class="text-gray-500">
                                            Kategori:
                                            <span v-if="product.category" class="font-medium">{{ product.category.label
                                                }}</span>
                                        </span>
                                    </span>
                                    <span class="text-sm text-left">
                                        <span class="text-gray-500">
                                            Stand:
                                            <span v-if="product.condition" class="font-medium">{{ condition }}</span>
                                            <span v-else class="text-red-600">Sæt stand</span>
                                        </span>
                                    </span>
                                </div>
                                <div class="border-b border-gray-600 h-1 w-full my-2.5"></div>
                                <div class="flex flex-col">
                                    <span class="text-lg text-left mb-2">
                                        <span v-if="product.category_id && product.brand"
                                              class="font-medium">{{ product.price }},00 kr.</span>
                                        <span v-else class="text-gray-500">Pris</span>
                                    </span>
                                    <span v-if="product.retail_price" class="text-xs text-left text-gray-500">
                                        Oprindelig købspris:
                                        <span class="font-medium">{{ product.retail_price }},00 kr.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ColorBadge from "./ColorBadge.vue";
    import TagBadge from "./TagBadge.vue";

    export default {
        name: "ProductPreview",
        components: { TagBadge, ColorBadge },
        props: {
            condition: {},
            currentParentCategory: {},
            editMode: {},
            isValid: {},
            isWebshopValid: {},
            product: {}
        }
    };
</script>