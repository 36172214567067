<template>
    <div>
        <div class="relative w-full">
            <div v-if="!usedForFiltering" class="flex items-center justify-between gap-2">
                <div class="flex items-center">
                    <label v-if="label" class="form-label" :class="{ 'opacity-25' : disabled }"
                           :for="id">{{ label
                        }}</label>
                    <div class="ml-1 -mt-1 text-red-500 font-bold" v-if="!disabled && !optional">*</div>
                    <div class="ml-1 -mt-1 text-xs font-normal text-gray-500" v-else-if="!disabled && optional">
                        (valgfrit)
                    </div>
                    <div class="ml-1 -mt-1.5 text-amber-800 font-bold cursor-help"
                         v-if="editMode && model === 'category'"
                         title="Når du redigerer, så kan du kun vælge kategorier med samme overkategori">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 h-4"
                             viewBox="0 0 24 24">
                            <g transform="translate(-108 -508)">
                                <path
                                    d="M8.745.442a2,2,0,0,1,2.51,0l1.58,1.35a2.31,2.31,0,0,0,1.27.46h1.7a1.938,1.938,0,0,1,1.93,1.93v1.7a2.279,2.279,0,0,0,.46,1.26l1.35,1.58a2,2,0,0,1,0,2.51l-1.35,1.58a2.279,2.279,0,0,0-.46,1.26v1.7A1.938,1.938,0,0,1,15.8,17.7h-1.7a2.279,2.279,0,0,0-1.26.46l-1.58,1.35a2,2,0,0,1-2.51,0l-1.58-1.35a2.307,2.307,0,0,0-1.26-.46H4.165a1.938,1.938,0,0,1-1.93-1.93v-1.71a2.237,2.237,0,0,0-.45-1.25l-1.35-1.59a2.013,2.013,0,0,1,0-2.5l1.35-1.59a2.264,2.264,0,0,0,.45-1.25V4.192a1.938,1.938,0,0,1,1.93-1.93h1.73a2.279,2.279,0,0,0,1.26-.46Z"
                                    transform="translate(110.005 510.008)" fill="none" stroke="currentColor"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0V4.83" transform="translate(120 516.13)"
                                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                                <path d="M0,0H.009" transform="translate(119.995 524)"
                                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                </div>
                <button v-if="model === 'category'"
                        class="font-bold text-xxs mb-1 hover:text-topolis-purple-600 underline focus:outline-none focus-visible:outline-none"
                        @click="openDrawer('create-guide')">Oprettelsesguide
                </button>
                <button v-if="model === 'size'" :class="{ 'opacity-25' : disabled }"
                        class="font-bold text-xxs mb-1 hover:text-topolis-purple-600 underline focus:outline-none focus-visible:outline-none"
                        @click="disabled? null : openDrawer('size-guide')"
                        :disabled="disabled">Størrelsesguide
                </button>
                <button v-if="model === 'colors'" :class="{ 'opacity-25' : disabled }"
                        class="font-bold text-xxs mb-1 hover:text-topolis-purple-600 underline focus:outline-none focus-visible:outline-none"
                        @click="disabled ? null : openDrawer('color-guide')" :disabled="disabled">Farveguide
                </button>
                <button v-if="model === 'patterns'" :class="{ 'opacity-25' : disabled }"
                        class="font-bold text-xxs mb-1 hover:text-topolis-purple-600 underline focus:outline-none focus-visible:outline-none"
                        @click="disabled ? null : openDrawer('pattern-guide')" :disabled="disabled">Mønsterguide
                </button>
                <button v-if="model === 'tags'" :class="{ 'opacity-25' : disabled }"
                        class="font-bold text-xxs mb-1 hover:text-topolis-purple-600 underline focus:outline-none focus-visible:outline-none"
                        @click="disabled ? null : openDrawer('tag-guide')" :disabled="disabled">Tagguide
                </button>
            </div>

            <Multiselect
                :id="id"
                ref="multiselect"
                :options="filteredItems"
                :mode="mode"
                :object="true"
                :placeholder="placeholder"
                :min-chars="1"
                :searchable="true"
                v-model="value"
                autocomplete="off"
                :disabled="disabled"
                class="mt-autocomplete"
                :close-on-select="true"
                :hide-selected="mode === 'tags'"
                :create-option="false"
                :append-new-option="false"
                no-results-text='Ingen resultater fundet. Vælg "Andet", hvis intet passer'
                @select="onSelect"
                :on-create="createItem"
                @clear="clearValue"
                @deselect="removeTag"
                @search-change="onSearch"
            >
                <template v-slot:option="{ option }">
                    <div v-if="option.__CREATE__ && adminPage">
                        + Opret {{ option.label }}
                    </div>
                    <div class="flex items-center" v-else>
                        <color-badge v-if="model === 'colors'" model="color" :name="option.label"
                                     :hex="option.hex"
                                     class="mr-2" />
                        <tag-badge v-if="model === 'tags'" :name="option.label"
                                   :hex="option.hex"
                                   class="mr-2" size="mini" />
                        <div class="text-sm" v-if="model !== 'tags'">
                            {{ option.label }}
                        </div>
                    </div>
                </template>

                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label"
                         :class="{'w-full' : disabled && model === 'category'}">
                        <div class="text-sm">{{ value.label }}</div>
                        <div class="absolute right-3" v-if="disabled && model === 'category'">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="flex-shrink-0 min-w-0 h-4 w-4 text-topolis-blue-700 opacity-75"
                                 viewBox="0 0 24 24">
                                <g id="lock" transform="translate(-172 -252)">
                                    <path d="M0,8V6C0,2.69,1,0,6,0s6,2.69,6,6V8"
                                          transform="translate(178 254)"
                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="1.5" />
                                    <path d="M5,2.5A2.5,2.5,0,1,1,2.5,0,2.5,2.5,0,0,1,5,2.5Z"
                                          transform="translate(181.5 265.5)" fill="none" stroke="currentColor"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                    <path d="M15,12H5c-4,0-5-1-5-5V5C0,1,1,0,5,0H15c4,0,5,1,5,5V7C20,11,19,12,15,12Z"
                                          transform="translate(174 262)" fill="none" stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" stroke-width="1.5" />
                                </g>
                            </svg>
                        </div>
                    </div>
                </template>
                <template v-slot:tag="{ option, disabled, handleTagRemove }">
                    <div class="multiselect-tag">
                        <div class="flex items-center">
                            <color-badge v-if="model === 'colors'" model="color" :name="option.label" :hex="option.hex"
                                         class="mr-2" />
                            <tag-badge v-if="model === 'tags'" :name="option.label"
                                       :hex="option.hex" size="mini"
                                       class="mr-2" />
                            <div class="text-sm" v-if="model !== 'tags'">
                                {{ option.label }}
                            </div>
                        </div>
                        <span class="multiselect-tag-remove"
                              @click.prevent="handleTagRemove(option, $event)">
                            <span class="multiselect-tag-remove-icon"></span>
                        </span>
                    </div>
                </template>
            </Multiselect>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import ColorBadge from "./ColorBadge.vue";
    import Multiselect from "@vueform/multiselect";

    const randcharset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randchars = (function(R, M) {
        const L = R.length,
            r = M.random,
            f = M.floor;
        return function(len) {
            let i,
                s = "";
            for (i = 0; i < len; i++) s += R[f(r() * L)];
            return s;
        };
    })(randcharset.split(""), Math);

    export default {
        name: "MTAutocomplete",
        components: {
            ColorBadge,
            Multiselect
        },
        // mounted() {
        //     this.$refs.multiselect.$refs.input.setAttribute("autocomplete", "off");
        // },
        props: {
            placeholder: {},
            modelValue: {},
            model: {},
            adminPage: {},
            product: {},
            id: {
                default: randchars(6)
            },
            label: {},
            disabled: {
                type: Boolean,
                default: false
            },
            items: {
                type: [Array, Object],
                required: true
            },
            mode: {
                type: String,
                required: true
            },
            optional: {
                type: Boolean,
                default: false,
                required: false
            },
            usedForFiltering: {
                type: Boolean,
                default: false,
                required: false
            },
            editMode: {
                type: Boolean,
                required: false
            },
            idName: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                value: this.modelValue,
                selectedOptions: [],
                // used when filtering products, not for creation.
                searchQuery: "",
                newCategoryCreated: false,
                newBrandCreated: false,
                newSizeCreated: false,
                newColorCreated: false,
                newPatternCreated: false
            };
        },
        // mounted() {
        //     this.$nextTick(() => {
        //         console.log("modelValue", this.modelValue);
        //     });
        // },
        computed: {
            filteredItems() {
                // console.log("modelValue", this.model);
                // If items is already in the correct format and no filtering needed, return it as is
                if (!this.usedForFiltering && Array.isArray(this.items)) {
                    if (this.model === "size") {
                        console.log("well", this.items);
                    }
                    return this.items;
                } else if (this.usedForFiltering) {
                    // Ensure items is an array. Convert to array if it's an object.
                    const itemsArray = Array.isArray(this.items) ? this.items : (this.items ? Object.values(this.items) : []);

                    let mappedItems; // Declare but don't initialize the variable
                    if (this.model === "colors") {
                        // Map itemsArray to an array of objects with 'label', 'value', and 'hex' properties.
                        mappedItems = itemsArray.map(item => ({
                            label: item.name,
                            value: item.id,
                            hex: item.hex_code
                        }));
                    } else {
                        // If the model isn't 'colors', map itemsArray to an array of objects with 'label' and 'value' properties.
                        mappedItems = itemsArray.map(item => ({
                            label: item.name,
                            value: item.id
                        }));
                    }

                    if (this.usedForFiltering && this.searchQuery) {
                        return mappedItems.filter(item => item.label.toLowerCase().includes(this.searchQuery.toLowerCase()));
                    }

                    return mappedItems;
                }

            }
        },
        methods: {
            openDrawer(name) {
                this.emitter.emit("open-drawer", name);
            },
            onSelect(optionId, select) {
                // When an item is clicked / selected
                if (this.model === "category") {
                    this.$emit("set-category-id", {
                        label: select.label,
                        value: select.value
                    });
                    if (this.usedForFiltering) {
                        this.$emit("set-category", select);
                    }
                    if (select.parent) {
                        if (optionId === 132 || optionId === 378) {
                            this.$emit("set-parent-category-id", optionId);
                        } else {
                            this.$emit("set-parent-category-id", {
                                productId: optionId,
                                parent_id: select.parent.id
                            });
                        }
                    } else {
                        this.$emit("set-parent-category-id", select);
                    }
                } else if (this.model === "brand") {
                    this.$emit("set-brand-id", {
                        label: select.label,
                        value: select.value
                    });
                    if (this.usedForFiltering) {
                        this.$emit("set-brand", select);
                    }
                } else if (this.model === "size") {
                    if (this.newSizeCreated) {
                        this.newSizeCreated = false;
                        return;
                    }
                    this.$emit("set-size-id", {
                        label: select.label,
                        value: select.value
                    });
                    if (this.usedForFiltering) {
                        this.$emit("set-size", select);
                    }
                } else if (this.model === "colors") {
                    this.$emit("add-color", optionId);
                    if (this.usedForFiltering) {
                        this.$emit("set-color", select);
                    }
                } else if (this.model === "patterns") {
                    this.$emit("add-pattern", optionId);
                } else if (this.model === "tags") {
                    this.$emit("add-tag", optionId);
                }

                if (this.usedForFiltering) {
                    this.selectedOptions.push(select.value);
                    this.$emit("update-product-filters", {
                        label: select.label,
                        value: select.value
                    });
                }

                this.$emit("clicked-item", optionId);
            },
            async createItem(option, select) {
                // Async request (eg. for validating)
                await new Promise((resolve, reject) => {
                    axios
                        .post("/create-entity", {
                            type: this.model,
                            query: option.label
                        })
                        .then((response) => {
                            if (this.model === "category") {
                                // this.newCategoryCreated = true;
                                // this.$emit("new-category-created", this.newCategoryCreated);
                                this.$emit("set-category-id", response.data.item);
                            } else if (this.model === "brand") {
                                // this.newBrandCreated = true;
                                // this.$emit("new-brand-created", this.newBrandCreated);
                                this.$emit("set-brand-id", response.data.item);
                                // Reset the flag
                                // this.newBrandCreated = false;
                            } else if (this.model === "size") {
                                this.$emit("added-size", response.data.item);
                                this.newSizeCreated = true;
                            } else if (this.model === "colors") {
                                // this.newColorCreated = true;
                                // this.$emit("new-color-created", this.newColorCreated);
                                this.$emit("add-color", response.data.item);
                            } else if (this.model === "patterns") {
                                // this.newPatternCreated = true;
                                // this.$emit("new-pattern-created", this.newPatternCreated);
                                this.$emit("add-pattern", response.data.item);
                            } else if (this.model === "tags") {
                                // this.newPatternCreated = true;
                                // this.$emit("new-tag-created", this.newPatternCreated);
                                this.$emit("add-tag", response.data.item);
                            }

                            resolve();
                        })
                        .catch((error) => {
                            console.log(error);

                            reject();
                        });
                });

                return option;
            },
            clearValue() {
                this.$emit("clear-value");
            },
            removeTag(option, select) {
                console.log("hello??", this.model);
                if (this.model === "colors") {
                    this.$emit("remove-color", option);
                } else if (this.model === "patterns") {
                    this.$emit("remove-pattern", option);
                } else if (this.model === "tags") {
                    this.$emit("remove-tag", option);
                }

                if (this.usedForFiltering) {
                    if (this.model === "category") {
                        this.$emit("remove-category", option);
                    }
                    if (this.model === "brand") {
                        this.$emit("remove-brand", option);
                    }
                    if (this.model === "size") {
                        this.$emit("remove-size", option);
                    }
                }

                this.$refs.multiselect.close();
            },
            onSearch(query) {
                this.searchQuery = query;
            }
        }
    };
</script>

<style>
    .multiselect.mt-autocomplete {
        min-width: 150px;
        border-radius: 0.5rem !important;
        font-size: 0.875rem;
        min-height: 38px !important;
    }

    .multiselect.mt-autocomplete .multiselect-wrapper {
        min-height: 38px !important;
    }

    .multiselect.mt-autocomplete .multiselect-search {
        border: transparent !important;
        border-radius: 0.5rem !important;
    }

    .multiselect.mt-autocomplete .multiselect-placeholder {
        color: #d1d5db !important;
        font-size: 0.875rem;
        padding-left: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .multiselect.mt-autocomplete.is-disabled {
        border: transparent !important;
        height: 40px !important;
        cursor: not-allowed !important;
    }

    .multiselect.mt-autocomplete.is-disabled .multiselect-wrapper {
        cursor: not-allowed !important;
    }

    .multiselect.mt-autocomplete.is-disabled .multiselect-placeholder {
        color: #d1d5db !important;
    }

    .multiselect.mt-autocomplete.is-disabled .multiselect-caret {
        display: none !important;
    }

    .multiselect.is-open {
        border-top-left-radius: 0.5rem !important;
        border-top-right-radius: 0.5rem !important;
    }

    [type='text']:focus {
        box-shadow: none;
    }

    .multiselect-option {
        border-top-width: 1px !important;
        border-bottom-width: 0px !important;
        border-style: solid !important;
        border-color: rgb(243 244 246) !important;
    }

    .multiselect-option {
        font-size: 14px !important;
    }

    .multiselect-tag {
        background: #f4f4f5 !important;
        color: #27272a !important;
        font-weight: 500 !important;
    }
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
