<template>
    <div class="flex flex-col bg-topolis-gray-400 shadow px-4 py-4 md:px-8">
        <h4 class="app-h4 mb-4">Tilmeldingsresumé</h4>
        <div class="flex justify-between border-b border-gray-300 pb-4 mb-4 space-x-4">
            <div class="flex flex-col xs:flex-row md:flex-col 2xl:flex-row justify-between w-full gap-4">
                <div class="flex flex-col gap-1 w-full">
                    <span class="font-bold text-sm">Event</span>
                    <span>{{ event.name }}</span>
                </div>
                <div class="flex justify-between xs:justify-end md:justify-between 2xl:justify-end gap-4 w-full">
                    <div class="flex flex-col gap-1">
                        <span class="font-bold text-sm">Billet pris</span>
                        <span v-if="event.price.value.amount !== '0'">{{ formattedPricePerTicket }}</span>
                        <span v-else>Gratis</span>
                    </div>
                    <div class="flex flex-col">
                        <span class="font-bold text-sm">Antal</span>
                        <div class="flex items-center gap-1">
                            <button @click="decreaseTicketCounter" type="button" class="topolis-number-button"
                                    :disabled="form.ticketCount === 1">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     class="flex-shrink-0 min-w-0 w-3"
                                     viewBox="0 0 24 24">
                                    <g transform="translate(-556 -252)">
                                        <path d="M0,0H12" transform="translate(562 264)"
                                              fill="none"
                                              stroke="currentColor" stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2" />
                                    </g>
                                </svg>
                            </button>
                            <input name="ticketCount" id="ticketCount" :value="form.ticketCount" type="number"
                                   min="1"
                                   max="99" disabled
                                   class="bg-transparent border-none p-0 m-0 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none">
                            <button @click="increaseTicketCounter" type="button" class="topolis-number-button"
                                    :disabled="totalAttendees + form.ticketCount >= event.max_attendees">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     class="flex-shrink-0 min-w-0 w-3"
                                     viewBox="0 0 24 24">
                                    <g transform="translate(-492 -252)">
                                        <path d="M0,0H12" transform="translate(498 264)"
                                              fill="none"
                                              stroke="currentColor" stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2" />
                                        <path d="M0,12V0"
                                              transform="translate(504 258)" fill="none"
                                              stroke="currentColor"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col pb-4 mb-4 space-y-1">
            <div class="flex justify-between gap-2">
                <div class="flex space-x-4">
                    <div class="flex flex-col">
                        <span class="font-black text-lg">Total inkl. moms</span>
                    </div>
                </div>
                <div class="flex flex-col">
                    <span id="totalPrice" class="font-black text-lg">{{ formattedTotal }}</span>
                </div>
            </div>
            <div v-if="event.price.value.amount !== '0'">
                <div class="flex justify-between gap-2">
                    <div class="flex space-x-4">
                        <div class="flex flex-col">
                            <span>Moms udgør</span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <span v-if="event.price.value.amount !== '0'"
                              id="priceWithoutVat">{{ formattedPriceWithoutVat
                            }}</span>
                        <span v-else>{{ formattedTotal }}</span>
                    </div>
                </div>
                <div class="flex justify-between gap-2">
                    <div class="flex space-x-4">
                        <div class="flex flex-col">
                            <span>Subtotal ekskl. moms</span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <span v-if="event.price.value.amount !== '0'" id="vatPrice">{{ formattedVatPrice
                            }}</span>
                        <span v-else>{{ event.price.value.amount }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col mb-4">
            <div class="flex">
                <div class="flex items-center h-5">
                    <input type="hidden" name="accepts_trade_terms" value="off" />
                    <input id="accepts_trade_terms" name="accepts_trade_terms" type="checkbox"
                           class="form-input-checkbox" required v-model="form.accepts_trade_terms" />
                </div>
                <div class="ml-3 text-sm">
                    <label for="accepts_trade_terms" class="font-medium text-gray-700"
                    >Jeg accepterer <span><a href="https://minitopolis.dk/handelsbetingelser#/"
                                             class="a-link" target="_blank">handelsbetingelserne</a></span>
                    </label>
                </div>
            </div>
            <div v-if="errors && errors.accepts_trade_terms" class="mt-1 text-xs text-red-500">
                <p>{{ errors.accepts_trade_terms[0] }}</p>
            </div>
        </div>
        <div class="flex flex-col mb-6">
            <div class="flex">
                <div class="flex items-center h-5">
                    <input type="hidden" name="accepts_marketing_emails" value="off" />
                    <input id="accepts_marketing_emails" name="accepts_marketing_emails" type="checkbox"
                           class="form-input-checkbox" v-model="form.accepts_marketing_emails" />
                </div>
                <div class="ml-3 text-sm">
                    <label for="accepts_marketing_emails" class="font-medium text-gray-700">
                        Jeg vil gerne
                        modtage Minitopolis' nyhedsbrev pr. email
                    </label>
                </div>
            </div>
            <div v-if="errors && errors.accepts_marketing_emails" class="mt-1 text-xs text-red-500">
                <p>{{ errors.accepts_marketing_emails[0] }}</p>
            </div>
        </div>
        <div class="flex w-full mb-6">
            <loading-button type="submit" :loading="loading"
                            :disabled="totalAttendees === event.max_attendees || event.cancelled_at || !event.is_active || !isFormValid"
                            class="button button-purple event-button">
                <span v-if="totalAttendees === event.max_attendees">Fuldt booket</span>
                <span v-else-if="event.cancelled_at">Aflyst</span>
                <span v-else-if="!event.is_active">Overstået</span>
                <span v-else>{{ event.price.value.amount === "0" ? "Tilmeld" : "Gå til betaling" }}</span>
            </loading-button>
        </div>
        <div class="flex flex-col justify-center space-y-2">
            <div class="flex justify-center -mx-1 flex-wrap">
                <img loading="lazy" class="w-20 bg-no-repeat m-1"
                     src="/assets/payments/mobile-pay.svg" alt="Mobile Pay logo">
                <img loading="lazy" class="w-12 bg-no-repeat m-1"
                     src="/assets/payments/visa.svg" alt="Visa logo">
                <img loading="lazy" class="w-10 bg-no-repeat m-1"
                     src="/assets/payments/visa-electron.svg" alt="Visa Electron logo">
                <img loading="lazy" class="w-12 bg-no-repeat m-1"
                     src="/assets/payments/mastercard.svg" alt="Mastercard logo">
                <img loading="lazy" class="w-12 bg-no-repeat m-1"
                     src="/assets/payments/maestro.svg" alt="Maestro logo">
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingButton from "../Pages/Common/LoadingButton.vue";

    export default {
        components: { LoadingButton },

        props: {
            event: Object,
            errors: Object,
            totalAttendees: Number,
            loading: Boolean,
            isFormValid: Boolean
        },
        watch: {
            form: {
                handler: function(newValue) {
                    this.$emit("event-form-updated", newValue);
                },
                deep: true
            }
        },
        data() {
            return {
                form: {
                    ticketCount: 1,  // Initial ticket count,
                    accepts_marketing_emails: false,
                    accepts_trade_terms: false
                }
            };
        },
        computed: {
            pricePerTicket() {
                console.log(this.event.price.value.amount);
                return parseFloat(this.event.price.value.amount) / 100;
                // Output: 39500 to 395
            },
            total() {
                return this.pricePerTicket * this.form.ticketCount;
            },
            vatPrice() {
                return this.total / 1.25; // Assuming 25% VAT
            },
            priceWithoutVat() {
                return this.total - this.vatPrice;
            },
            formattedPricePerTicket() {
                return this.formattedCurrency(this.pricePerTicket);

            },
            formattedTotal() {
                return this.formattedCurrency(this.total);
            },
            formattedVatPrice() {
                return this.formattedCurrency(this.vatPrice);
            },
            formattedPriceWithoutVat() {
                return this.formattedCurrency(this.priceWithoutVat);
            }
        },
        methods: {
            formattedCurrency(amount) {
                return new Intl.NumberFormat("da-DK", {
                    style: "currency",
                    currency: "DKK"
                }).format(amount);
            },
            increaseTicketCounter() {
                if ((this.form.ticketCount < this.event.max_attendees) && ((this.totalAttendees + this.form.ticketCount) < this.event.max_attendees)) {
                    this.form.ticketCount++;
                }
            },
            decreaseTicketCounter() {
                if (this.form.ticketCount > 1) {
                    this.form.ticketCount--;
                }
            }
            // submit() {
            //     // this.$emit("button-loading", true);
            //     this.loading = true;
            //     console.log(this.loading);
            //     // this.$emit("submit", this.form);
            // }
        }
    };
</script>
