import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";
import { useForm } from "laravel-precognition-vue";

const store = createStore({
    plugins: [createPersistedState()],
    state: {
        // Booking
        periods: [],
        authenticatedUser: null,
        chosenConcept: null,
        chosenSubConcept: null,
        chosenPeriod: null,
        startDate: null,
        endDate: null,
        formattedStartDate: null,
        formattedEndDate: null,
        selectedWeeks: [],
        startWeek: null,
        endWeek: null,
        pickupWeek: null,
        chosenDelivery: null,
        bookingUuid: null,
        note: null,
        conceptPrices: {
            easy25: 19900,
            easy50: 39900,
            easy75: 59900,
            easy100: 79900,
            easier50: 24900,
            easier100: 39900,
            easiest: 39900
        },
        pickUpPrice: 7900,
        // for the profile form fields in the booking flow:
        showPassword: false,
        registrationStep: false,
        fullRegistration: false,
        isEditMode: false,

        // Shop
        cart: {
            items: []
        },
        clientId: null,
        showCart: false,
        checkout: {
            errors: null,
            form: useForm("post", "/checkout", {
                name: null,
                address: null,
                zip: null,
                city: null,
                email: "",
                confirm_email: "",
                phone: null,
                // password: null,
                note: null,
                accepts_marketing_emails: false,
                accepts_trade_terms: false,
                // accepts_quality_terms: false,
                delivery_method: "store_delivery",
                chosenCarrier: null,
                chosenCarrierService: null,
                delivery_shop_id: null
            })
        }
    },
    mutations: {
        // Booking
        setConcept(state, concept) {
            state.chosenConcept = concept;
        },
        setSubConcept(state, concept) {
            state.chosenSubConcept = concept;
        },
        setPeriods(state, periods) {
            state.periods = periods;
        },
        setPeriod(state, period) {
            state.chosenPeriod = period;
        },
        setStartDates(state, dates) {
            state.startDate = dates.startDate;
            state.formattedStartDate = dates.formattedStartDate;
        },
        setEndDates(state, dates) {
            state.endDate = dates.endDate;
            state.formattedEndDate = dates.formattedEndDate;
        },
        setStartWeek(state, week) {
            state.startWeek = week;
        },
        setEndWeek(state, week) {
            state.endWeek = week;
        },
        setSelectedWeeks(state, weeks) {
            state.selectedWeeks = weeks;
            state.pickupWeek = state.selectedWeeks[state.selectedWeeks.length - 1];
        },
        setAuthenticatedUser(state, user) {
            state.authenticatedUser = user;
        },
        setBookingUuid(state, bookingUuid) {
            state.bookingUuid = bookingUuid;
        },
        setNote(state, note) {
            state.note = note;
        },
        // for the profile form fields in the booking flow:
        setParameters(state, parameters) {
            state.showPassword = parameters.showPassword;
            state.registrationStep = parameters.registrationStep;
            state.fullRegistration = parameters.fullRegistration;
            state.isEditMode = parameters.isEditMode;
        },

        // Shop
        setCart(state, cart) {
            state.cart = cart;
        },
        showCart(state) {
            state.showCart = true;
            //document.querySelector('body').classList.add('overflow-hidden');
        },
        hideCart(state) {
            state.showCart = false;

            //document.querySelector('body').classList.remove('overflow-hidden');
        },
        setErrors(state, errors) {
            state.checkout.errors = errors;
        },
        setClientId(state, payload) {
            state.clientId = payload;
        }
    },
    getters: {
        // Booking
        periods(state) {
            return state.periods;
        },
        chosenConcept(state) {
            return state.chosenConcept;
        },
        conceptPrices(state) {
            return state.conceptPrices;
        },
        chosenSubConcept(state) {
            return state.chosenSubConcept;
        },
        chosenPeriod(state) {
            return state.chosenPeriod;
        },
        startWeek(state) {
            return state.startWeek;
        },
        endWeek(state) {
            return state.endWeek;
        },
        pickupWeek(state) {
            return state.pickupWeek;
        },
        selectedWeeks(state) {
            return state.selectedWeeks;
        },
        authenticatedUser(state) {
            return state.authenticatedUser;
        },
        bookingUuid(state) {
            return state.bookingUuid;
        },
        startDate(state) {
            return state.startDate;
        },
        endDate(state) {
            return state.endDate;
        },
        formattedStartDate(state) {
            return state.formattedStartDate;
        },
        formattedEndDate(state) {
            return state.formattedEndDate;
        },
        note(state) {
            return state.note;
        },

        // Shop
        checkoutForm(state) {
            return state.checkout.form;
        },
        cartProductIds(state) {
            return Object.keys(state.cart.items).map(key => {
                return state.cart.items[key];
            }).map(product => product.id);
        },
        cartProducts(state) {
            return Object.keys(state.cart.items).map(key => {
                return state.cart.items[key];
            });
        },
        checkoutErrors(state) {
            return state.checkout.errors;
        }
    },
    actions: {
        // Booking
        startBooking({ state, commit }) {
            return new Promise((resolve, reject) => {
                axios.post("booking-start", {
                    booking_uuid: state.bookingUuid,
                    conceptId: state.chosenConcept === "easiest" ? state.chosenPeriod.id : state.chosenSubConcept,
                    note: state.note,
                    start_date: state.startWeek.start_date,
                    end_date: state.endWeek.end_date
                }).then((response) => {
                    commit("setBookingUuid", response.data.booking.uuid);

                    resolve();
                }).catch((error) => {
                    console.log(error);

                    reject();
                });
            });

        },
        getPeriods({ state, commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("concepts")
                    .then((response) => {
                        commit("setPeriods", response.data.concepts);
                        console.log(response.data);
                        resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        reject();
                    });
            });

        },

        // Shop
        getCartItems({ commit, state }) {
            axios.get("/cart").then((response) => {
                commit("setCart", response.data.result);
            });
        },
        addToCart({ commit, state }, product) {
            axios.post("/cart/add", { product: product }).then((response) => {
                commit("setCart", response.data.result);
                commit("showCart");
            }).catch((error) => {
                console.log(error);
            });
        },
        removeFromCart({ commit, state }, hash) {
            axios.delete("/cart/remove-single?hash=" + hash).then((response) => {
                commit("setCart", response.data.result);
            }).catch((error) => {
                console.log(error);
            });
        },
        removeAll({ commit, state }, product) {
            axios.delete("/cart/remove-all").then((response) => {
                commit("setCart", response.data.result);
            }).catch((error) => {
                console.log(error);
            });
        }
    }
});

export default store;
