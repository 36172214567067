<template>
    <form class="w-full space-y-2" method="POST" @submit.prevent="submitFilters">
        <div class="flex flex-row md:flex-col lg:flex-row flex-wrap w-full -m-0.5">
            <div class="flex flex-col relative p-1 w-full sm:w-1/2 md:w-full lg:w-1/2 xl:w-[12%]">
                <!--                <label class="form-label" for="category_id">Filtrér efter kategori</label>-->
                <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="category"
                                          :placeholder="$translate('Categories')"
                                          :items="categories" @set-category="setFilteredCategory"
                                          @remove-category="deleteFilteredCategory"
                                          @clear-value="clearAllFilteredCategories"></minitopolis-autocomplete>

            </div>
            <div class="flex flex-col relative p-1 w-full sm:w-1/2 md:w-full lg:w-1/2 xl:w-[12%]">
                <!--                <label class="form-label" for="category_id">Filtrér efter mærke</label>-->
                <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="brand"
                                          :placeholder="$translate('Brands')"
                                          :items="brands" @set-brand="setFilteredBrand"
                                          @remove-brand="deleteFilteredBrand"
                                          @clear-value="clearAllFilteredBrands"></minitopolis-autocomplete>

            </div>
            <div class="flex flex-col relative p-1 w-full sm:w-1/2 md:w-full lg:w-1/2 xl:w-[12%]">
                <!--                <label class="form-label" for="category_id">Filtrér efter størrelse</label>-->
                <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="size"
                                          :placeholder="$translate('Sizes')"
                                          :items="sizes" @set-size="setFilteredSize" @remove-size="deleteFilteredSize"
                                          @clear-value="clearAllFilteredSizes"></minitopolis-autocomplete>

            </div>
            <div class="flex flex-col relative p-1 w-full sm:w-1/2 md:w-full lg:w-1/2 xl:w-[12%]">
                <!--                <label class="form-label" for="category_id">Filtrér efter farve</label>-->
                <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="color"
                                          :placeholder="$translate('Colors')"
                                          :items="colors" @set-color="setFilteredColor"
                                          @remove-color="deleteFilteredColor"
                                          @clear-value="clearAllFilteredColors"></minitopolis-autocomplete>

            </div>
            <div class="flex flex-col relative p-1 w-full sm:w-1/2 md:w-full lg:w-1/2 xl:w-[12%]">
                <!--                <label class="form-label" for="category_id">Filtrér efter mønster</label>-->
                <minitopolis-autocomplete mode="tags" :used-for-filtering="true" model="pattern"
                                          :placeholder="$translate('Patterns')"
                                          :items="patterns" @set-color="setFilteredPattern"
                                          @remove-color="deleteFilteredPattern"
                                          @clear-value="clearAllFilteredPatterns"></minitopolis-autocomplete>

            </div>
            <div class="flex flex-col relative p-1 w-full sm:w-1/2 md:w-full lg:w-1/2 xl:w-[12%]">
                <!--                <label class="form-label" for="category_id">Filtrér efter kategori</label>-->
                <min-max-slider class="mx-6 mt-4 mb-8 sm:m-4" :value="[filters.priceFrom, filters.priceTo]"
                                @changed-price="handlePriceChange"></min-max-slider>

            </div>
        </div>
        <button type="submit" class="button button-gray smaller-button">Filtrér</button>
    </form>
</template>

<script>
    import MTAutocomplete from "../Pages/Common/MTAutocomplete.vue";
    import MinMaxSlider from "../Shop/MinMaxSlider.vue";

    export default {
        components: {
            MTAutocomplete, MinMaxSlider
        },
        props: {
            categories: {},
            brands: {},
            sizes: {},
            colors: {},
            patterns: {}
        },
        data() {
            return {
                filters: {
                    autocompleteCategories: [],
                    autocompleteBrands: [],
                    autocompleteSizes: [],
                    autocompleteColors: [],
                    autocompletePatterns: [],
                    priceFrom: 0,
                    priceTo: 1500
                }
            };
        },
        methods: {
            handlePriceChange(newPriceRange) {
                this.filters.priceFrom = newPriceRange[0];
                this.filters.priceTo = newPriceRange[1];
            },
            submitFilters() {
                this.$emit("filters-changed", this.filters);  // emit an event with current filter values
            },
            setFilteredCategory(payload) {
                console.log(payload);
                this.filters.autocompleteCategories.push(payload.value);
            },
            setFilteredBrand(payload) {
                this.filters.autocompleteBrands.push(payload.value);
            },
            setFilteredSize(payload) {
                this.filters.autocompleteSizes.push(payload.value);
            },
            setFilteredColor(payload) {
                this.filters.autocompleteColors.push(payload.value);
            },
            setFilteredPattern(payload) {
                this.filters.autocompletePatterns.push(payload.value);
            },
            deleteFilteredCategory(payload) {
                this.filters.autocompleteCategories = this.filters.autocompleteCategories.filter(
                    item => item !== payload.value
                );
            },
            deleteFilteredBrand(payload) {
                this.filters.autocompleteBrands = this.filters.autocompleteBrands.filter(
                    item => item !== payload.value
                );
            },
            deleteFilteredSize(payload) {
                this.filters.autocompleteSizes = this.filters.autocompleteSizes.filter(
                    item => item !== payload.value
                );
            },
            deleteFilteredColor(payload) {
                this.filters.autocompleteColors = this.filters.autocompleteColors.filter(
                    item => item !== payload.value
                );
            },
            deleteFilteredPattern(payload) {
                this.filters.autocompletePatterns = this.filters.autocompletePatterns.filter(
                    item => item !== payload.value
                );
            },
            clearAllFilteredCategories() {
                this.filters.autocompleteCategories = [];
            },
            clearAllFilteredBrands() {
                this.filters.autocompleteBrands = [];
            },
            clearAllFilteredSizes() {
                this.filters.autocompleteSizes = [];
            },
            clearAllFilteredColors() {
                this.filters.autocompleteColors = [];
            },
            clearAllFilteredPatterns() {
                this.filters.autocompletePatterns = [];
            }
        }
    };

</script>
