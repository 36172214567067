<template>
    <div class="space-y-4 w-full">
        <div v-for="product in $store.state.cart.items" :key="product.id">
            <div class="space-y-2 mt-4" :class="{ 'p-1' : inCart}">
                <div class="flex">
                    <div class="basis-1/12" :class="{ 'basis-2/12' : inCart }">
                        <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg"
                             alt="">
                    </div>
                    <div class="flex justify-between text-left w-full ml-3 my-1">
                        <div class="flex flex-col justify-between gap-1 leading-3 mr-4">
                            <p class="inline-flex font-medium">{{ product.title }}, str. {{ product.options.size }}</p>
                            <p class="inline-flex font-medium">{{ product.quantity }}</p>
                        </div>
                        <div class="flex flex-col items-end justify-between gap-1">
                            <div v-if="product.extra_info.discount" class="flex">
                                <price class="font-bold text-sm line-through"
                                       :value="product.extra_info.original_price"></price>
                                <price :value="product.extra_info.discounted_price"></price>
                            </div>
                            <div v-if="!product.extra_info.discount" class="flex">
                                <price class="font-bold text-sm" :value="Number(product.price)"></price>
                            </div>
                            <button v-if="inCart" @click="removeFromCart(product)"
                                    class="remove-item-cart inline-flex text-xxs font-medium underline hover:text-red-700">
                                Fjern
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Price from "./Price.vue";

    export default {
        name: "product-list",
        components: { Price },
        props: {
            inCart: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        methods: {
            removeFromCart(product) {
                console.log("cart", product);
                this.$store.dispatch("removeFromCart", product.hash);
            }
        }
    };
</script>
