<template>
    <transition name="bounce">
        <div aria-live="assertive" class="toast fixed bottom-4 right-4 z-[9999] flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start w-full">
            <div v-if="type === 'success'" id="toast-success" class="w-full flex flex-col items-center space-y-4 sm:items-end">
                <div class="max-w-sm w-full bg-gradient-to-r from-green-200 from-2% via-white/[0.2] via-3% to-white/[0.5] to-90% backdrop-blur-sm shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="p-4">
                        <div class="flex items-start">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 text-green-700" viewBox="0 0 24 24">
                                <g transform="translate(-748 -188)">
                                    <path
                                        d="M10,0A10,10,0,1,0,20,10,10.016,10.016,0,0,0,10,0Zm4.78,7.7L9.11,13.37a.748.748,0,0,1-1.06,0L5.22,10.54A.75.75,0,1,1,6.28,9.48l2.3,2.3,5.14-5.14A.75.75,0,0,1,14.78,7.7Z"
                                        transform="translate(750 190)"
                                        fill="currentColor"
                                    />
                                </g>
                            </svg>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-bold text-gray-900">{{ message }}</p>
                                <!--                                <p class="mt-1 text-sm text-gray-500">-->
                                <!--                                    {{ message }}-->
                                <!--                                </p>-->
                            </div>
                            <div class="ml-4 flex-shrink-0 flex">
                                <button class="inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500" @click="close()">
                                    <span class="sr-only">Close</span>
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="type === 'error'" id="toast-danger" class="w-full flex flex-col items-center space-y-4 sm:items-end">
                <div class="max-w-sm w-full bg-gradient-to-r from-red-200 from-2% via-white/[0.2] via-3% to-white/[0.5] to-90% backdrop-blur-sm shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="p-4">
                        <div class="flex items-start">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 text-red-700" viewBox="0 0 24 24">
                                <g transform="translate(-236 -252)">
                                    <path
                                        d="M10,0A10,10,0,1,0,20,10,10.016,10.016,0,0,0,10,0Zm3.36,12.3a.754.754,0,0,1,0,1.06.748.748,0,0,1-1.06,0L10,11.06l-2.3,2.3a.748.748,0,0,1-1.06,0,.754.754,0,0,1,0-1.06L8.94,10,6.64,7.7A.75.75,0,0,1,7.7,6.64L10,8.94l2.3-2.3A.75.75,0,0,1,13.36,7.7L11.06,10Z"
                                        transform="translate(238 254)"
                                        fill="currentColor"
                                    />
                                </g>
                            </svg>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-bold text-gray-900">{{ message }}</p>
                                <!--                                <p class="mt-1 text-sm text-gray-500">-->
                                <!--                                    {{ message }}-->
                                <!--                                </p>-->
                            </div>
                            <div class="ml-4 flex-shrink-0 flex">
                                <button class="inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500" @click="close()">
                                    <span class="sr-only">Close</span>
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: 'Toast',
        data() {
            return {
                show: false,
                message: null,
                type: null,
            };
        },
        created() {
            this.emitter.on('show-toast', (toast) => {
                this.show = true;
                this.message = toast.message;
                this.type = toast.type;

                setTimeout(() => {
                    this.show = false;
                }, 4000);
            });
        },
        methods: {
            close() {
                console.log('hello', this.show);
                this.show = false;
            },
        },
    };
</script>
