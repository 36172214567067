<template>
    <div class="flex flex-col">
        <div class="overflow-x-auto">
            <div class="py-2 align-middle inline-block min-w-full">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="table min-w-full divide-y divide-gray-200 lg:min-w-screen">
                        <!-- Table header -->
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col"
                                class="px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div class="font-semibold text-left">{{ $translate("Product name") }}</div>
                            </th>
                            <th scope="col"
                                class="px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div class="font-semibold text-left">{{ $translate("Color(s)") }}</div>
                            </th>
                            <th scope="col"
                                class="px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div class="font-semibold text-left">{{ $translate("Size") }}/{{ $translate("Desc") }}
                                </div>
                            </th>
                            <th scope="col"
                                class="px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div class="font-semibold text-left">{{ $translate("Status") }}</div>
                            </th>
                            <th scope="col"
                                class="px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div class="font-semibold text-left">{{ $translate("Price") }}</div>
                            </th>
                            <th scope="col" class="relative px-2 py-4">
                                <span class="sr-only">{{ $translate("Edit") }}</span>
                            </th>
                        </tr>
                        </thead>

                        <!-- Table body -->
                        <tbody class="text-sm">
                        <!-- Row -->
                        <tr v-for="(product, i) in products" :key="'product-' + i" class="bg-white">
                            <template v-for="(variant, j) in product.variants" :key="'variant-' + j">
                                <td class="px-2 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{ product.name }}
                                </td>
                                <td class="px-2 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
                                    <div class="flex flex-col">
                                        <div v-if="variant.colors && variant.colors.length"
                                             class="text-gray-500 inline-flex items-center flex-wrap w-fit gap-1">
                                            <div v-for="color in variant.colors" :key="color.value"
                                                 class="font-medium inline-flex items-center">
                                                <color-badge model="color"
                                                             :title="color.name"
                                                             :name="color.name"
                                                             :hex="color.hex_code"
                                                             class="w-fit" />
                                            </div>
                                        </div>
                                        <div v-if="variant.patterns && variant.patterns.length"
                                             class="text-gray-500 inline-flex items-center flex-wrap w-fit gap-1">
                                            <div v-for="pattern in variant.patterns" :key="pattern.value"
                                                 class="font-medium inline-flex items-center">
                                                {{ pattern.name }}
                                            </div>
                                        </div>
                                    </div>

                                </td>
                                <td class="px-2 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{ variant.size ? variant.size.name : null }}
                                </td>
                                <td class="px-2 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{ $translate(product.status) }}
                                </td>
                                <td class="px-2 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
                                    <div v-if="variant.discount" class="flex flex-col">
                                        <div class="flex">
                                            <p class="text-xs xs:text-sm font-medium text-red-500">
                                                <price :price="variant.discounted_price"></price>
                                            </p>
                                        </div>
                                        <div class="flex items-center -mt-0.5">
                                            <p class="text-gray-500 line-through text-xxs mr-1">
                                                <price :price="variant.price"></price>
                                            </p>
                                            <p class="text-[10px] xs:text-xxs text-red-500 font-medium">
                                                -{{ variant.discount
                                                }}%</p>
                                        </div>
                                    </div>
                                    <div v-else
                                         class="flex text-xs xs:text-sm font-medium text-topolis-blue-700 mr-2 xs:mr-0">
                                        <price :price="variant.price"></price>
                                    </div>
                                </td>
                                <td class="px-2 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{ product.name }}
                                </td>
                            </template>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ColorBadge from "./ColorBadge.vue";

    export default {
        name: "ProductTable",
        components: {
            ColorBadge
        },
        props: {
            products: {},
            adminPage: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    };
</script>