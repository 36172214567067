<template>
    <div class="relative bg-white flex flex-col p-4 md:p-8 w-full md:w-2/5">
        <div class="md:mr-auto max-w-xl md:fixed">
            <h3>Ordreoversigt</h3>
            <div>
                <product-list></product-list>
                <div class="flex flex-col gap-1 border-y border-stone-100 py-4 my-4">
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span>Produkttotal</span>
                            </div>
                        </div>
                        <price :value="$store.state.cart.subtotal"></price>
                    </div>
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span>Fragt</span>
                            </div>
                        </div>
                        <price :value="$store.state.cart.subtotal"></price>
                    </div>
                </div>
                <div class="flex flex-col gap-1 mb-2">
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span class="font-bold text-base">Total inkl. moms</span>
                            </div>
                        </div>
                        <price :value="$store.state.cart.subtotal"></price>
                    </div>
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span>Moms udgør</span>
                            </div>
                        </div>
                        <price :value="$store.state.cart.tax_amount"></price>
                    </div>
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span>Subtotal ekskl. moms</span>
                            </div>
                        </div>
                        <price :value="$store.state.cart.subtotal"></price>
                    </div>
                </div>
                <!--Terms-->
                <div v-if="!inCart" class="py-4">
                    <label class="flex p-2 border border-gray-300 bg-stone-100 hover:bg-stone-50"
                           for="accepts_trade_terms">
                            <span class="flex items-center h-5">
<!--                                <input id="accepts_trade_terms" v-model="form.accepts_trade_terms" name="accepts_trade_terms" type="checkbox" class="form-input-checkbox" value="" />-->
                                <input id="accepts_trade_terms" name="accepts_trade_terms" type="checkbox"
                                       class="form-input-checkbox" value="" />
                            </span>
                        <span class="ml-3 text-sm">
                                <span class="font-medium text-gray-700"
                                >Jeg accepterer
                                    <a href="https://minitopolis.dk/handelsbetingelser#/" class="a-link"
                                       target="_blank">handelsbetingelserne</a>
                                    og giver samtykke til at Minitopolis behandler mine
                                    <a href="https://minitopolis.dk/persondatapolitik#/" class="a-link" target="_blank">persondata</a>

                                </span>
                            </span>
                    </label>
                </div>
                <button class="button button-purple checkout-button" @click="submit">Godkend ordren og gå til
                    betaling
                </button>
                <div class="flex flex-col justify-center space-y-2 mt-4">
                    <div class="flex justify-center -mx-1 flex-wrap">
                        <img loading="lazy" class="w-20 bg-no-repeat m-1" src="/assets/payments/mobile-pay.svg"
                             alt="Mobile Pay logo">
                        <img loading="lazy" class="w-12 bg-no-repeat m-1" src="/assets/payments/visa.svg"
                             alt="Visa logo">
                        <img loading="lazy" class="w-10 bg-no-repeat m-1" src="/assets/payments/visa-electron.svg"
                             alt="Visa Electron logo">
                        <img loading="lazy" class="w-12 bg-no-repeat m-1" src="/assets/payments/mastercard.svg"
                             alt="Mastercard logo">
                        <img loading="lazy" class="w-12 bg-no-repeat m-1" src="/assets/payments/maestro.svg"
                             alt="Maestro logo">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductList from "./ProductList.vue";
    import Price from "./Price.vue";
    import { mapGetters } from "vuex";

    export default {
        computed: {
            ...mapGetters(["checkoutErrors", "checkoutForm"])
        },
        props: {
            inCart: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        components: {
            ProductList, Price
        },
        mounted() {
            this.$store.dispatch("getCartItems");

            this.emitter.on("set-form-state", (form) => {
                this.form = form;
            });
        },
        methods: {
            submit() {
                this.checkoutForm.submit()
                    .then(response => {
                        this.checkoutForm.reset();
                        window.location.href = response.data;
                    })
                    .catch(error => {
                        //alert('An error occurred.');
                    });
            }
        }
    };

</script>
