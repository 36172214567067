<template>
    <div v-if="tenantName">
        <div
            class="flex flex-col lg:flex-row justify-between w-full bg-[#F5F5F5] border border-[#E6E6E6] rounded-2xl">
            <div
                class="flex w-full xl:w-1/2 bg-[#FCFCFC] lg:border-r lg:border-[#E6E6E6] p-4 rounded-t-2xl lg:rounded-r-none lg:rounded-l-2xl">
                <div>
                    <div class="w-full space-y-12">
                        <!--Product creation fields-->
                        <div class="flex items-center flex-wrap gap-4 w-full">
                            <div class="flex flex-col xs:flex-row gap-4 w-full">
                                <!--CATEGORIES-->
                                <minitopolis-autocomplete
                                    :id="'category-' + product.id"
                                    :model-value="product.category"
                                    class="w-full"
                                    label="Kategori"
                                    :items="allCategoriesExcludingParentCategories(attributes.categories)"
                                    mode="single"
                                    :product="product"
                                    send-type="name"
                                    model="category"
                                    :edit-mode="editMode"
                                    :admin-page="adminPage"
                                    placeholder="Søg efter kategori ..."
                                    :disabled="(booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage"
                                    @set-category-id="setCategoryId"
                                    @set-parent-category-id="setParentCategoryId($event, product)"
                                    @added-category="addCategory"
                                    @clicked-item="selectedProductValue(product, (parentCategoriesNotIncludingBooks() || (currentParentCategory && (currentParentCategory.value === 132 || currentParentCategory.value === 378))) ? 'brand-' + product.id : 'title-' + product.id)"
                                    @clear-value="clearCategoryField"
                                />
                                <!--PARENT CATEGORIES-->
                                <div v-if="adminPage" class="relative w-full">
                                    <label class="form-label">Overkategori</label>
                                    <div v-if="currentParentCategory">
                                        <span class="text-sm">{{ currentParentCategory.label }}</span>
                                    </div>
                                    <div v-if="currentParentCategory && currentParentCategory.value === 132">
                                        <span class="text-sm">Andet</span>
                                    </div>
                                    <div v-if="currentParentCategory && currentParentCategory.value === 378">
                                        <span class="text-sm">Ukendt</span>
                                    </div>
                                    <div v-if="!currentParentCategory && !product.category">
                                        <span class="text-sm">Udfyld kategori</span>
                                    </div>
                                    <div v-if="!currentParentCategory && product.category">
                                        <select v-model="new_parent_category" class="text-xs form-select"
                                                @change="setNewParentCategory(product)">
                                            <option disabled selected value="null">-- Vælg overkategori --</option>
                                            <option
                                                v-for="category in allParentCategories(attributes.categories)"
                                                :key="category.value"
                                                :value="category.value"
                                            >
                                                {{ category.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col gap-4 w-full">
                                <div class="flex flex-col xs:flex-row gap-4 w-full">
                                    <!--BRANDS-->
                                    <minitopolis-autocomplete
                                        :id="'brand-' + product.id"
                                        :model-value="product.brand"
                                        class="w-full xs:w-1/2"
                                        label="Mærke"
                                        model="brand"
                                        :edit-mode="editMode"
                                        :items="attributes.brands"
                                        mode="single"
                                        :admin-page="adminPage"
                                        send-type="name"
                                        :placeholder="(editMode && !product.category) ||
                                            (!editMode && !product.category_id) ||
                                            (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                            (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Søg efter mærke ...'"
                                        :disabled="
                                            (editMode && !product.category) ||
                                            (!editMode && !product.category_id) ||
                                            (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                            (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)"
                                        @set-brand-id="setBrandId"
                                        @new-brand-created="newBrandCreated = $event"
                                        @clicked-item="selectedProductValue(product, (parentCategoriesEquipmentInteriorToysBooks() || (currentParentCategory && (currentParentCategory.value === 132 || currentParentCategory.value === 378))) ? 'color-' + product.id : 'size-' + product.id)"
                                        @clear-value="product.brand = null"
                                    />
                                    <!--SIZES-->
                                    <minitopolis-autocomplete
                                        :id="'size-' + product.id"
                                        :model-value="product.size"
                                        class="w-full xs:w-1/2"
                                        label="Størrelse"
                                        :items="attributes.sizes"
                                        mode="single"
                                        model="size"
                                        :edit-mode="editMode"
                                        :admin-page="adminPage"
                                        send-type="name"
                                        :placeholder="(editMode && !product.category) ||
                                            (!editMode && !product.category_id) ||
                                            parentCategoriesEquipmentInteriorToysBooks() ||
                                            (editMode && product.category_id === 132) ||
                                            (editMode && product.category_id === 378) ||
                                            (currentParentCategory && currentParentCategory.value === 132) ||
                                            (currentParentCategory && currentParentCategory.value === 378) ||
                                            (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Søg efter størrelse ...'"
                                        :disabled="
                                            (editMode && !product.category) ||
                                            (!editMode && !product.category_id) ||
                                            parentCategoriesEquipmentInteriorToysBooks() ||
                                            (editMode && product.category_id === 132) ||
                                            (editMode && product.category_id === 378) ||
                                            (currentParentCategory && currentParentCategory.value === 132) ||
                                            (currentParentCategory && currentParentCategory.value === 378) ||
                                            (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)
                                        "
                                        @set-size-id="setSizeId"
                                        @added-size="addSize"
                                        @clicked-item="selectedProductValue(product, 'color-' + product.id)"
                                        @clear-value="product.size = null"
                                    />
                                </div>

                                <!--COLORS-->
                                <minitopolis-autocomplete
                                    :id="'color-' + product.id"
                                    v-model="product.new_colors"
                                    class="w-full"
                                    label="Farve(r)"
                                    :items="attributes.colors"
                                    mode="tags"
                                    model="colors"
                                    :edit-mode="editMode"
                                    :admin-page="adminPage"
                                    send-type="name"
                                    :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) ||
                                                   (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                                   (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Søg efter farve ...'"
                                    :disabled="(editMode && !product.category) || (!editMode && !product.category_id) ||
                                                   (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                                   (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)"
                                    @add-color="addColor"
                                    @added-color="addNewColor"
                                    @remove-color="removeColor"
                                    @clear-value="product.color = null"
                                />
                                <!--PATTERNS-->
                                <minitopolis-autocomplete
                                    :id="'pattern-' + product.id"
                                    v-model="product.patterns"
                                    class="w-full"
                                    label="Mønster"
                                    :items="attributes.patterns"
                                    mode="tags"
                                    model="patterns"
                                    :edit-mode="editMode"
                                    :admin-page="adminPage"
                                    send-type="name"
                                    :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) ||
                                                (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                                (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Søg efter mønster ...'"
                                    :disabled="(editMode && !product.category) || (!editMode && !product.category_id) ||
                                                (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                                (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)"
                                    :optional="true"
                                    @add-pattern="addPattern"
                                    @added-pattern="addNewPattern"
                                    @remove-pattern="removePattern"
                                    @clear-value="product.pattern = null"
                                />

                                <div class="flex flex-col xs:flex-row gap-4 w-full">
                                    <div class="flex flex-col w-full xs:w-1/2">
                                        <label class="form-label" :class="{ 'opacity-25' : (editMode && !product.category) ||
                                                    (!editMode && !product.category_id) ||
                                                    parentCategoriesNotIncludingBooks() ||
                                                    (editMode && product.category_id === 132) ||
                                                    (editMode && product.category_id === 378) ||
                                                    (currentParentCategory && currentParentCategory === 132) ||
                                                    (currentParentCategory && currentParentCategory === 378) ||
                                                    (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage) }"
                                               :for="'title-' + product.id">Titel
                                            <span
                                                v-if="currentParentCategory &&  !parentCategoriesNotIncludingBooks()"
                                                class="-mt-1 text-red-500 font-bold">*</span>
                                        </label>
                                        <div class="flex h-[40px]">
                                            <input
                                                :id="'title-' + product.id"
                                                v-model="product.title"
                                                class="form-input"
                                                type="text"
                                                :placeholder="(editMode && !product.category) ||
                                                    (!editMode && !product.category_id) ||
                                                    parentCategoriesNotIncludingBooks() ||
                                                    (editMode && product.category_id === 132) ||
                                                    (editMode && product.category_id === 378) ||
                                                    (currentParentCategory && currentParentCategory === 132) ||
                                                    (currentParentCategory && currentParentCategory === 378) ||
                                                    (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Sæt titel ...'"
                                                autocomplete="off"
                                                :disabled="
                                                    (editMode && !product.category) ||
                                                    (!editMode && !product.category_id) ||
                                                    parentCategoriesNotIncludingBooks() ||
                                                    (editMode && product.category_id === 132) ||
                                                    (editMode && product.category_id === 378) ||
                                                    (currentParentCategory && currentParentCategory === 132) ||
                                                    (currentParentCategory && currentParentCategory === 378) ||
                                                    (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-full xs:w-1/2">
                                        <label class="form-label"
                                               :class="{ 'opacity-25' : (editMode && !product.category) || (!editMode && !product.category_id) || (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage) }"
                                               :for="'description-' + product.id"
                                        >Kort beskrivelse
                                            <span
                                                v-if="parentCategoriesClothingAccessoriesShoes()"
                                                class="text-xs font-normal text-gray-500">(valgfrit)
                                            </span>
                                            <span
                                                v-if="currentParentCategory && !parentCategoriesClothingAccessoriesShoes()"
                                                class="-mt-1 text-red-500 font-bold">*</span>
                                        </label>
                                        <div class="flex h-[40px]">
                                            <input
                                                :id="'description-' + product.id"
                                                v-model="product.description"
                                                class="form-input"
                                                type="text"
                                                :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) || (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Sæt beskrivelse ...'"
                                                autocomplete="off"
                                                :disabled="(editMode && !product.category) || (!editMode && !product.category_id) || (editMode && (booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col xs:flex-row gap-4 w-full">
                                <div class="flex flex-col w-full xs:w-1/2">
                                    <div class="flex items-center">
                                        <label class="form-label" :class="{ 'opacity-25' : !product.category }"
                                               :for="'price-' + product.id">
                                            Pris
                                            <span v-if="currentParentCategory"
                                                  class="-mt-1 text-red-500 font-bold">*</span>
                                        </label>
                                    </div>

                                    <div class="flex h-[40px]">
                                        <input :id="'price-' + product.id" v-model="product.price"
                                               class="form-input-defined" type="number" placeholder="0"
                                               autocomplete="off" :disabled="!product.category" />
                                        <span class="form-input-defined-box"
                                              :class="{ 'isDisabled' : !product.category }">kr.</span>
                                    </div>
                                </div>
                                <div class="flex flex-col w-full xs:w-1/2">
                                    <div class="form-label"
                                         :class="{ 'opacity-25' : disablePriceHelperButton(product) || !parentCategoriesNotIncludingBooks() }">
                                        Prishjælper
                                    </div>
                                    <div class="flex h-full">
                                        <button class="price-helper-button price-helper-button-left max-h-[40px]"
                                                :disabled="disablePriceHelperButton(product) || !parentCategoriesNotIncludingBooks()"
                                                @click="product.price = averagePrice.value / 100">
                                            <span class="flex flex-col justify-center leading-none">
                                                <span class="text-sm">{{ averagePrice.pretty }}</span>
                                                <span class="text-xxs font-medium">Gns.</span>
                                            </span>
                                        </button>
                                        <button class="price-helper-button price-helper-button-right max-h-[40px]"
                                                :disabled="disablePriceHelperButton(product) || !parentCategoriesNotIncludingBooks()"
                                                @click="product.price = maxPrice.value / 100">
                                            <span class="flex flex-col justify-center leading-none">
                                                <span class="text-sm">{{ maxPrice.pretty }}</span>
                                                <span class="text-xxs font-medium">Højeste</span>
                                            </span>
                                        </button>
                                    </div>
                                    <div v-if="total" class="flex">
                                        <span class="ml-1 mt-1 text-xs font-bold">({{ total
                                            }} solgte produkter)</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="product.price >= 200" class="flex flex-col w-full mt-4">
                                <div class="text-xs my-2">
                                    <span v-if="adminPage"
                                    >Ved produkter over 200 kr., så muliggøres en minimumspris. Hvis en kunde er
                                        interesseret, men ikke til prisen. Dette er ikke et krav, så den kan ignoreres
                                        hvis ikke det ikke findes relevant.
                                    </span>
                                    <span v-if="!adminPage">Ved produkter til eller over 200 kr., så muliggøres en
                                        minimumspris. Dette er intet krav. <span
                                            v-if="editMode">Lad 0 tallet stå hvis ikke minimumspris er noget for
                                            dig.</span></span>
                                </div>

                                <label class="form-label" :for="'min-price-' + product.id">Minimumspris</label>
                                <div class="flex">
                                    <input :id="'min_price-' + product.id" v-model="product.min_price"
                                           class="form-input-defined" type="number" placeholder="..." />
                                    <span class="form-input-defined-box">kr.</span>
                                </div>
                            </div>
                            <div v-if="!adminPage" class="flex flex-col w-full mt-4">
                                <div class="w-full">
                                    <label class="form-label" :for="'product_note-' + product.id">Produkt
                                        note</label>
                                    <div>
                                        <textarea
                                            :id="'product_note-' + product.id"
                                            v-model="product.product_note"
                                            rows="3"
                                            class="form-input"
                                            placeholder="Hvis du ikke kan finde det relevante i kategori/mærke/størrelse/farve, så vælg Andet og angiv beskrivelse her ..."
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-between flex-wrap gap-2 xs:gap-4">
                            <button
                                :class="{ 'admin-gray-button admin-small-button h-fit': adminPage, 'button button-gray smallest-button': !adminPage }"
                                @click="goBack">
                                <span
                                    class="hidden sm:inline-block lg:hidden 2xl:inline-block">Afslut
                                    oprettelsesmode</span>
                                <span class="inline-block sm:hidden lg:inline-block 2xl:hidden">Afslut</span>
                            </button>
                            <div class="flex items-center flex-wrap justify-end gap-2 w-full xs:w-auto">
                                <button v-if="!editMode"
                                        :class="{ 'admin-blue-button admin-small-button h-fit': adminPage, 'button button-blue smallest-button': !adminPage }"
                                        :disabled="!isValid(product)" @click="saveProduct(product)">
                                    <span>Opret produkt</span>
                                </button>
                                <div v-if="editMode"
                                     class="flex items-center flex-wrap gap-2 xs:gap-4 w-full xs:w-auto">
                                    <button
                                        :class="{ 'admin-blue-button admin-small-button h-fit': adminPage, 'button button-blue smallest-button': !adminPage }"
                                        :disabled="!isValid(product)"
                                        @click="updateProduct(product, goToNewProduct)">
                                        <span>Opdatér & afslut</span>
                                    </button>
                                    <div class="relative flex flex-col w-full xs:w-auto"
                                         :class="{ 'mt-2 xs:mt-0': !adminPage && !notReachedProductLimit() }">
                                        <button
                                            :class="{ 'admin-border-button admin-small-button h-fit': adminPage, 'button border-button smallest-button': !adminPage }"
                                            :disabled="!isValid(product) || !notReachedProductLimit() || ((booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)"
                                            @click="updateProduct(product, (goToNewProduct = true))"
                                        >
                                            <span>Opdatér & fortsæt</span>
                                        </button>
                                        <span v-if="!adminPage && !notReachedProductLimit()"
                                              class="absolute -top-4 left-0 right-0 mx-auto w-fit text-xxs font-bold text-topolis-purple-600">Du
                                            kan ikke oprette flere</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="adminPage" class="relative w-full">
                            <span class="form-label">Placering</span>
                            <div class="flex justify-between w-full gap-2">
                                <label class="placement-radio-card"
                                       :class="{ active: product.marked_for_webshop === false || product.marked_for_webshop === 0 }">
                                    <input id="radio-card-1" v-model="product.marked_for_webshop"
                                           type="radio" name="radio-card" :value="false" class="hidden" />
                                    <span id="radio-card-1-label" class="text-center">Kun i butik</span>
                                </label>

                                <label class="placement-radio-card"
                                       :class="{ active: product.marked_for_webshop === true || product.marked_for_webshop === 1 }">
                                    <input id="radio-card-2" v-model="product.marked_for_webshop"
                                           type="radio" name="radio-card" :value="true" class="hidden" />
                                    <span id="radio-card-2-label" class="text-center">På webshop</span>
                                </label>
                            </div>
                        </div>
                        <div v-if="product.marked_for_webshop" class="space-y-8">
                            <div class="space-y-4">
                                <div class="flex flex-col xs:flex-row gap-4 w-full">
                                    <div class="flex flex-col w-full xs:w-1/2">
                                        <label class="form-label" :for="'pretty_name-' + product.id">Navn
                                            (Webshop)</label>
                                        <div class="flex">
                                            <input :id="'pretty_name-' + product.id" v-model="product.pretty_name"
                                                   class="form-input" type="text" placeholder="..." />
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-full xs:w-1/2">
                                        <label class="form-label" :for="'condition-' + product.id">Stand</label>
                                        <div class="relative w-full">
                                            <div class="flex rounded-lg divide-x divide-office-black w-full">
                                                <div class="relative z-0 flex rounded-lg w-full">
                                                    <div
                                                        class="relative flex items-center py-2 px-3 border border-gray-300 border-r-none rounded-l-lg w-full">
                                                        <svg v-if="product.condition"
                                                             class="h-5 w-5 text-office-sky-900"
                                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                             fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clip-rule="evenodd" />
                                                        </svg>
                                                        <span
                                                            class="inline-flex ml-1 xs:ml-2.5 text-xs xs:text-sm font-medium text-gray-400 truncate"
                                                            :class="{ 'text-black': product.condition }">{{ condition
                                                            }}</span>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        class="relative inline-flex items-center bg-office-black p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-office-gray-800 focus:outline-none"
                                                        @click="showConditionDropdown = !showConditionDropdown"
                                                    >
                                                        <svg class="h-5 w-5 text-white"
                                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                             fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd"
                                                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                                  clip-rule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>

                                            <ul v-if="showConditionDropdown"
                                                class="origin-top-right absolute z-10 right-0 mt-2 w-full rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 focus:outline-none"
                                                tabindex="-1">
                                                <li
                                                    class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                                    role="option"
                                                    :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'new' }"
                                                    @click="selectCondition('new')"
                                                >
                                                    <div class="flex-col">
                                                        <div class="flex items-center justify-between">
                                                            <span class="font-medium">Nyt</span>
                                                            <span class="text-office-sky-900">
                                                                <svg v-if="product.condition === 'new'" class="h-5 w-5"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 20 20" fill="currentColor"
                                                                     aria-hidden="true">
                                                                    <path fill-rule="evenodd"
                                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                          clip-rule="evenodd" />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <p class="text-gray-500 mt-2">Produktet er helt nyt og
                                                            aldrig
                                                            blevet brugt.</p>
                                                    </div>
                                                </li>
                                                <li
                                                    class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                                    :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'almost-new' }"
                                                    role="option"
                                                    @click="selectCondition('almost-new')"
                                                >
                                                    <div class="flex flex-col">
                                                        <div class="flex items-center justify-between">
                                                            <span class="font-medium">Næsten nyt</span>
                                                            <span class="text-office-sky-900">
                                                                <svg v-if="product.condition === 'almost-new'"
                                                                     class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 20 20" fill="currentColor"
                                                                     aria-hidden="true">
                                                                    <path fill-rule="evenodd"
                                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                          clip-rule="evenodd" />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <p class="text-gray-500 mt-2">Produktet er brugt, men er
                                                            næsten
                                                            som ny.</p>
                                                    </div>
                                                </li>
                                                <li
                                                    class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                                    :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'good-but-used' }"
                                                    role="option"
                                                    @click="selectCondition('good-but-used')"
                                                >
                                                    <div class="flex flex-col">
                                                        <div class="flex items-center justify-between">
                                                            <span class="font-medium">Godt, men brugt</span>
                                                            <span class="text-office-sky-900">
                                                                <svg v-if="product.condition === 'good-but-used'"
                                                                     class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 20 20" fill="currentColor"
                                                                     aria-hidden="true">
                                                                    <path fill-rule="evenodd"
                                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                          clip-rule="evenodd" />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <p class="text-gray-500 mt-2">Produktet er brugt og det kan
                                                            ses,
                                                            men det kan stadig bruges.</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!--                                    <div class="flex flex-col w-1/2">-->
                                    <!--                                        <label class="form-label" :for="'min-price-' + product.id">Minimumspris</label>-->
                                    <!--                                        <div class="flex">-->
                                    <!--                                            <input :id="'min_price-' + product.id" v-model="product.min_price" class="form-input-defined" type="text" placeholder="0" />-->
                                    <!--                                            <span class="form-input-defined-box">kr.</span>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                </div>
                                <div class="flex flex-col xs:flex-row gap-4 w-full">
                                    <div class="relative flex flex-col w-full xs:w-1/2">
                                        <span class="form-label">Mulighed for forsendelse</span>
                                        <div class="flex justify-between w-full gap-2">
                                            <label class="placement-radio-card"
                                                   :class="{ active: product.is_shippable === false || product.is_shippable === 0 }">
                                                <input id="radio-card-1" v-model="product.is_shippable" type="radio"
                                                       name="radio-card" :value="false" class="hidden" />
                                                <span id="radio-card-1-label"
                                                      class="text-center">Kun klik og hent</span>
                                            </label>

                                            <label class="placement-radio-card"
                                                   :class="{ active: product.is_shippable === true || product.is_shippable === 1 }">
                                                <input id="radio-card-2" v-model="product.is_shippable" type="radio"
                                                       name="radio-card" :value="true" class="hidden" />
                                                <span id="radio-card-2-label"
                                                      class="text-center">Kan afsendes</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-full xs:w-1/2">
                                        <label class="form-label" :for="'retail-price-' + product.id">Oprindelig
                                            pris
                                            (ny pris)</label>
                                        <div class="flex">
                                            <input :id="'retail_price-' + product.id" v-model="product.retail_price"
                                                   class="form-input-defined" type="text" placeholder="0" />
                                            <span class="form-input-defined-box">kr.</span>
                                        </div>
                                    </div>
                                </div>
                                <!--Tags-->
                                <minitopolis-autocomplete
                                    :id="'tags-' + product.id"
                                    v-model="product.tags"
                                    class="w-full"
                                    label="Tags"
                                    :items="attributes.tags"
                                    mode="tags"
                                    model="tags"
                                    :edit-mode="editMode"
                                    :admin-page="adminPage"
                                    send-type="name"
                                    :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) && !adminPage ? 'Deaktiveret' : 'Søg efter tag ...'"
                                    :disabled="(editMode && !product.category) || (!editMode && !product.category_id) && !adminPage"
                                    :optional="true"
                                    @add-tag="addTag"
                                    @added-tag="addNewTag"
                                    @remove-tag="removeTag"
                                    @clear-value="product.tag = null"
                                />
                                <div class="flex flex-col space-y-8 w-full mb-4">
                                    <div class="w-full">
                                        <label class="form-label"
                                               :for="'pretty_description-' + product.id">Beskrivelse (Webshop)</label>
                                        <div>
                                            <textarea :id="'pretty_description-' + product.id"
                                                      v-model="product.pretty_description" rows="3" class="form-input"
                                                      placeholder="..."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <product-preview :condition="condition" :current-parent-category="currentParentCategory"
                             :is-valid="isValid(product)" :edit-mode="editMode"
                             :is-webshop-valid="isWebshopValid(product)" :product="product" />
        </div>
        <drawer name="create-guide" deactivate-outside-click drawer-header="Oprettelsesguide"
                :product="product" :current-parent-category="currentParentCategory"
                guide :admin-page="adminPage" :direction-left="!adminPage" :edit-mode="editMode"></drawer>
        <drawer name="size-guide" :edit-mode="editMode" deactivate-outside-click drawer-header="Størrelsesguide"
                :current-parent-category="currentParentCategory" guide :admin-page="adminPage"
                :direction-left="!adminPage"></drawer>
        <drawer name="color-guide" :items="attributes.colors" :colors="product.new_colors"
                deactivate-outside-click
                drawer-header="Farve guide"
                guide
                :admin-page="adminPage" :edit-mode="editMode" @add-color="addColor" @remove-color="removeColor"
                :direction-left="!adminPage"></drawer>
        <drawer name="pattern-guide" :items="attributes.patterns" :patterns="product.patterns"
                deactivate-outside-click
                drawer-header="Mønster guide"
                guide
                :admin-page="adminPage" :edit-mode="editMode" @add-pattern="addPattern" @remove-pattern="removePattern"
                :direction-left="!adminPage"></drawer>
        <drawer name="tag-guide" :items="attributes.tags" :tags="product.tags"
                deactivate-outside-click
                drawer-header="Tag guide"
                guide
                :admin-page="adminPage" :edit-mode="editMode" @add-tag="addTag" @remove-tag="removeTag"
                :direction-left="!adminPage"></drawer>
    </div>
</template>

<script>
    import axios from "axios";
    import ProductPreview from "./ProductPreview.vue";
    import Drawer from "./Drawer.vue";
    import LoadingSpinner from "./LoadingSpinner.vue";

    const CATEGORY_ANDET = 132;
    const CATEGORY_FODTOJ = 621;
    const CATEGORY_UDSTYR = 144;
    const CATEGORY_INTERIOR = 620;
    const CATEGORY_LEGETOJ = 213;
    const CATEGORY_TOJ = 351;
    const CATEGORY_OVERTOJ = 221;
    const CATEGORY_ACCESSORIES = 376;
    const CATEGORY_BOGER_SPIL = 377;
    const CATEGORY_UKENDT = 378;

    export default {
        components: { LoadingSpinner, Drawer, ProductPreview },
        props: {
            booking: {},
            endpoint: {},
            attributes: {},
            editProduct: {},
            editVariant: {},
            adminPage: {},
            notAllowedToCreateMore: {
                type: Boolean,
                default: true,
                required: false
            },
            editMode: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        data() {
            return {
                product: {
                    name: null,
                    category: null,
                    size: null,
                    new_colors: [],
                    patterns: [],
                    brand: null,
                    price: null,
                    marked_for_webshop: false,
                    title: null,
                    description: null,
                    pretty_name: null,
                    pretty_description: null,
                    condition: null,
                    retail_price: null,
                    min_price: null,
                    is_shippable: true,
                    product_note: null,
                    tags: []
                },
                averagePrice: {
                    value: null,
                    pretty: null
                },
                maxPrice: {
                    value: null,
                    pretty: null
                },
                currentParentCategory: null,
                new_parent_category: null,
                total: null,
                tenantName: null,
                parentCategoryIds: [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_LEGETOJ, CATEGORY_UDSTYR, CATEGORY_INTERIOR, CATEGORY_FODTOJ, CATEGORY_ACCESSORIES, CATEGORY_BOGER_SPIL],
                otherCategoryId: CATEGORY_ANDET,
                unknownCategoryId: CATEGORY_UKENDT,
                showConditionDropdown: false,
                currentTab: 1,
                goToNewProduct: false,
                loading: false,
                newCategoryCreated: false,
                newBrandCreated: false,
                isBrandNew: false,
                newSizeCreated: false,
                newColorCreated: false,
                newPatternCreated: false,
                newTagCreated: false
            };
        },
        watch: {
            newBrandCreated(newValue) {
                this.isBrandNew = newValue;
            }
        },
        computed: {
            condition() {
                if (this.product) {
                    if (this.product.condition === "new") {
                        return "Ny";
                    }
                    if (this.product.condition === "almost-new") {
                        return "Næsten ny";
                    }
                    if (this.product.condition === "good-but-used") {
                        return "God, men brugt";
                    }

                    return "-- Vælg stand --";
                }
            }
        },
        mounted() {
            this.loading = true;
            this.$nextTick(() => {
                this.tenantName = "egaa_"; // window.tenantName;
                this.loading = false;
            });
            // Tabs are used in the drawer component
            this.emitter.on("change-current-tab", (value) => {
                this.currentTab = value;
                window.localStorage.setItem("current-tab", value);
            });
            if (this.editMode) {
                this.product.id = this.editProduct.id;
                // this.product.category_id = this.editProduct.category_id;
                // this.product.category = this.editProduct.category;
                this.product.name = this.editProduct.name;
                this.product.title = this.editProduct.title;
                // this.product.brand = this.editProduct.brand;
                this.product.pretty_name = this.editProduct.pretty_name;
                // this.product.size = this.editVariant.size;
                // this.product.color = this.editProduct.attributes.color;
                // this.product.new_colors = this.editVariant.colors;
                // this.product.patterns = this.editVariant.patterns;
                this.product.price = this.editVariant.price.value.amount / 100;
                this.product.marked_for_webshop = this.editVariant.marked_for_webshop;
                this.product.description = this.editVariant.description;
                this.product.pretty_description = this.editVariant.pretty_description;
                this.product.product_note = this.editVariant.product_note;
                this.product.condition = this.editVariant.condition;
                this.product.retail_price = this.editVariant.retail_price.value.amount / 100;
                this.product.min_price = this.editVariant.min_price.value.amount / 100;
                this.product.is_shippable = this.editVariant.is_shippable;

                this.currentParentCategory = this.findParentCategory(this.editProduct.category.parent_id);
                this.setCategoryId({
                    product: this.editProduct
                });

                if (!this.isBrandNew) {
                    if (this.parentCategoriesNotIncludingBooks()) {
                        this.setBrandId({
                            product: this.editProduct
                        });
                    }
                }

                if (this.parentCategoriesClothingAccessoriesShoes()) {
                    this.setSizeId({
                        product: this.editVariant
                    });
                }

                this.setColors({
                    product: this.editVariant
                });
                this.setPatterns({
                    product: this.editVariant
                });
                this.setTags({
                    product: this.editVariant
                });
                this.setParentCategoryId({ parent_id: this.editProduct.category.parent_id, product: this.editProduct });
            }
            this.notReachedProductLimit();
        },
        methods: {
            selectedProductValue(product, next) {
                setTimeout(() => {
                    if (typeof next !== "undefined" && next) {
                        document.querySelector("#" + next).focus();
                    }
                    if (product.category && product.brand) {
                        product.pretty_name = product.brand.label + " " + product.category.label;

                        axios
                            .post("/price-calculator", {
                                category: product.category_id,
                                brand: product.brand.label
                            })
                            .then((response) => {
                                this.total = response.data.total;
                                if (this.total < 2) {
                                    this.averagePrice = {
                                        value: null,
                                        pretty: null
                                    };
                                    this.maxPrice = {
                                        value: null,
                                        pretty: null
                                    };
                                } else {
                                    this.$nextTick(() => {
                                        this.averagePrice = response.data.averagePrice;
                                        this.maxPrice = response.data.maxPrice;
                                    });
                                }

                                if (!this.editProduct && this.total > 3) {
                                    // TODO: only set if price calculator is active
                                    product.price = response.data.averagePrice.value / 100;
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                }, 50);
            },
            setParentCategoryId(eventData) {
                if (eventData.parent_id) {
                    this.currentParentCategory = this.findParentCategory(eventData.parent_id);
                } else {
                    this.currentParentCategory = null;
                }

                if (eventData.value === CATEGORY_ANDET || eventData.value === CATEGORY_UKENDT) {
                    console.log(eventData);
                    this.currentParentCategory = eventData;
                }
            },
            findParentCategory(parentId) {
                return this.attributes.categories.find((cat) => {
                    return cat.value === parentId;
                });
            },
            setNewParentCategory(product) {
                axios
                    .post("/admin/produkter/kategorier/" + product.category_id + "/opdater-parent", {
                        parent_id: this.new_parent_category
                    })
                    .then((response) => {
                        this.setParentCategoryId(response.data.category);
                    });
            },
            addCategory(category) {
                this.attributes.categories.push(category);
            },
            addBrand(brand) {
                this.attributes.brands.push(brand);
            },
            addSize(size) {
                let newSize = {
                    value: size.id,
                    label: size.name
                };
                this.attributes.sizes.push(newSize);
                console.log("newSize", newSize);
                this.setSizeId(newSize);
            },
            addNewColor(color) {
                this.attributes.colors.push(color);
            },
            addNewPattern(pattern) {
                this.attributes.patterns.push(pattern);
            },
            addNewTag(tag) {
                this.attributes.tags.push(tag);
            },
            setBrandId(payload) {
                if (this.editMode) {
                    console.log("setBrandId", payload);
                    if (payload.label && this.product.brand !== payload.label) {
                        this.product.brand = {
                            label: payload.label,
                            value: payload.value
                        };
                    } else {
                        // Otherwise, use the brand from the product
                        this.product.brand = {
                            label: payload.product.brand.name,
                            value: payload.product.brand.id
                        };
                    }
                } else {
                    this.product.brand = {
                        label: payload.label,
                        value: payload.value
                    };
                }
            },
            setSizeId(payload) {
                if (this.editMode) {
                    if (payload.label && this.product.size !== payload.label) {
                        this.product.size = {
                            label: payload.label,
                            value: payload.value
                        };
                    } else {
                        // Otherwise, use the size from the product's variant
                        this.product.size = {
                            label: payload.product.size.name,
                            value: payload.product.size.id
                        };
                    }
                } else {
                    this.product.size = {
                        label: payload.label,
                        value: payload.value
                    };
                }
            },
            addColor(payload) {
                if (this.editMode) {
                    this.product.new_colors.push(payload);
                } else {
                    if (!this.product.new_colors) {
                        this.product.new_colors = [];
                    }
                    this.product.new_colors.push(payload);
                }
                console.log("add colors array", this.product.new_colors);
            },
            removeColor(payload) {
                console.log("remove it", payload);
                let index = this.product.new_colors.findIndex(color => color.value === payload.value);

                if (index !== -1) {
                    console.log("index?", index);
                    console.log("index? beofr", this.product.new_colors);
                    this.product.new_colors.splice(index, 1);
                }
                console.log("delete colors array", this.product.new_colors);
            },
            setColors(payload) {
                if (this.editMode) {
                    this.product.new_colors = payload.product.colors.map(color => {
                        return {
                            label: color.name,
                            value: color.id,
                            hex: color.hex_code
                        };
                    });
                } else {
                    this.product.new_colors = {
                        label: payload.label,
                        value: payload.value,
                        hex: payload.hex_code
                    };
                }
            },
            setPatterns(payload) {
                if (this.editMode) {
                    this.product.patterns = payload.product.patterns.map(pattern => {
                        return {
                            label: pattern.name,
                            value: pattern.id
                        };
                    });
                } else {
                    this.product.patterns = {
                        label: payload.label,
                        value: payload.value
                    };
                }
            },
            addPattern(payload) {
                if (this.editMode) {
                    this.product.patterns.push(payload);
                } else {
                    if (!this.product.patterns) {
                        this.product.patterns = [];
                    }
                    this.product.patterns.push(payload);
                }
            },
            removePattern(payload) {
                let index = this.product.patterns.findIndex(pattern => pattern.value === payload.value);

                if (index !== -1) {
                    this.product.patterns.splice(index, 1);
                }
            },
            setTags(payload) {
                if (this.editMode) {
                    this.product.tags = payload.product.tags.map(tag => {
                        return {
                            label: tag.name,
                            value: tag.id
                        };
                    });
                } else {
                    this.product.tags = {
                        label: payload.label,
                        value: payload.value
                    };
                }
            },
            addTag(payload) {
                console.log(payload);
                if (this.editMode) {
                    this.product.tags.push(payload);
                } else {
                    if (!this.product.tags) {
                        this.product.tags = [];
                    }
                    this.product.tags.push(payload);
                }
            },
            removeTag(payload) {
                console.log("hello", payload);
                let index = this.product.tags.findIndex(tag => tag.value === payload.value);

                if (index !== -1) {
                    this.product.tags.splice(index, 1);
                }
            },
            setCategoryId(payload) {
                if (this.editMode) {
                    if (payload.label && this.product.category !== payload.label) {
                        this.product.category = {
                            label: payload.label,
                            value: payload.value
                        };
                        this.product.category_id = payload.value;
                    } else {
                        this.product.category = {
                            label: payload.product.category.name,
                            value: payload.product.category.id
                        };
                        this.product.category_id = payload.product.category.id;
                    }
                } else {
                    // If not in edit mode
                    this.product.category = {
                        label: payload.label,
                        value: payload.value
                    };
                    this.product.category_id = payload.value;
                }
            },
            notReachedProductLimit() {
                return this.notAllowedToCreateMore;
            },
            parentCategoriesNotIncludingBooks() {
                if (!this.currentParentCategory) return false;

                // fields to deactivated if book category is selected. Therefore, all other categories than the book category is deactivated
                const deactivatedCategories = [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_LEGETOJ, CATEGORY_UDSTYR, CATEGORY_INTERIOR, CATEGORY_FODTOJ, CATEGORY_ACCESSORIES];
                return deactivatedCategories.includes(this.currentParentCategory.value);
            },
            parentCategoriesClothingShoesAccessoriesBooks() {
                if (!this.currentParentCategory) return false;

                // fields to deactivated if shoe, clothing or accessories categories are selected. Therefore, all other categories than the these three categories is deactivated
                const deactivatedCategories = [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_FODTOJ, CATEGORY_ACCESSORIES, CATEGORY_BOGER_SPIL];
                return deactivatedCategories.includes(this.currentParentCategory.value);
            },
            parentCategoriesEquipmentInteriorToysBooks() {
                if (!this.currentParentCategory) return false;

                // fields to deactivated if equipment, interior or toys categories are selected. Therefore, all other categories than the these three categories is deactivated
                const deactivatedCategories = [CATEGORY_LEGETOJ, CATEGORY_UDSTYR, CATEGORY_INTERIOR, CATEGORY_BOGER_SPIL];
                return deactivatedCategories.includes(this.currentParentCategory.value);
            },
            parentCategoriesClothingAccessoriesShoes() {
                if (!this.currentParentCategory) return false;

                // fields to deactivated if equipment, interior or toys categories are selected. Therefore, all other categories than the these three categories is deactivated
                const deactivatedCategories = [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_ACCESSORIES, CATEGORY_FODTOJ];
                return deactivatedCategories.includes(this.currentParentCategory.value);
            },
            disablePriceHelperButton(product) {
                return !product.category && !product.brand;
            },
            isValid(product) {
                const noSizeCategories = [CATEGORY_UDSTYR, CATEGORY_INTERIOR, CATEGORY_LEGETOJ];
                const noDescCategories = [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_ACCESSORIES, CATEGORY_FODTOJ];

                if (this.currentParentCategory) {
                    if (noDescCategories.includes(this.currentParentCategory.value)) {
                        return product.category && product.brand && product.new_colors && product.size && product.price;
                    }
                    if (noSizeCategories.includes(this.currentParentCategory.value)) {
                        return product.category && product.brand && product.new_colors && product.description && product.price;
                    }
                    if (CATEGORY_BOGER_SPIL === this.currentParentCategory.value) {
                        return product.category && product.title && product.description && product.price;
                    }
                    if (this.otherCategoryId === this.currentParentCategory) {
                        return product.category && product.brand && product.new_colors && product.description && product.price;
                    }
                    if (this.unknownCategoryId === this.currentParentCategory) {
                        return product.category && product.brand && product.new_colors && product.description && product.price;
                    }
                }

                if (this.editMode && !this.currentParentCategory) {
                    if (this.otherCategoryId === this.editProduct.category_id) {
                        return product.category && product.brand && product.new_colors && product.description && product.price;
                    }
                    if (this.unknownCategoryId === this.editProduct.category_id) {
                        return product.category && product.brand && product.new_colors && product.description && product.price;
                    }
                }
            },
            isWebshopValid(product) {
                return product.pretty_name && product.pretty_description;
            },
            updateProduct(product, goToNewProduct) {
                axios
                    .patch(this.endpoint, {
                        category_id: product.category.value,
                        product_id: this.product.uuid,
                        brand: product.brand,
                        price: product.price,
                        booking_id: this.booking.id,
                        parent_id: this.currentParentCategory.value,
                        size: product.size,
                        new_colors: product.new_colors,
                        patterns: product.patterns,
                        tags: product.tags,
                        title: product.title,
                        description: product.description,
                        name: product.name,
                        pretty_name: product.pretty_name,
                        pretty_description: product.pretty_description,
                        product_note: product.product_note,
                        condition: product.condition,
                        retail_price: product.retail_price,
                        min_price: product.min_price,
                        marked_for_webshop: product.marked_for_webshop,
                        is_shippable: product.is_shippable
                    })
                    .then((response) => {
                        this.emitter.emit("show-toast", {
                            message: "Varen er blevet opdateret",
                            type: "success"
                        });
                        if (this.adminPage) {
                            if (goToNewProduct) {
                                setTimeout(() => {
                                    window.location.href = "/admin/produkter/" + this.booking.uuid + "/opret";
                                }, 500);
                            } else {
                                setTimeout(() => {
                                    window.location.href = "/admin/bookinger/" + this.booking.uuid + "/produkter";
                                }, 500);
                            }
                        } else {
                            if (goToNewProduct) {
                                setTimeout(() => {
                                    window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/opret-produkt";
                                }, 500);
                            } else {
                                setTimeout(() => {
                                    window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/dine-produkter";
                                }, 500);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.emitter.emit("show-toast", {
                            message: "Varen blev ikke opdateret",
                            type: "error"
                        });
                    });
            },
            saveProduct(product, fromDuplicate = false) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(this.endpoint, {
                            category_id: product.category.value,
                            brand: product.brand,
                            price: product.price,
                            booking_id: this.booking.id,
                            parent_id: this.currentParentCategory.value,
                            size: product.size,
                            new_colors: product.new_colors,
                            patterns: product.patterns,
                            tags: product.tags,
                            title: product.title,
                            description: product.description,
                            name: product.name,
                            pretty_name: product.pretty_name,
                            pretty_description: product.pretty_description,
                            product_note: product.product_note,
                            condition: product.condition,
                            retail_price: product.retail_price ? product.retail_price : 0,
                            min_price: product.min_price ? product.min_price : 0,
                            marked_for_webshop: product.marked_for_webshop,
                            is_shippable: product.is_shippable
                        })
                        .then((response) => {
                            product.id = response.data.product.id;
                            product.uuid = response.data.product.uuid;
                            product.name = response.data.product.name;

                            // Clear the price buttons and total when saving a product
                            this.averagePrice = {
                                value: null,
                                pretty: null
                            };
                            this.maxPrice = {
                                value: null,
                                pretty: null
                            };
                            this.total = null;

                            if (!fromDuplicate) {
                                this.emitter.emit("show-toast", {
                                    message: "Varen er blevet oprettet",
                                    type: "success"
                                });
                                if (!this.adminPage && !this.notReachedProductLimit()) {
                                    setTimeout(() => {
                                        window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/dine-produkter";
                                    }, 500);
                                } else {
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 500);
                                }
                            }
                            resolve();
                        })
                        .catch((error) => {
                            console.log(error);

                            this.emitter.emit("show-toast", {
                                message: "Varen blev ikke oprettet",
                                type: "error"
                            });

                            reject();
                        });
                });
            },
            allCategoriesExcludingParentCategories(categories) {
                if (this.editMode) {
                    // let currentCategoryId = this.product.category.value;
                    let currentCategoryId = this.editProduct.category.id;
                    let parentCategoryId = null;

                    for (let category of categories) {
                        if (category.value === currentCategoryId) {
                            parentCategoryId = category.parent.id; // Assuming the parent is an id, not an object.
                            break;
                        }
                    }

                    if (parentCategoryId) {
                        // Filter categories which have the same 'parent.id'
                        let sameParentCategories = categories.filter(category => category.parent && category.parent.id === parentCategoryId);

                        return sameParentCategories;
                    }
                } else {
                    return categories.filter((item) => !this.parentCategoryIds.includes(item.value));
                }
            },
            allParentCategories(categories) {
                return categories.filter((item) => this.parentCategoryIds.includes(item.value));
            },
            selectCondition(condition) {
                this.product.condition = condition;
                this.showConditionDropdown = false;
            },
            clearCategoryField(payload) {
                this.product.category = null;
                this.product.category_id = null;
                if (!this.editMode) {
                    this.currentParentCategory = null;
                }
            },
            goBack() {
                if (this.editMode) {
                    history.back();
                } else {
                    if (this.adminPage) {
                        setTimeout(() => {
                            window.location.href = "/admin/bookinger/" + this.booking.uuid + "/produkter";
                        }, 500);
                    } else {
                        setTimeout(() => {
                            window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/dine-produkter";
                        }, 500);
                    }
                }
            }
        }
    };
</script>
