<template>
    <div class="relative">
        <button @click="openDrawer('search')"
                class="menu-item relative flex items-center"
                :class="{ 'menu-item-frontpage' : frontPage }">
            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6" viewBox="0 0 16 16">
                <path d="M12.667,6.333A6.333,6.333,0,1,1,6.333,0,6.333,6.333,0,0,1,12.667,6.333Z"
                      transform="translate(1.333 1.333)" fill="fillColor" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1" />
                <path d="M1.333,1.333,0,0" transform="translate(13.333 13.333)" fill="fillColor" stroke="currentColor"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </svg>
        </button>
        <drawer name="search" drawer-header="Søg efter varer" :search="true"></drawer>
    </div>
</template>

<script>
    import Drawer from "../Pages/Common/Drawer.vue";

    export default {
        components: { Drawer },
        props: {
            frontPage: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                isOpen: false,
                searchQuery: ""
            };
        },
        methods: {
            openDrawer(name) {
                this.emitter.emit("open-drawer", name);
            }
        }
    };
</script>

<style>

</style>