<template>
    <div class="flex flex-col">
        <div class="w-full mx-auto text-left space-y-8">
            <div v-if="checkoutForm.hasErrors">
                <div class="rounded-md bg-red-50 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-red-800">Hov, der er fejl i udfyldelsen.</h3>
<!--                            <div class="mt-2 text-sm text-red-700">-->
<!--                                <ul role="list" class="list-disc space-y-1 pl-5">-->
<!--                                    <li>Your password must be at least 8 characters</li>-->
<!--                                    <li>Your password must include at least one pro wrestling finishing move</li>-->
<!--                                </ul>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <form class="flex flex-col space-y-2">
                <h4>Din adresse</h4>
                <div class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full">
                    <!--Full name-->
                    <div class="basis-[48%] flex-1 h-auto">
                        <label for="name" class="block text-sm font-bold text-topolis-blue-700">Navn (husk efternavn)</label>
                        <div class="mt-1">
                            <input id="name" v-model="checkoutForm.name" name="name" type="text" placeholder="..." autocomplete="name" data-cy="login-form-name-input" required class="form-input" @change="checkoutForm.validate('name')" />
                        </div>
                        <div v-if="checkoutForm.invalid('name')" class="mt-1 text-xs text-red-500 font-semibold">
                            {{ checkoutForm.errors.name }}
                        </div>
                    </div>
                    <!--Address-->
                    <div class="basis-[48%] flex-1 h-auto">
                        <label for="address" class="block text-sm font-bold text-topolis-blue-700">Adresse</label>
                        <div class="mt-1">
                            <input id="address" v-model="checkoutForm.address" name="address" type="text" placeholder="..." autocomplete="address" data-cy="login-form-address-input" required class="form-input" @change="checkoutForm.validate('address')" />
                        </div>
                        <div v-if="checkoutForm.invalid('address')" class="mt-1 text-xs text-red-500 font-semibold">
                            {{ checkoutForm.errors.address }}
                        </div>
                    </div>
                    <!--ZIP-->
                    <div class="basis-[48%] flex-1 h-auto">
                        <label for="zip" class="block text-sm font-bold text-topolis-blue-700">Postnummer</label>
                        <div class="mt-1">
                            <input id="zip" @input="validateZip" v-model="checkoutForm.zip" name="zip" type="text" placeholder="..." autocomplete="postal-code" data-cy="login-form-zip-input" required class="form-input" @change="checkoutForm.validate('zip')" />
                        </div>
                        <div v-if="checkoutForm.invalid('zip')" class="mt-1 text-xs text-red-500 font-semibold">
                            {{ checkoutForm.errors.zip }}
                        </div>
                    </div>
                    <!--City-->
                    <div class="basis-[48%] flex-1 h-auto">
                        <label for="city" class="block text-sm font-bold text-topolis-blue-700">By</label>
                        <div class="mt-1">
                            <input id="city" v-model="checkoutForm.city" name="city" type="text" placeholder="..." autocomplete="address-level2" data-cy="login-form-city-input" required class="form-input" @change="checkoutForm.validate('city')" />
                        </div>
                        <div v-if="checkoutForm.invalid('city')" class="mt-1 text-xs text-red-500 font-semibold">
                            {{ checkoutForm.errors.city }}
                        </div>
                    </div>
                    <!--Email-->
                    <div class="basis-[48%] flex-1 h-auto">
                        <label for="email" class="block text-sm font-bold text-topolis-blue-700">Email</label>
                        <div class="mt-1">
                            <input
                                id="email"
                                v-model="checkoutForm.email"
                                :class="{ valid: validateEmail, invalid: !validateEmail }"
                                name="email"
                                type="email"
                                placeholder="..."
                                autocomplete="email"
                                data-cy="login-form-email-input"
                                required
                                class="form-input"
                                @change="checkoutForm.validate('email')"
                            />
                        </div>

                        <div v-if="checkoutForm.invalid('email')" class="mt-1 text-xs text-red-500 font-semibold">
                            {{ checkoutForm.errors.email }}
                        </div>
                    </div>
                    <!--Repeat Email-->
                    <div class="basis-[48%] flex-1 h-auto">
                        <label for="confirm_email" class="block text-sm font-bold text-topolis-blue-700">Bekræft email</label>
                        <div class="mt-1">
                            <input
                                id="confirm_email"
                                v-model="checkoutForm.confirm_email"
                                :class="{ valid: confirmEmailsMatching, invalid: !confirmEmailsMatching }"
                                name="confirm_email"
                                type="email"
                                placeholder="..."
                                autocomplete="off"
                                data-cy="login-form-confirm-email-input"
                                required
                                class="form-input"
                                @change="checkoutForm.validate('confirm_email')"
                            />
                        </div>

                        <div v-if="checkoutForm.invalid('confirm_email')" class="mt-1 text-xs text-red-500 font-semibold">
                            {{ checkoutForm.errors.confirm_email }}
                        </div>
                    </div>
                    <!--Phone-->
                    <div class="basis-[48%] flex-1 h-auto w-full">
                        <label for="phone" class="block text-sm font-bold text-topolis-blue-700">Telefonnummer</label>
                        <div class="mt-1 relative flex items-center">
                        <span class="form-input-defined-phone mr-2">
                            <img src="https://flagcdn.com/24x18/dk.png" srcset="https://flagcdn.com/48x36/dk.png 2x, https://flagcdn.com/72x54/dk.png 3x" width="24" height="18" alt="Denmark" />
                        </span>
                            <div class="relative w-full">
                                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span class="sm:text-sm text-topolis-blue-300">+45</span>
                                </div>
                                <input
                                    id="phone"
                                    v-model="checkoutForm.phone"
                                    required
                                    :class="{ valid: validatePhoneNumber, invalid: !validatePhoneNumber }"
                                    name="phone"
                                    type="tel"
                                    placeholder="..."
                                    autocomplete="tel"
                                    data-cy="login-form-phone-input"
                                    class="pl-10 block w-full rounded-r-lg border border-gray-300 placeholder-gray-300 focus:outline-none focus:ring-topolis-purple-400 focus:border-topolis-purple-400 text-sm min-w-[140px]"
                                    @change="checkoutForm.validate('phone')"
                                />
                            </div>
                        </div>

                        <div v-if="checkoutForm.invalid('phone')" class="mt-1 text-xs text-red-500 font-semibold">
                            {{ checkoutForm.errors.phone }}
                        </div>
                    </div>
                </div>
                <!--Note-->
                <div class="basis-[48%] flex-1 h-auto pt-2">
                    <label for="note" class="block text-left text-sm font-bold text-topolis-blue-700">Evt. en note</label>
                    <div class="mt-1">
                        <textarea id="note" v-model="checkoutForm.note" rows="3" class="form-input" placeholder="Evt. en besked til kundeservice..."></textarea>
                    </div>
                </div>
                <!--Password-->
<!--                <div>-->
<!--                    <label for="password-register" class="block text-sm font-bold text-topolis-blue-700">Password</label>-->
<!--                    <div class="mt-1">-->
<!--                        <input id="password-register" v-model="checkoutForm.password" data-cy="login-form-password-input" name="password" type="password" placeholder="************" autocomplete="current-password" required class="form-input" />-->
<!--                    </div>-->
<!--                    <div v-if="errors && errors.password" class="mt-1 text-xs text-red-500 font-semibold">-->
<!--                        <p>{{ errors.password[0] }}</p>-->
<!--                    </div>-->
<!--                </div>-->
                <!--Newsletter-->
                <div class="pt-2">
                    <label for="accepts_marketing_emails" class="flex p-2 border border-gray-300 bg-stone-100 hover:bg-stone-50">
                        <span class="flex items-center h-5">
                            <input id="accepts_marketing_emails" v-model="checkoutForm.accepts_marketing_emails" name="accepts_marketing_emails" type="checkbox" checked class="form-input-checkbox" value="" />
                        </span>
                        <span class="ml-3 text-sm font-medium text-gray-700">
                            Jeg vil gerne modtage Minitopolis' nyhedsbrev pr. email
                        </span>
                    </label>
                </div>
            </form>

            <div class="shop-radio-card checked" @click="selectCarrier('egaa', 'store_delivery')">
                <div class="shop-radio-card-wrapper">
                    <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">
                        <span class="shop-radio-card-check-icon"></span>
                    </div>
                    <span class="inline-flex flex-col mx-4">
                            <span class="text-left font-bold">Afhent i butikken (0,00 DKK)</span>
                            <span class="text-left text-slate-500">Du afhenter dine varer i vores fysiske butik i Egaa, Aarhus</span>
                        </span>
                    <img class="flex-shrink-1 w-10 mt-0.5" src="/assets/logos/minitopolis-blaa-lilla-blomst.svg" alt="Minitopolis" />
                </div>
            </div>

            <h3>Bemærk at vi arbejder på at kunne tilbyde levering i hele danmark. Hvis du er skrevet op til vores nyhedsbrev får du besked lige så snart dette er muligt.</h3>

<!--            <div class="flex flex-col space-y-2">-->
<!--                <h4>Forsendelsesmetode</h4>-->
<!--                <div class="flex flex-col items-center w-full gap-4">-->
<!--                    <div class="flex items-center justify-between flex-wrap w-full gap-4 h-full">-->
<!--                        &lt;!&ndash;Afhent i butikken&ndash;&gt;-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'egaa' && checkoutForm.delivery_method === 'store_delivery'}" @click="selectCarrier('egaa', 'store_delivery')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">Afhent i butikken (0,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Du afhenter dine varer i vores fysiske butik i Egaa, Aarhus</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-0.5" src="/assets/logos/minitopolis-blaa-lilla-blomst.svg" alt="Minitopolis" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full">-->
<!--                        &lt;!&ndash;GLS&ndash;&gt;-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'gls' && checkoutForm.delivery_method === 'pickup'}" @click="selectCarrier('gls', 'pickup')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">GLS - Pakkeshop (29,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Vælg mellem levering i mere end 1.600 GLS Pakkeshops</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-0.5" src="/assets/shipping/gls-blue.png" alt="GLS" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'gls' && checkoutForm.delivery_method === 'home_delivery'}" @click="selectCarrier('gls', 'home_delivery')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">GLS - Hjemmelevering (59,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Leveres direkte hjem til dig</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-0.5" src="/assets/shipping/gls-blue.png" alt="GLS" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="checkoutForm.chosenCarrier === 'gls' && checkoutForm.delivery_method === 'pickup' && checkoutForm.zip" class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full p-4 bg-gray-50">-->
<!--                            <div class="shop-sub-radio-card basis-[48%] flex-1" v-for="service in carrierServices" :class="{'checked': service.id === checkoutForm.delivery_shop_id}" @click="setCarrierService(service)">-->
<!--                                <div class="shop-radio-card-wrapper">-->
<!--                                    <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                        <span class="shop-radio-card-check-icon"></span>-->
<!--                                    </div>-->
<!--                                    <span class="inline-flex flex-col ml-10 w-full text-sm">-->
<!--                                    <span class="text-left font-bold">{{ service.name }}</span>-->
<!--                                    <span class="text-left">{{ service.address }}</span>-->
<!--                                    <span class="text-left">{{ service.zipcode }} {{ service.city }}</span>-->
<!--                                </span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="checkoutForm.chosenCarrier === 'gls' && checkoutForm.delivery_method === 'pickup' && !checkoutForm.zip" class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full p-4 bg-gray-50">-->
<!--                        <span class="inline-flex flex-col w-full text-sm">-->
<!--                            <span class="text-left font-medium">Udfyld dit postnummer for at kunne mulige pakkeshops</span>-->
<!--                        </span>-->
<!--                        </div>-->

<!--                        &lt;!&ndash;Bring&ndash;&gt;-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'bring' && checkoutForm.delivery_method === 'pickup'}" @click="selectCarrier('bring', 'pickup')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">Bring - Pakkeshop (29,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Vælg mellem levering i mere end 1.100 Bring Pakkeshops</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-0.5" src="/assets/shipping/bring.png" alt="Bring" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'bring' && checkoutForm.delivery_method === 'home_delivery'}" @click="selectCarrier('bring', 'home_delivery')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">Bring - Hjemmelevering (59,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Leveres direkte hjem til dig</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-0.5" src="/assets/shipping/bring.png" alt="Bring" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="checkoutForm.chosenCarrier === 'bring' && checkoutForm.delivery_method === 'pickup' && checkoutForm.zip" class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full p-4 bg-gray-50">-->
<!--                            <div class="shop-sub-radio-card basis-[48%] flex-1" v-for="service in carrierServices" :class="{'bg-red-500': service && service.id === checkoutForm.delivery_shop_id}" @click="setCarrierService(service)">-->
<!--                                <div class="shop-radio-card-wrapper">-->
<!--                                    <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                        <span class="shop-radio-card-check-icon"></span>-->
<!--                                    </div>-->
<!--                                    <span class="inline-flex flex-col ml-10 w-full text-sm">-->
<!--                                    <span class="text-left font-bold">{{ service.name }}</span>-->
<!--                                    <span class="text-left">{{ service.address }}</span>-->
<!--                                    <span class="text-left">{{ service.zipcode }} {{ service.city }}</span>-->
<!--                                </span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="checkoutForm.chosenCarrier === 'bring' && checkoutForm.delivery_method === 'pickup' && !checkoutForm.zip" class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full p-4 bg-gray-50">-->
<!--                        <span class="inline-flex flex-col w-full text-sm">-->
<!--                            <span class="text-left font-medium">Udfyld dit postnummer for at kunne mulige pakkeshops</span>-->
<!--                        </span>-->
<!--                        </div>-->

<!--                        &lt;!&ndash;dao&ndash;&gt;-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'dao' && checkoutForm.delivery_method === 'pickup'}" @click="selectCarrier('dao', 'pickup')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">dao - Pakkeshop (29,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Vælg mellem levering i mere end 1.200 dao Pakkeshops</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-0.5" src="/assets/shipping/dao.png" alt="dao" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'dao' && checkoutForm.delivery_method === 'home_delivery'}" @click="selectCarrier('dao', 'home_delivery')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">dao - Hjemmelevering (59,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Leveres direkte hjem til dig</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-0.5" src="/assets/shipping/dao.png" alt="dao" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="checkoutForm.chosenCarrier === 'dao' && checkoutForm.delivery_method === 'pickup' && checkoutForm.zip" class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full p-4 bg-gray-50">-->
<!--                            <div class="shop-sub-radio-card basis-[48%] flex-1" v-for="service in carrierServices" :class="{ 'radio-card-check-icon-checked': service && service.id === checkoutForm.delivery_shop_id }" @click="setCarrierService(service)">-->
<!--                                <div class="shop-radio-card-wrapper">-->
<!--                                    <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                        <span class="shop-radio-card-check-icon"></span>-->
<!--                                    </div>-->
<!--                                    <span class="inline-flex flex-col ml-10 w-full text-sm">-->
<!--                                    <span class="text-left font-bold">{{ service.name }}</span>-->
<!--                                    <span class="text-left">{{ service.address }}</span>-->
<!--                                    <span class="text-left">{{ service.zipcode }} {{ service.city }}</span>-->
<!--                                </span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="checkoutForm.chosenCarrier === 'dao' && checkoutForm.delivery_method === 'pickup' && !checkoutForm.zip" class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full p-4 bg-gray-50">-->
<!--                        <span class="inline-flex flex-col w-full text-sm">-->
<!--                            <span class="text-left font-medium">Udfyld dit postnummer for at kunne mulige pakkeshops</span>-->
<!--                        </span>-->
<!--                        </div>-->

<!--                        &lt;!&ndash;PostNord&ndash;&gt;-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'pdk' && checkoutForm.delivery_method === 'pickup'}" @click="selectCarrier('pdk', 'pickup')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">PostNord - Pakkeshop (29,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Vælg mellem levering i mere end 2.650 PostNord Pakkeshops</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-1" src="/assets/shipping/pn-blue.png" alt="PostNord" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="shop-radio-card" :class="{'checked': checkoutForm.chosenCarrier === 'pdk' && checkoutForm.delivery_method === 'home_delivery'}" @click="selectCarrier('pdk', 'home_delivery')">-->
<!--                            <div class="shop-radio-card-wrapper">-->
<!--                                <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                    <span class="shop-radio-card-check-icon"></span>-->
<!--                                </div>-->
<!--                                <span class="inline-flex flex-col mx-4">-->
<!--                                <span class="text-left font-bold">PostNord - Hjemmelevering (59,00 DKK)</span>-->
<!--                                <span class="text-left text-slate-500">Leveres direkte hjem til dig</span>-->
<!--                            </span>-->
<!--                                <img class="flex-shrink-1 w-10 mt-1" src="/assets/shipping/pn-blue.png" alt="PostNord" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="checkoutForm.chosenCarrier === 'pdk' && checkoutForm.delivery_method === 'pickup' && checkoutForm.zip" class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full p-4 bg-gray-50">-->
<!--                            <div class="shop-sub-radio-card basis-[48%] flex-1" v-for="service in carrierServices" :class="{'bg-red-500': service && service.id === checkoutForm.delivery_shop_id}" @click="setCarrierService(service)">-->
<!--                                <div class="shop-radio-card-wrapper">-->
<!--                                    <div class="absolute top-4 left-3 xs:top-0 xs:left-0 lg:top-4 lg:left-3">-->
<!--                                        <span class="shop-radio-card-check-icon"></span>-->
<!--                                    </div>-->
<!--                                    <span class="inline-flex flex-col ml-10 w-full text-sm">-->
<!--                                    <span class="text-left font-bold">{{ service.name }}</span>-->
<!--                                    <span class="text-left">{{ service.address }}</span>-->
<!--                                    <span class="text-left">{{ service.zipcode }} {{ service.city }}</span>-->
<!--                                </span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="checkoutForm.chosenCarrier === 'pdk' && checkoutForm.delivery_method === 'pickup' && !checkoutForm.zip" class="flex items-stretch justify-between flex-wrap w-full gap-4 h-full p-4 bg-gray-50">-->
<!--                        <span class="inline-flex flex-col w-full text-sm">-->
<!--                            <span class="text-left font-medium">Udfyld dit postnummer for at kunne mulige pakkeshops</span>-->
<!--                        </span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    components: {  },
    computed: {
        ...mapGetters(['checkoutErrors', 'checkoutForm']),
        invalidInputs() {
            return false;
            // @TODO - make disabled work
            // return !this.checkoutForm.name || !this.checkoutForm.email || this.checkoutForm.password;
        },
        submitButtonText() {
            if (this.registrationStep) {
                return 'Opret bruger';
            }

            return 'Gem';
        },
        validatePhoneNumber() {
            if (!this.checkoutForm.phone) return true;

            return this.checkoutForm.phone.match(this.phoneRegex);
        },
        validateEmail() {
            if (!this.checkoutForm.email) return true;

            return this.checkoutForm.email.match(this.emailRegex);
        },
        confirmEmailsMatching() {
            if (this.checkoutForm.confirm_email.length <= 6 || !this.checkoutForm.confirm_email) return true;
            return this.checkoutForm.email === this.checkoutForm.confirm_email;
        }
    },
    watch: {
        form: {
            handler(self) {
                this.emitter.emit('set-form-state', self);
            },
            deep: true
        }
    },
    props: {
        showTerms: Boolean,
        registrationStep: Boolean,
        outsideFlow: Boolean,
        errors: Object,
        generalError: Object,
    },
    data() {
        return {
            carrierServices: [],
            deliveryServiceCodes: [
                {
                    carrier: 'gls',
                    type: 'home_delivery',
                    code: 'GLSDK_HD',
                },
                {
                    carrier: 'gls',
                    type: 'pickup',
                    code: 'GLSDK_SD',
                },
                {
                    carrier: 'dao',
                    type: 'home_delivery',
                    code: 'DAO_STH',
                },
                {
                    carrier: 'dao',
                    type: 'pickup',
                    code: 'DAO_STS',
                },
                {
                    carrier: 'bring',
                    type: 'home_delivery',
                    code: 'BRI_HDP',
                },
                {
                    carrier: 'bring',
                    type: 'pickup',
                    code: 'BRI_PP',
                },
                {
                    carrier: 'pdk',
                    type: 'home_delivery',
                    code: 'PDK_MH',
                },
                {
                    carrier: 'pdk',
                    type: 'pickup',
                    code: 'PDK_MC',
                },
                {
                    carrier: 'egaa',
                    type: 'store_delivery',
                    code: 'MINI_EGAA'
                }
            ],
            // Regex only for 8 numbers, with no symbols or spaces allowed
            phoneRegex: /^[1-9][0-9]{7}$/,
            emailRegex: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
        };
    },
    mounted() {
        //this.fetchShippingAddresses();

        if (this.authenticatedUser) {
              (this.checkoutForm.name = this.authenticatedUser.name),
                (this.checkoutForm.address = this.authenticatedUser.address),
                (this.checkoutForm.zip = this.authenticatedUser.zip),
                (this.checkoutForm.city = this.authenticatedUser.city),
                (this.checkoutForm.email = this.authenticatedUser.email),
                (this.checkoutForm.phone = this.authenticatedUser.phone),
                (this.checkoutForm.accepts_marketing_emails = this.authenticatedUser.accepts_marketing_emails),
                (this.checkoutForm.accepts_trade_terms = this.authenticatedUser.accepts_trade_terms);
        }
    },
    methods: {
        selectCarrier(carrier_code, delivery_method) {

            this.checkoutForm.chosenCarrier = carrier_code;
            this.checkoutForm.delivery_method = delivery_method;

            // this.fetchServiceCode();

            if(this.checkoutForm.zip && this.checkoutForm.chosenCarrier !== 'egaa') {
                this.fetchShippingAddresses();
            }
        },
        fetchServiceCode() {
            axios.get(`/carrier-services?carrier_code=${this.checkoutForm.chosenCarrier}&receiver_country_code=DK`).then((response) => {
               console.log(response.data);
                this.setCarrierService(response.data[0].service_code);
            });
        },
        validateZip(event) {
            if(event.target.value.length > 3) {
                this.fetchShippingAddresses();
            }
        },
        fetchShippingAddresses() {
            axios.get(`/shipping-addresses?country_code=DK&carrier_code=${this.checkoutForm.chosenCarrier}&zipcode=${this.checkoutForm.zip}`).then((response) => {
                this.carrierServices = response.data;
            });
        },
        setCarrierService(service) {
            // this.checkoutForm.chosenCarrierService = null;
            // this.checkoutForm.delivery_shop_id = null;

            if(this.checkoutForm.delivery_method === 'home_delivery') {
                this.checkoutForm.chosenCarrierService = this.deliveryServiceCodes.find((serviceCode) => {
                    return serviceCode.carrier === this.checkoutForm.chosenCarrier && serviceCode.type === this.checkoutForm.delivery_method;
                }).code;
            } else if(this.checkoutForm.delivery_method === 'store_delivery') {
                    return;
            } else {
                this.checkoutForm.chosenCarrierService = this.deliveryServiceCodes.find((serviceCode) => {
                    return serviceCode.carrier === this.checkoutForm.chosenCarrier && serviceCode.type === this.checkoutForm.delivery_method;
                }).code;

                this.checkoutForm.delivery_shop_id = service.id;
            }
        },
        gotoPrev() {
            this.$router.back();
        },
        validateRegex(form, phone) {
            return this.isValidEmail(form) && this.isValidPhone(phone);
        },
        isValidEmail() {
            if (!this.checkoutForm.email) return;

            return this.emailRegex.test(this.checkoutForm.email);
        },
        isValidPhone() {
            if (!this.checkoutForm.phone) return;

            return this.phoneRegex.test(this.checkoutForm.phone);
        }
    },
};
</script>
